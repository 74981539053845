import {Spinner} from "reactstrap";
import {Link} from "react-router-dom";
import {Activity, CreditCard, DollarSign, Home, Server, Users} from "react-feather";
import React from "react";

const ADMIN_PAGES = [
    {
        name:
            <>
                <Activity size={"2.0em"} className={"me-2 admin-link"}/>
                Activity
            </>,
        uri: "/admin/activity"
    },
    {
        name:
            <>
                <Server size={"2.0em"} className={"me-2 admin-link"}/>
                Infrastructure
            </>,
        uri: "/admin/infrastructure"
    },
    {
        name:
            <>
                <DollarSign size={"2.0em"} className={"me-2 admin-link"}/>
                Subscriptions
            </>,
        uri: "/admin/subscriptions"
    },
    {
        name:
            <>
                <Users size={"2.0em"} className={"me-2 admin-link"}/>
                Users
            </>,
        uri: "/admin/users"
    }]

export function AdminSidebar(props) {
    return <div className="sidenav-menu admin-link">
        <div className="sidenav-menu-heading admin-link">Admin</div>
        {ADMIN_PAGES.map(page =>
            <div className={"sidenav-menu-heading text-md admin-link mt-n4"} key={page.uri}>
                <Link key={page.name} to={page.uri}>
                    <span className={"admin-link"}>{page.name}</span>
                </Link>
            </div>)}
    </div>
}
