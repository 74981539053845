import {AdminOnly} from "./admin_only.component";
import AuthService from "../services/auth.service";
import React, {useEffect, useState} from "react";
import GofigrService from "../services/gofigr.service";
import {getErrorMessage} from "../common/errors";
import {Spinner} from "reactstrap";
import {JSONPreview} from "./admin_entity_table.component";

export function IndexData(props) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!props.api_id) {
            setIsLoading(false);
            return;
        }
        GofigrService.getIndexData(props.api_id).then(data => {
            setData(data);
            setError(null);
            setIsLoading(false);
        }).catch(error => {
            setData(null);
            setError(error);
            setIsLoading(false);
        })
    }, []);

    let body;
    if(error) {
        body = <div className={"alert alert-danger"}>{getErrorMessage(error)}</div>;
    } else if(isLoading) {
        body = <Spinner/>
    } else if(!data) {
        body = "No data"
    } else {
        body = <div className={"overflow-auto"} style={{maxHeight: "40em"}}>
            <JSONPreview value={data}/>
        </div>
    }

    return <AdminOnly>
        <div className="card card-header-actions overflow-auto m-2">
        <div className="card-header">
                🚀 Index Data
            </div>
            <div className="card-body">
                {body}
            </div>
        </div>
    </AdminOnly>
}
