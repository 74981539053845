import React, {Component, useState} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {pageTitle, required} from "../js/utils";
import {Spinner} from "reactstrap";
import GofigrService from "../services/gofigr.service";
import {getErrorMessage} from "../common/errors";
import AuthService from "../services/auth.service";
import {Button} from "react-bootstrap";
import {DefaultFooter} from "../components/footer.component";

export class FormTextInput extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            value: props.value || "",
        }

    }

    componentDidMount() {
        if(this.props.callback) {
            this.props.callback(this);
        }
    }

    handleChange(event) {
        this.setState({value: event.target.value});
        if(this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    setValue(val) {
        this.setState({value: val})

        if(this.props.onChange) {
            this.props.onChange(val);
        }
    }

    clear() {
        this.setValue("");
    }

    render() {
        const props = this.props;

        return (
            <div className={this.props.margin || "mb-3"}>
                {props.label ? <label className="small mb-1" htmlFor={props.name}>{props.label}</label> : ""}
                <Input
                    type={props.type || "text"}
                    className="form-control"
                    name={props.name}
                    value={this.state.value}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                    validations={props.validations}
                    placeholder={props.placeholder || ("Enter " + props.label)}
                />

                {this.props.messages && this.props.name in this.props.messages ?
                    <div className="alert alert-danger py-1" role="alert">
                        {this.props.messages[this.props.name].map(msg => {
                            return <div key={msg}>{msg}</div>
                        })}
                    </div> : ""
                }
            </div>
        )
    }
}

export function passwordsMatch(getPassword) {
    return value => {
        const pass = getPassword();
        if((pass || value) && pass !== value) {
            return (
                <div className="alert alert-danger py-1" role="alert">
                    Passwords don't match
                </div>
            )
        }
    }
}

export class Signup extends Component {
    constructor(props) {
        super(props);
        this.handleSignup = this.handleSignup.bind(this);

        this.state = {
            username: "",
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
            email: "",
            message: "",
            loading: false,
            fieldErrors: null,
            tosAccepted: false
        }

    }

    componentDidMount() {
        document.title = pageTitle("Register");
    }

    handleSignup(e) {
        e.preventDefault();

        this.setState({message: "", loading: true})

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            const newUser = {
                username: this.state.username,
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                email: this.state.email,
                password: this.state.password
            }

            GofigrService.registerUser(newUser).then(res => {
                this.setState({loading: false})
                if(res && 'auth' in res) {
                    AuthService.autoLogin(res['auth']);

                    const params = (new URL(document.location)).searchParams;
                    let next = params.get("next");
                    if(next) {
                        next = decodeURIComponent(next);
                    } else {
                        next = "/"
                    }
                    document.location.replace(next);
                } else {
                    document.location.replace("/login");
                }
            },
            err => {
                if(err.response.status == 400) {
                    this.setState({loading: false, fieldErrors: err.response.data, message: ""})
                } else {
                    this.setState({loading: false, fieldErrors: null, message: getErrorMessage(err)});
                }
            })
        } else {
            this.setState({loading: false})
        }
    }

    render() {
        return (
            <div className="container-xl px-4">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="card shadow-lg border-0 rounded-lg mt-5">
                            <div className={"mx-2"}>
                                <img src="/logo_wide.png" className="m-4 img-fluid mx-auto d-block"/>
                            </div>
                            <div className="card-header justify-content-center"><h3
                                className="fw-light my-2">Create an Account</h3></div>
                            <div className="card-body">
                                {/* Login form */}
                                <Form
                                    onSubmit={this.handleSignup}
                                    ref={c => {
                                        this.form = c;
                                    }}
                                >
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <FormTextInput name={"firstName"}
                                                           label={"First Name"}
                                                           placeholder={"Enter first name"}
                                                           validations={[required]}
                                                           messages={this.state.fieldErrors}
                                                           onChange={val => this.setState({"firstName": val})}/>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <FormTextInput name={"lastName"}
                                                           label={"Last Name"}
                                                           placeholder={"Enter last name"}
                                                           validations={[required]}
                                                           messages={this.state.fieldErrors}
                                                           onChange={val => this.setState({"lastName": val})}/>
                                        </div>
                                    </div>

                                    <FormTextInput name={"username"}
                                                   label={"Username"}
                                                   placeholder={"Enter username"}
                                                   validations={[required]}
                                                   messages={this.state.fieldErrors}
                                                   onChange={val => this.setState({"username": val})}/>

                                    <FormTextInput name={"email"}
                                                   label={"Email"}
                                                   placeholder={"Enter email address"}
                                                   validations={[required]}
                                                   messages={this.state.fieldErrors}
                                                   onChange={val => this.setState({"email": val})}/>

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <FormTextInput name={"password"}
                                                           label={"Password"}
                                                           type={"password"}
                                                           placeholder={"Enter password"}
                                                           messages={this.state.fieldErrors}
                                                           validations={[required]}
                                                           onChange={val => this.setState({"password": val})}/>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <FormTextInput name={"confirmPassword"}
                                                           label={"Confirm Password"}
                                                           type={"password"}
                                                           placeholder={"Please confirm password"}
                                                           messages={this.state.fieldErrors}
                                                           validations={[required, passwordsMatch(() => this.state.password)]}
                                                           onChange={val => this.setState({"confirmPassword": val})}/>
                                        </div>
                                    </div>

                                    <div className={"mt-4"}>
                                        <div className={"form-check"}>
                                            <input className="form-check-input" type="checkbox" value={this.state.tosAccepted}
                                                   onChange={value => {
                                                       this.setState({tosAccepted: !this.state.tosAccepted});
                                                   }}
                                                   id="acceptTOS"/>

                                            <label className="form-check-label" htmlFor="acceptTOS">
                                                <span>I have read and agree with GoFigr's </span>
                                                <a href="https://gofigr.io/privacy-policy/" target="_blank">Privacy Policy</a>
                                                <span> and </span>
                                                <a href="https://gofigr.io/terms-and-conditions/"
                                                   target="_blank">Terms &amp; Conditions</a>.
                                            </label>
                                        </div>

                                    </div>

                                    <div
                                        className="d-flex align-items-center justify-content-between mt-4 mb-0 float-end">
                                        <button
                                            className={this.state.tosAccepted ? "btn btn-primary" : "btn btn-light disabled"}
                                            disabled={this.state.loading}
                                        >
                                            {this.state.loading && (
                                                <span className="spinner-border spinner-border-sm me-2"></span>
                                            )}
                                            <span>Register</span>
                                        </button>
                                    </div>

                                    {this.state.message && (
                                        <div className="form-group">
                                            <div className="alert alert-danger mt-4" role="alert">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    )}

                                    <CheckButton
                                        style={{display: "none"}}
                                        ref={c => {
                                            this.checkBtn = c;
                                        }}
                                    />

                                </Form>
                            </div>
                            <div className="card-footer text-center">
                                <div className="small">If you already have an account, you can <a
                                    href="/login">login</a> here.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"my-10"}/>
                <DefaultFooter/>
            </div>
        );
    }
}
