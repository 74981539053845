import {EntityList} from "./entity_list.component";
import React, {useEffect, useState} from "react";
import {orderByMostRecent} from "../services/gofigr.service";

export function RecentsList(props) {
    const [api, setApi] = useState(props.api);
    const [workspacesToFetch, setWorkspacesToFetch] = useState(api.workspaces);
    const [recentFigures, setRecentFigures] = useState([])
    const [recentAnalyses, setRecentAnalyses] = useState([]);

    useEffect(() => {
        if(!workspacesToFetch || workspacesToFetch.length === 0) {
            return;
        }

        api.getRecents(workspacesToFetch[0]).then(recents => {
            let newFigs = [...recentFigures];
            let newAnas = [...recentAnalyses];
            newFigs.push(...recents.figures);
            newAnas.push(...recents.analyses);

            newFigs = orderByMostRecent(newFigs);
            newAnas = orderByMostRecent(newAnas);

            setRecentFigures(newFigs);
            setRecentAnalyses(newAnas);
            setWorkspacesToFetch(workspacesToFetch.slice(1));
        }).catch(console.log);
    }, [api, workspacesToFetch, recentFigures, recentAnalyses]);

    return <>
        { /* Analysis */}
        <EntityList key={"recent_analyses_" + String(recentAnalyses.length)}
                    objects={recentAnalyses}
                    title="Recent Analyses"
                    isLoading={workspacesToFetch.length > 0}
                    endpoint={"/analysis"}/>

        <hr className={"mb-n2"}/>

        { /* Figure */}
        <EntityList key={"recent_figures_" + String(recentFigures.length)}
                    objects={recentFigures}
                    title="Recent Figures"
                    isLoading={workspacesToFetch.length > 0}
                    endpoint={"/figure"}/>
    </>
}