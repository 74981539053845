import React, {Component} from "react";
import {required} from "../js/utils";
import Form from "react-validation/build/form";
import {FormTextInput, passwordsMatch} from "./signup.view";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import {getErrorMessage} from "../common/errors";
import {useSearchParams} from "react-router-dom";
import {DefaultFooter} from "../components/footer.component";

export class RequestToken extends Component {
    constructor(props) {
        super(props);
        this.handleReset = this.handleReset.bind(this);

        this.state = {
            email: "",
            loading: false,
            message: "",
        }
    }

    handleReset(e) {
        e.preventDefault();

        this.setState({message: "", loading: true})

        this.form.validateAll();

        if (this.checkBtn.context._errors.length !== 0) {
            this.setState({message: "", loading: false})
            return
        } else {
            AuthService.requestPasswordReset(this.state.email).then(res => {
                this.setState({message: "", loading: false, codeSent: true})
            }, err => {
                this.setState({message: getErrorMessage(err), loading: false})
            })
        }
    }

    render() {
        return (
            <div className="container-xl px-4">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="card shadow-lg border-0 rounded-lg mt-5">
                            <img src="/logo_wide.png" className="m-4 img-fluid mx-auto d-block"/>
                            <div className="card-header justify-content-center"><h3
                                className="fw-light my-2">Password Reset</h3></div>
                            <div className="card-body">

                                <div className={this.state.codeSent ? "" : "visually-hidden"}>
                                    <div className={"alert alert-info"}>
                                        Code sent. Please check your email. If it doesn't arrive, please check your spam folder.
                                    </div>

                                    <div className={"d-flex align-items-center justify-content-between mt-4 mb-0"}>
                                        <button className={"btn btn-primary"} onClick={() => document.location.replace("/")}>OK</button>
                                    </div>
                                </div>

                                <div className={this.state.codeSent ? "visually-hidden": ""}>
                                    <div className={"alert alert-info"}>
                                        Please enter your email address below. If the email address is associated with
                                        an account, we will email you a code to reset your password.
                                    </div>

                                    <Form
                                        onSubmit={this.handleReset}
                                        ref={c => {
                                            this.form = c;
                                        }}
                                    >

                                        <FormTextInput name={"email"}
                                                       label={"Email"}
                                                       placeholder={"Enter email"}
                                                       validations={[required]}
                                                       onChange={val => this.setState({"email": val})}/>

                                        <div
                                            className="d-flex align-items-center justify-content-between mt-4 mb-0">
                                            <button
                                                className={"btn btn-primary btn-block"}
                                                disabled={this.state.loading}
                                            >
                                                {this.state.loading && (
                                                    <span className="spinner-border spinner-border-sm me-2"></span>
                                                )}
                                                <span>Send password reset code</span>
                                            </button>

                                        </div>

                                        {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger mt-4" role="alert">
                                                    {this.state.message}
                                                </div>
                                            </div>
                                        )}
                                        <CheckButton
                                            style={{display: "none"}}
                                            ref={c => {
                                                this.checkBtn = c;
                                            }}
                                        />

                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"my-10"}/>
                <DefaultFooter/>
            </div>
        )
    }
}

export class ResetWithToken extends Component {
    constructor(props) {
        super(props);
        this.handleReset = this.handleReset.bind(this);

        this.state = {
            password: "",
            confirmPassword: "",
            loading: false,
            message: "",
            isTokenValid: true,
            passwordChanged: false,
        }
    }

    componentDidMount() {
        AuthService.checkResetToken(this.props.token).then(res => {
            // Valid token
            this.setState({isTokenValid: true})
        }, err => {
            // Invalid token
            this.setState({isTokenValid: false})
        })
    }

    handleReset(e) {
        e.preventDefault();

        this.setState({message: "", loading: true})

        this.form.validateAll();

        if (this.checkBtn.context._errors.length !== 0) {
            this.setState({message: "", loading: false})
            return
        } else {
            AuthService.resetPassword(this.props.token, this.state.password).then(res => {
                this.setState({loading: false, message: "", passwordChanged: true})
            }, err => {
                this.setState({loading: false, message: getErrorMessage(err)})
            })
        }
    }

    render() {
        return (
            <div className="container-xl px-4">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="card shadow-lg border-0 rounded-lg mt-5">
                            <img src="/logo_wide.png" className="m-4 img-fluid mx-auto d-block"/>
                            <div className="card-header justify-content-center"><h3
                                className="fw-light my-2">Password Reset</h3></div>
                            <div className="card-body">
                                <div className={this.state.passwordChanged ? "" : "visually-hidden"}>
                                    <div className={"alert alert-info"}>
                                        Your password was successfully changed. Click here to login: <a href={"/login"}>Login</a>
                                    </div>
                                </div>

                                <div className={this.state.passwordChanged ? "visually-hidden" : ""}>

                                    <div className={this.state.isTokenValid ? "visually-hidden" : ""}>
                                        <div className={"alert alert-danger"}>
                                            This password reset request is invalid or has expired. Click here to request
                                            a new one: <a href="/reset-password">Reset password</a>
                                        </div>
                                    </div>


                                    <div className={this.state.isTokenValid ? "" : "visually-hidden"}>
                                        <Form
                                            onSubmit={this.handleReset}
                                            ref={c => {
                                                this.form = c;
                                            }}
                                        >
                                            <div className={"mt-2 mb-4 alert alert-info"}>
                                                Please enter your new password below
                                            </div>

                                            <div className={"row"}>
                                                <div className={"col-md-6"}>
                                                    <FormTextInput name={"password"}
                                                                   label={"Password"}
                                                                   type={"password"}
                                                                   placeholder={"Enter password"}
                                                                   messages={this.state.fieldErrors}
                                                                   validations={[required]}
                                                                   onChange={val => this.setState({"password": val})}/>
                                                </div>

                                                <div className={"col-md-6"}>
                                                    <FormTextInput name={"confirmPassword"}
                                                                   label={"Confirm Password"}
                                                                   type={"password"}
                                                                   placeholder={"Please confirm password"}
                                                                   messages={this.state.fieldErrors}
                                                                   validations={[required, passwordsMatch(() => this.state.password)]}
                                                                   onChange={val => this.setState({"confirmPassword": val})}/>
                                                </div>
                                            </div>

                                            <div
                                                className="d-flex align-items-center justify-content-between mt-4 mb-0">

                                                <button
                                                    className="btn btn-secondary btn-block"
                                                    onClick={() => document.location.replace("/")}
                                                >
                                                    <span>Cancel</span>
                                                </button>

                                                <button
                                                    className="btn btn-primary btn-block"
                                                    disabled={this.state.loading}
                                                >
                                                    {this.state.loading && (
                                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                                    )}
                                                    <span>Change password</span>
                                                </button>

                                            </div>

                                            {this.state.message && (
                                                <div className="form-group">
                                                    <div className="alert alert-danger mt-4" role="alert">
                                                        {this.state.message}
                                                    </div>
                                                </div>
                                            )}
                                            <CheckButton
                                                style={{display: "none"}}
                                                ref={c => {
                                                    this.checkBtn = c;
                                                }}
                                            />

                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"my-10"}/>
                <DefaultFooter/>
            </div>
        );
    }
}

export function ResetPassword(props) {
    const [params, _] = useSearchParams();
    const token = params.get("token")

    if(token && token.length > 0) {
        return <ResetWithToken token={token}/>
    } else {
        return <RequestToken/>
    }
}