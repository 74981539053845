import {Component} from "react";
import {withLoginOptionalRedirect} from "./login.view";
import GofigrService from "../services/gofigr.service";
import {getErrorMessage} from "../common/errors";
import {Spinner} from "reactstrap";
import {EntitySize, humanFileSize, StorageUsed} from "../components/entity_size.component";
import {ArrowRight, Check, CheckCircle, Database, HelpCircle, Lock, Mail, Share, Share2, Users} from "react-feather";

export function Plan(props) {
    const plan = props.plan;
    if(!plan) {
        return "";
    }

    let colorClass;
    switch(plan.name) {
        case "Free":
            colorClass = "primary";
            break;

        case "Personal":
            colorClass = "secondary";
            break;

        case "Team":
            colorClass = "primary";
            break;

        case "Enterprise":
            colorClass = "success";
            break;

        default:
            colorClass = "primary";
            break;
    }

    const iconClass = `text-${colorClass} me-2`;

    const price = (plan.monthly_cost === 0 && plan.annual_cost === 0) ? "Free" : plan.monthly_cost;
    const check = <CheckCircle className={iconClass} size={18}/>
    const users = <Users className={iconClass} size={18}/>
    const lock = <Lock className={iconClass} size={18}/>
    const share = <Share2 className={iconClass} size={18}/>
    const database = <Database className={iconClass} size={18}/>
    const help = <Mail className={iconClass} size={18}/>

    let action;
    if(props.onSelect) {
        if(!props.currentPlan) {
            action = (
                <div className={"card-footer"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <button className={"btn btn-primary"} style={{width: "100%"}} onClick={() => props.onSelect(plan)}>Choose Plan</button>
                        </div>
                    </div>
                </div>)
        } else {
            action = "";
        }
    } else {
        action = (
            <a className={`card-footer d-flex align-items-center justify-content-between text-${colorClass}`} href="/register">
                Get started!
                <ArrowRight size={18}/>
            </a>)
    }

    return (
        <div className={"card h-100 " + (props.currentPlan ? "border border-2 border-dark" : "")}>
            {props.currentPlan ? <div className={"alert alert-info py-2"}>Current Plan</div> : ""}
            <div className="card-header bg-transparent">
                <span className={`badge bg-${colorClass}-soft text-${colorClass} rounded-pill py-2 px-3 mb-2`}>{plan.name}</span>
                <div className="pricing-columns-price">
                    ${price}
                    <span>/month</span>
                </div>
            </div>
            <div className="card-body p-0">
                <ul className="list-group list-group-flush">
                    <li key="storage" className="list-group-item">
                        {database}
                        {humanFileSize(plan.max_storage_bytes)} data & image storage
                    </li>

                    <li key="share" className="list-group-item">
                        {share} Share analyses, figures & revisions
                    </li>

                    <li key="revisions" className="list-group-item">
                        {check} Unlimited number of revisions
                    </li>

                    <li key="encryption" className="list-group-item">
                        {lock} Image & data encryption
                    </li>

                    <li key="library" className="list-group-item">
                        {check} Python & R support
                    </li>

                    {plan.max_users > 1 ?
                        <li key="users" className="list-group-item">
                            {users}
                            {(plan.max_users - 1) == 0 ? "No" : plan.max_users - 1} collaborators
                        </li> : ""}

                    {plan.name === "Enterprise" ?
                    <li key="support" className={"list-group-item"}>
                        {help} Priority customer support
                    </li> : ""}

                </ul>

            </div>
            {action}
        </div>
    );
}

export class PlansView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            plans: null
        }
    }

    componentDidMount() {
        this.setState({loading: true})

        GofigrService.listPlans().then(res => {
            const plans = res.map(obj => {
                obj.monthly_cost = parseFloat(obj.monthly_cost).toFixed(2);
                obj.annual_cost = parseFloat(obj.annual_cost).toFixed(2);
                return obj;
            });

            this.setState({loading: false, error: null, plans: plans})
        }, err => {
            this.setState({loading: false, error: getErrorMessage(err), plans: null})
        })
    }

    render() {
        if(this.state.loading || !this.state.plans) {
            return <Spinner/>
        } else if(this.state.error) {
            return <div className={"alert alert-danger m-4"}>{this.state.error}</div>
        }

        const planElements = this.state.plans.map(plan => {
            return (
                <div key={plan.api_id} className={"col-xl-3 col-lg-6 mb-4 mb-xl-0"}>
                    <Plan plan={plan}
                          currentPlan={this.props.currentPlan && this.props.currentPlan.api_id === plan.api_id}
                          onSelect={this.props.onSelect}/>
                </div>
            )
        })

        return (
            <div className={"m-4"}>
                <div className={"container-xl"}>
                    <div className={"pricing-columns"}>
                        <div className={"row justify-content-center"}>
                            {planElements}
                        </div>
                    </div>

                    <div className="card bg-dark text-center pricing-detailed-behind mt-10">
                        <div className="card-header justify-content-center py-4"><h5
                            className="mb-0 text-white">Custom</h5></div>
                        <div className="card-body text-white-50 p-2">Need something more? We offer customized,
                            enterprise level plans for specific implementations. Contact our sales team to learn more
                            about custom licensing!
                        </div>
                        <a className="card-footer bg-gray-800 text-white d-flex align-items-center justify-content-center"
                           href="mailto:sales@gofigr.io?subject=Inquiring about a custom plan">
                            Contact sales
                            <ArrowRight size={18} className={"ms-2"}/>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default withLoginOptionalRedirect(PlansView);
