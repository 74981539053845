import React from "react";

export function appName() {
    return "GoFigr"
}

export function pageTitle(subtitle) {
    if(!subtitle) {
        return appName()
    } else {
        return appName() + " - " + subtitle;
    }
}

export const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger py-1" role="alert">
                This field is required!
            </div>
        );
    }
};


export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
