import GofigrService from "../services/gofigr.service";
import React, {useEffect, useState} from "react";
import {SmallSpinner} from "./entity_list.component";
import {getErrorMessage} from "../common/errors";
import Select from "react-select";

export function WorkspaceSelector(props) {
    const [workspaces, setWorkspaces] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selection, setSelection] = useState(null);

    useEffect(() => {
        setIsLoading(true);

        GofigrService.listWorkspaces().then(res => {
            setWorkspaces(res);
            setError(null);
            setIsLoading(false);
            if(res.length > 0) {
                setSelection({label: res[0].name, value: res[0]})
                if (props.onChange) {
                    props.onChange(res[0]);
                }
            }
        }).catch(err => {
            setError(err);
            setWorkspaces([]);
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return <SmallSpinner/>
    } else if (error) {
        return <div className={"alert alert-danger"}>{getErrorMessage(error)}</div>
    } else {
        return <Select
            value={selection}
            onChange={event => {
                setSelection(event);
                if (props.onChange) {
                    props.onChange(event.value);
                }
            }}
            options={workspaces.map(worx => {
                return {label: worx.name, value: worx};
            })}/>
    }
}

export function WorkspaceName(props) {
    const [workspace, setWorkspace] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(props.workspace_id) {
            GofigrService.getWorkspace(props.workspace_id).then(worx => {
                setWorkspace(worx);
                setError(null);
                setIsLoading(false);
            }).catch(error => {
                setWorkspace(null);
                setError(error);
                setIsLoading(false);
            })
        } else {
            setIsLoading(false);
            setError(null);
        }
    }, []);

    if(isLoading) {return <SmallSpinner/>}
    else if(error) {return <div className={"alert alert-danger"}>{getErrorMessage(error)}</div> }
    else {
        return workspace ? <a target="_blank" href={"/workspace/" + workspace.api_id}>{workspace.name}</a> : "N/A"
    }
}
