export function Metadata(props) {
    const metadata = props.metadata;

    if(!metadata || Object.keys(metadata).length === 0) {
        return "No metadata available"
    }

    const rows = Object.keys(metadata).map(prop_name => {
        return <tr key={prop_name}>
            <td className={"pe-4 font-monospace"}>{prop_name}</td>
            <td>{metadata[prop_name]}</td>
        </tr>
    })

    return (
        <table className="table table-striped table-responsive m-2">
            <thead>
            <tr>
                <th>Key</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>)
}