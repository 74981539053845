import React, {Component} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";

import {withRouter} from '../common/with-router';
import {Navigate, useLoaderData} from "react-router-dom";
import {NOT_LOGGED_IN_ERROR} from "../services/gofigr.service";
import {pageTitle, required} from "../js/utils";
import {getErrorMessage, isPermissionDenied} from "../common/errors";
import {DefaultFooter} from "../components/footer.component";

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            username: "",
            password: "",
            rememberMe: false,
            loading: false,
            message: "",
            codeSent: false
        };
    }

    componentDidMount() {
        document.title = pageTitle("Login");
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    handleLogin(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            AuthService.login(this.state.username, this.state.password, this.state.rememberMe).then(
                () => {
                    const params = (new URL(document.location)).searchParams;
                    let next = params.get("next");
                    if(next) {
                        next = decodeURIComponent(next);
                    } else {
                        next = "/"
                    }
                    document.location.replace(next);
                },
                error => {
                    this.setState({
                        loading: false,
                        message: getErrorMessage(error)
                    });
                }
            );
        } else {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        return (
            <div className="container-xl px-4">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="card shadow-lg border-0 rounded-lg mt-5">
                            <div className={"mx-2"}>
                                <img src="/logo_wide.png" className="m-4 img-fluid mx-auto d-block"/>
                            </div>
                            <div className="card-header justify-content-center"><h3
                                className="fw-light my-2">Login</h3></div>
                            <div className="card-body">
                                {/* Login form */}
                                <Form
                                    onSubmit={this.handleLogin}
                                    ref={c => {
                                        this.form = c;
                                    }}
                                >
                                    {/* Form Group (username) */}
                                    <div className="mb-3">
                                        <label className="small mb-1"
                                               htmlFor="username">E-mail or username</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="username"
                                            value={this.state.username}
                                            onChange={this.onChangeUsername}
                                            validations={[required]}
                                            placeholder="Enter e-mail or username"
                                        />
                                    </div>
                                    {/* Form Group (password) */}
                                    <div className="mb-3">
                                        <label className="small mb-1"
                                               htmlFor="password">Password</label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.onChangePassword}
                                            validations={[required]}
                                            placeholder="Enter password"
                                        />
                                    </div>
                                    {/* Remember me */}
                                    <div className="my-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value={this.state.rememberMe}
                                                   onChange={value => {
                                                       this.setState({rememberMe: !this.state.rememberMe})
                                                   }}
                                                   id="rememberMeCheck"/>
                                            <label className="form-check-label small" htmlFor="rememberMeCheck">
                                                Remember me on this device for 30 days
                                            </label>
                                        </div>
                                    </div>

                                    {/* Form Group (login box) */}
                                    <div
                                        className="d-flex align-items-center justify-content-between mt-4 mb-0">
                                        <a className="small" href="/reset-password">Forgot
                                            Password?</a>
                                        <button
                                            className="btn btn-primary btn-block"
                                            disabled={this.state.loading}
                                        >
                                            {this.state.loading && (
                                                <span className="spinner-border spinner-border-sm me-2"></span>
                                            )}
                                            <span>Login</span>
                                        </button>
                                    </div>

                                    {this.state.message && (
                                        <div className="form-group">
                                            <div className="alert alert-danger mt-4" role="alert">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    )}
                                    <CheckButton
                                        style={{display: "none"}}
                                        ref={c => {
                                            this.checkBtn = c;
                                        }}
                                    />

                                </Form>
                            </div>
                            <div className="card-footer text-center">
                                <div className="small"><a href="/register">Need an account?
                                    Sign up!</a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"my-10"}/>
                <DefaultFooter/>
            </div>
        );
    }
}

export function NavigateToLogin(props) {
    const next = encodeURIComponent(document.URL);
    return <Navigate to={"/login?next=" + next}/>
}

export function redirectToLogin() {
    const next = encodeURIComponent(document.URL);
    document.location.replace("/login?next=" + next);
}

export const withLoginOptionalRedirect = (Component) => {
    function WrappedComponent(props) {
        const data = useLoaderData();

        if(!data) {
            return ""
        }

        if(AuthService.isLoginExpired()) {  // user was just logged in but the token expired
            return <NavigateToLogin/>
        }

        // At this point the user may not be logged in, but the page may work in Anonymous mode. So try to render it
        // and only redirect on errors
        try {
            return <Component {...props}/>;
        } catch(err) {
            if(err.response && err.response.status == 401) {
                return <NavigateToLogin/>
            } else {
                throw(err);
            }
        }
    }
    return WrappedComponent;
};

export const withLoginRequiredRedirect = (Component) => {
    function WrappedComponent(props) {
        if(!AuthService.isLoggedIn()) {
            return <NavigateToLogin/>
        }

        return <Component {...props}/>;
    }
    return WrappedComponent;
};

export function withLoginRedirectLoader(loader) {
    return async function (...args) {
        try {
            return await loader(...args);
        } catch(error) {
            if(error === NOT_LOGGED_IN_ERROR || (error.response && error.response.status == 401)) {
                redirectToLogin();
                return null;
            } else {
                throw(error);
            }
        }
    }
}

export default withRouter(Login);