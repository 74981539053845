import React, {Component} from "react";
import {Check} from "react-feather";
import {getErrorMessage} from "../common/errors";
import {Spinner} from "reactstrap";

export class AsyncDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            choices: props.choices,
            selected: props.selected,
            pending: false,
            getName: props.getName || (x => x)
        }
    }

    setPending(pending) {
        this.setState({pending: pending})
    }

    setSelected(value) {
        this.setState({selected: value})
    }

    render() {
        const getName = this.state.getName;

        return <div className="btn-group">
            <button type="button" className="btn btn-secondary dropdown-toggle"
                    data-bs-toggle="dropdown" aria-expanded="false">
                {this.state.pending ? <span className="spinner-border spinner-border-sm"/> : ""}
                {getName(this.state.selected)}
            </button>
            <ul className="dropdown-menu">
                {this.state.choices.map(label => {
                    return <li key={getName(label)}>
                        <a className="dropdown-item" href="#" onClick={() => {
                            const prevLabel = this.state.selected;
                            this.setState({selected: label});

                            if(label != prevLabel && this.props.onChange) {
                                this.props.onChange(this, prevLabel, label);
                            }
                        }}>
                            {label === this.state.selected ? <Check/> : ""} {getName(label)}
                        </a>
                    </li>
                })}
            </ul>
        </div>
    }
}


export class WithAsyncLoader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: null,
            loadedProps: null,
        }
    }

    componentDidMount() {
        const loadedProps = {};
        Promise.all(Object.keys(this.props.loaders).map(async key => {
            loadedProps[key] = await this.props.loaders[key]();
        })).then(res => {
            this.setState({loadedProps: loadedProps, error: null, loading: false})
        }, err => {
            this.setState({loadedProps: {}, error: getErrorMessage(err), loading: false})
        })
    }

    render() {
        let elementProps = this.props.targetProps;
        if(this.state.loadedProps) {
            Object.assign(elementProps, this.state.loadedProps);
        }

        if(this.state.loading) {
            return <Spinner/>
        } else if(this.state.error) {
            return <div className={"alert alert-danger"}>{this.state.error}</div>
        } else {
            return this.props.target({...elementProps});
        }
    }
}