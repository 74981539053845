import axios from "axios";
import {Navigate} from "react-router-dom";
import React from "react";
import {NOT_LOGGED_IN_ERROR} from "./gofigr.service";
import {SERVICE_URL} from "../js/config";

const AUTH_URL = SERVICE_URL + "/api/token/";
const PASSWORD_RESET_URL = SERVICE_URL + "/api/password_reset/"

class AuthService {
    constructor() {
        const auth = JSON.parse(localStorage.getItem("auth"));
        if(auth == null) {
            this.access_token = null;
            this.refresh_token = null;
        } else {
            this.access_token = auth.access_token;
            this.refresh_token = auth.refresh_token;
        }

        this.loginExpired = false;
    }

    saveAuth() {
        localStorage.setItem("auth", JSON.stringify({
            access_token: this.access_token,
            refresh_token: this.refresh_token
        }));
    }

    requestPasswordReset(email) {
        return axios.post(PASSWORD_RESET_URL, {email: email})
    }

    resetPassword(token, password) {
        return axios.post(PASSWORD_RESET_URL + "confirm/", {token: token, password: password})
    }

    checkResetToken(token) {
        return axios.post(PASSWORD_RESET_URL + "validate_token/", {token: token})
    }

    autoLogin(data) {
        this.access_token = data.access;
        this.refresh_token = data.refresh;

        this.saveAuth();
    }

    login(username, password, rememberMe=false) {
        return axios
            .post(AUTH_URL, {
                username: username,
                password: password,
                remember_me: rememberMe
            })
            .then(response => {
                this.access_token = response.data.access;
                this.refresh_token = response.data.refresh;

                this.saveAuth();
            });
    }

    isLoggedIn() {
        return localStorage.getItem('auth') != null;
    }

    isLoginExpired() {
        return this.loginExpired;
    }

    setLoginExpired(val) {
        this.loginExpired = val;
    }

    refresh() {
        return axios
            .post(AUTH_URL + "refresh/", {
                refresh: this.refresh_token
            })
            .then(response => {
                this.access_token = response.data.access;
                this.saveAuth();
            });
    }

    logout() {
        localStorage.removeItem("auth");
        this.access_token = null;
        this.refresh_token = null;
    }
}

export function authHeader() {
    const auth = JSON.parse(localStorage.getItem('auth'));

    if (auth) {
        return { Authorization: 'Bearer ' + auth.access_token };
    } else {
        return {};
    }
}

export default new AuthService();
