import React, {Component, useEffect, useState} from "react";
import {
    createBrowserRouter,
    Link,
    Navigate,
    Outlet,
    RouterProvider,
    useLoaderData, useNavigate, useNavigation,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './css/sb.styles.css';
import './css/App.css';
import "react-datepicker/dist/react-datepicker.css";
import './js/scripts'
import GofigrService from "./services/gofigr.service";
import Workspace, {EditWorkspace, ShareWorkspace, workspaceEditAction, workspaceLoader} from "./views/workspace.view";
import {ExternalLink, Grid, HelpCircle, Home, LogOut, Mail, Menu, Search, Settings, User} from "react-feather";
import Login, {NavigateToLogin, redirectToLogin, withLoginRedirectLoader} from "./views/login.view";
import {IntlProvider} from "react-intl";
import Analysis, {analysisEditAction, analysisLoader, EditAnalysis, ShareAnalysis} from "./views/analysis.view";
import Figure, {EditFigure, figureEditAction, figureLoader, ShareFigure} from "./views/figure.view";
import Revision, {
    RedirectToRevision,
    redirectToRevisionLoader,
    revisionLoader,
    ShareRevision
} from "./views/revisions.view";
import AuthService from "./services/auth.service";
import {setupSB} from "./js/scripts";
import {GfAvatar, UserLink} from "./components/userlink.component";
import UserView, {ConfirmEmailView, userLoader} from "./views/user.view";
import {Logout} from "./views/logout.view";
import {EntityList} from "./components/entity_list.component";
import CreateNewEntity, {createNewEntityLoader} from "./views/create_entity.view";
import {Signup} from "./views/signup.view";
import {pageTitle} from "./js/utils";
import {ResetPassword} from "./views/reset_password.view";
import {NotLoggedInAlert} from "./components/loginalert.component";
import HomeView, {homeViewLoader} from "./views/home.view";
import PlansView from "./views/plans.view";
import {QuickSearch, SearchView} from "./views/search.view";
import {DefaultGoFigrError} from "./components/error.component";
import {DefaultFooter} from "./components/footer.component";
import {map} from "react-bootstrap/ElementChildren";
import {Spinner} from "reactstrap";
import {RecentsList} from "./components/recents.component";
import {InvitationView} from "./views/invitation.view";
import {AdminSidebar} from "./components/admin_sidebar.component";
import {UserManagement} from "./views/admin/user_management.view";
import {ActivityView} from "./views/admin/activity.view";
import {InfrastructureView} from "./views/admin/infrastructure.view";
import {SubscriptionsView} from "./views/admin/subscriptions.view";

export async function loader({params}) {
    const gf = await GofigrService.initialize();

    return {api: gf,
        userInfo: gf.userInfo};
}

async function defaultLoader({params}) {
    const gf = await GofigrService.initialize();
    return {api: gf}
}

function setErrorElement(children) {
    for(const child of children) {
        child.errorElement = <DefaultGoFigrError/>
    }
    return children;
}

const router = createBrowserRouter([
    {
        element: <Root />,
        errorElement: <DefaultGoFigrError footer={true} show_back_link={true}/>,
        path: '/',
        loader: withLoginRedirectLoader(loader),
        children: setErrorElement([
            /* Home */
            {
                element: <HomeView />,
                path: '/',
                loader: withLoginRedirectLoader(homeViewLoader)
            },

            /*
            Workspace
             */
            {
                path: '/workspace/:apiId',
                element: <Workspace/>,
                loader: withLoginRedirectLoader(workspaceLoader)
            },
            {
                path: '/workspace/:apiId/manage',
                element: <EditWorkspace/>,
                loader: withLoginRedirectLoader(workspaceLoader),
            },
            {
                path: '/workspace/:apiId/share',
                element: <ShareWorkspace/>,
                loader: withLoginRedirectLoader(workspaceLoader)
            },
            {
                path: '/workspace',
                element: <Workspace/>,
                loader: withLoginRedirectLoader(workspaceLoader)
            },
            {
                path: '/create-workspace/',
                element: <CreateNewEntity workspace={true}/>,
                loader: createNewEntityLoader
            },

            /*
            Analysis
             */
            {
                path: '/analysis/:apiId',
                element: <Analysis/>,
                loader: withLoginRedirectLoader(analysisLoader)
            },
            {
                path: '/analysis/:apiId/edit',
                element: <EditAnalysis/>,
                loader: withLoginRedirectLoader(analysisLoader),
            },
            {
                path: '/analysis/:apiId/share',
                element: <ShareAnalysis/>,
                loader: withLoginRedirectLoader(analysisLoader),
            },

            /*
            Figure
             */
            {
                path: '/figure/:apiId',
                element: <Figure/>,
                loader: withLoginRedirectLoader(figureLoader)
            },
            {
                path: '/figure/:apiId/edit',
                element: <EditFigure/>,
                loader: withLoginRedirectLoader(figureLoader),
            },
            {
                path: '/figure/:apiId/share',
                element: <ShareFigure/>,
                loader: withLoginRedirectLoader(figureLoader),
            },

            /*
            Revision
             */
            {
                path: '/revision/:apiId',
                element: <Revision/>,
                loader: withLoginRedirectLoader(revisionLoader)
            },
            {
                path: '/r/:apiId',
                element: <RedirectToRevision/>,
                loader: redirectToRevisionLoader
            },
            {
                path: '/revision/:apiId/share',
                element: <ShareRevision/>,
                loader: withLoginRedirectLoader(revisionLoader)
            },

            /*
            Create new analysis or figure
             */
            {
                path: '/:parentType/:parentId/create-child/',
                element: <CreateNewEntity/>,
                loader: createNewEntityLoader
            },

            /*
            User profile
             */
            {
                path: '/user/:username',
                element: <UserView/>,
                loader: withLoginRedirectLoader(userLoader),
            },

            /*
            Plans
             */
            {
                path: '/plans',
                element: <PlansView/>,
                loader: defaultLoader,
            },

            /*
            Search
             */
            {
                path: '/search',
                element: <SearchView/>,
            },

            /*
            Admin
             */
            {
                path: '/admin/users',
                element: <UserManagement/>
            },

            {
                path: '/admin/activity',
                element: <ActivityView/>
            },

            {
                path: '/admin/infrastructure',
                element: <InfrastructureView/>
            },

            {
                path: '/admin/subscriptions',
                element: <SubscriptionsView/>
            }
        ])
    },

    /* Login/logout */
    {
        element: <Login />,
        path: '/login',
        errorElement: <DefaultGoFigrError/>
    },
    {
        element: <Logout />,
        path: '/logout',
        errorElement: <DefaultGoFigrError/>
    },

    /* Register */
    {
        element: <Signup />,
        path: '/register',
        errorElement: <DefaultGoFigrError/>
    },

    /* Invitations */
    {
        element: <InvitationView />,
        path: '/invitations/workspace/:token',
        errorElement: <DefaultGoFigrError/>
    },

    /* Forgot password */
    {
        element: <ResetPassword />,
        path: '/reset-password',
        errorElement: <DefaultGoFigrError/>
    },

    /* Confirm email */
    {
        element: <ConfirmEmailView/>,
        path: '/confirm-email',
        errorElement: <DefaultGoFigrError/>,
        loader: withLoginRedirectLoader(userLoader)
    }
])

function Root() {
    const data = useLoaderData();
    const navigation = useNavigation();
    const [api, setApi] = useState(data && data.api);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if(initialized) {
            return;
        }

        setInitialized(true);
        setupSB();
        GofigrService.registerRefreshHandler((gf) => {
            setApi(gf);
        })

        setInterval(() => {
            if(AuthService.isLoginExpired()) {
                redirectToLogin();
            }
        }, 1000);
    })

    if(AuthService.isLoginExpired()) {
        return <NavigateToLogin/>
    }

    const mainContent = (
        <div id="layoutSidenav_content">
        <main className={navigation.state === "loading" ? "loading" : ""}>
            <Outlet/>
        </main>

            {navigation.state === "loading" ? <Spinner className={"spinner-overlay"}/> : ""}

        <DefaultFooter/>
    </div>)

    if(!api || api.anonymous) {
        return (
            <IntlProvider locale="en-US">
                <NotLoggedInAlert/>
                {mainContent}
            </IntlProvider>
        )
    }  else {
        return <IntlProvider locale="en-US">
            <div className="App nav-fixed">

                <nav
                    className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white"
                    id="sidenavAccordion">

                    <button className="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0"
                            id="sidebarToggle"><Menu/></button>

                    <a href={"/"}><img className={"ms-2"} src={"/logo_large.png"} style={{width: "32px", height: "32px"}}/></a>
                    <a className="navbar-brand pe-3 ps-4 ps-lg-2" href="/">GoFigr</a>

                    <QuickSearch router={router}/>

                    <ul className="navbar-nav align-items-center ms-auto">

                        <li className="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
                            <a className="btn btn-icon btn-transparent-dark dropdown-toggle"
                               id="navbarDropdownUserImage"
                               role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                               aria-expanded="false"><GfAvatar userInfo={data.userInfo} size={"48px"}/></a>
                            <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up"
                                 aria-labelledby="navbarDropdownUserImage">
                                <h6 className="dropdown-header d-flex align-items-center">
                                    {data.userInfo && data.userInfo.avatar ?
                                        <img className="dropdown-user-img"
                                             src={`data:image/png;base64,${data.userInfo && data.userInfo.avatar}`}/>
                                        : <User className={"m-2"}/>
                                    }
                                    <div className="dropdown-user-details">
                                        <div className="dropdown-user-details-name">
                                            <UserLink username={data.userInfo && data.userInfo.username}/>
                                            {data.userInfo && data.userInfo.is_staff ? <span className={"ms-1"}>🚀</span> : ""}
                                        </div>
                                        <div
                                            className="dropdown-user-details-email">{data.userInfo && data.userInfo.email}</div>
                                    </div>
                                </h6>
                                <div className="dropdown-divider"></div>
                                <Link className="dropdown-item"
                                      to={"/user/" + (data.userInfo ? data.userInfo.username : "")}>
                                    <div className="dropdown-item-icon"><Settings/></div>
                                    Account
                                </Link>
                                <a className="dropdown-item"
                                   href={"https://gofigr.io/docs/gofigr-python/latest/start.html"}
                                   target="_blank">
                                    <div className="dropdown-item-icon"><HelpCircle/></div>
                                    Quick Start Guide
                                </a>
                                <a className="dropdown-item"
                                   href={"mailto:support@gofigr.io"}
                                   target={"_blank"}>
                                    <div className="dropdown-item-icon"><Mail/></div>
                                    Contact Support
                                </a>
                                <Link className="dropdown-item" to="/logout">
                                    <div className="dropdown-item-icon"><LogOut/></div>
                                    Logout
                                </Link>
                            </div>
                        </li>
                    </ul>
                </nav>

                <div id="layoutSidenav">
                    <div id="layoutSidenav_nav">
                        <nav className="sidenav shadow-right sidenav-dark">
                            <div className="sidenav-menu">
                                <div className="nav accordion" id="accordionSidenav">

                                    <div className={"sidenav-menu-heading text-md"}>
                                        <Link to={"/"}><Home size={"2.0em"} className={"me-2"}/><span>Home</span></Link>
                                    </div>

                                    <div className={"sidenav-menu-heading text-md mt-n2"}>
                                        <Link to={"/workspace"}><Grid size={"2.0em"} className={"me-2"}/><span>My Workspaces</span></Link>
                                    </div>

                                    <div className={"sidenav-menu-heading text-md mt-n2"}>
                                        <Link to={"/search"}><Search size={"2.0em"} className={"me-2"}/><span>Search</span></Link>
                                    </div>

                                    <hr className={"mb-n2"}/>

                                    {data.userInfo && data.userInfo.is_staff ? <>
                                        <AdminSidebar/>
                                        <hr className={"mb-n2"}/>
                                    </> : ""}

                                    { /* Workspaces */}
                                    <EntityList objects={api && api.workspaces}
                                                title="Recent Workspaces"
                                                endpoint={"/workspace"}/>

                                    <hr className={"mb-n2"}/>

                                    { /* Recent Analyses & Figures */}
                                    <RecentsList api={api.workspaces && api}/>

                                </div>
                            </div>

                            <div className="sidenav-footer">
                                <div className="sidenav-footer-content">
                                    <div className="sidenav-footer-subtitle">Logged in as:</div>
                                    <div className="sidenav-footer-title">
                                        <UserLink username={data.userInfo && data.userInfo.username}/>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    {mainContent}
                </div>
            </div>
        </IntlProvider>
    }
}

class App extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        //document.title = pageTitle(null);
    }

    componentWillUnmount() {

    }

    render() {
      return (
          <RouterProvider router={router}>
          </RouterProvider>
      );
  }
}

export default App;
