import {PlusCircle} from "react-feather";
import {Link} from "react-router-dom";
import React from "react";
import {Button} from "react-bootstrap";

export function CreateNew(props) {
    return (
        <Link to={props.endpoint}>
            <span className={"float-end btn btn-primary px-2 py-2 m-n2"}><PlusCircle className="me-2" size={"1.25em"}/> Create new...</span>
        </Link>
    )
}

export function RightFloatingButton(props) {
    return (
        <Link to={null} onClick={props.onClick}>
            <span className={"float-end btn px-2 py-2 m-n2 " + (props.customClass ? props.customClass : "btn-primary")}>
                {props.children}
            </span>
        </Link>
    )
}
