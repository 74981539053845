import React from "react";
import GofigrService from "../../services/gofigr.service";
import {humanFileSize} from "../../components/entity_size.component";
import {AdminDataPanel, AdminPage} from "../../components/admin_entity_table.component";
import Moment from "react-moment";
import {Link} from "react-router-dom";

function YesNo(props) {
    if(props.value) {
        return <div className="badge bg-green" style={{fontSize: '1em'}}>yes</div>
    } else {
        return <div className="badge bg-orange" style={{fontSize: '1em'}}>no</div>
    }
}

function simpleSelector(property) {
    return row => row[property]
}

function simpleSetter(property) {
    return (row, value) => {
        row[property] = value;
        const changes = {}
        changes[property] = value;

        return({row, changes})
    }
}

function simpleProperty(name) {
    return {selector: simpleSelector(name),
            setter: simpleSetter(name)}
}

const planColumns = [
    {
        name: 'ID',
        selector: row => row.api_id
    },
    {
        name: 'Name',
        ...simpleProperty("name")
    },
    {
        name: 'Description',
        ...simpleProperty("description")
    },
    {
        name: 'Max users',
        ...simpleProperty("max_users")
    },
    {
        name: 'Storage',
        format: (row, index) => humanFileSize(row.max_storage_bytes),
        ...simpleProperty("max_storage_bytes")
    },
    {
        name: 'Available',
        format: (row, index) => <YesNo value={row.available}/>,
        ...simpleProperty("available"),
    },
    {
        name: 'New user default',
        format: (row, index) => <YesNo value={row.new_user_default}/>,
        ...simpleProperty("new_user_default"),
    },
    {
        name: 'Monthly cost',
        ...simpleProperty("monthly_cost"),
    },
    {
        name: 'Annual cost',
        ...simpleProperty("annual_cost"),
    }
];

function WorkspaceLink(props) {
    const worx = props.workspace;
    return <Link to={"/workspace/"  + worx.api_id}>{worx.name} ({humanFileSize(worx.size_bytes)})</Link>;
}

export function InlineList(props) {
    const elements = props.elements;
    if(!elements) {return null};

    return elements.map(elt => {
        return <div key={elt}>
        {elt === elements[0] ? "" : <span className={"mx-2"}>•</span>}
        {props.makeElement(elt)}
        </div>
    })
}

function totalStorage(workspaces) {
    let total = 0;
    if(!workspaces) {
        return(total)
    }

    workspaces.forEach(worx => {
        total += worx.size_bytes;
    })
    return total;
}

const subscriptionColumns = [
    {
        name: 'ID',
        selector: row => row.api_id
    },
    { name: 'Plan',
        format: row => row.plan_data.name,
        ...simpleProperty("plan")
    },
    {
        name: 'Started on',
        format: (row, index) => <Moment>{row["started_on"]}</Moment>,
        ...simpleProperty("started_on"),
    },
    {
        name: 'Expires on',
        format: (row, index) => row["expires_on"] ? <Moment>{row["expires_on"]}</Moment> : "N/A",
        ...simpleProperty("expires_on"),
    },
    {
        name: 'Workspaces',
        selector: row => row["workspace_set"],
        format: (row, index) => <InlineList elements={row["workspace_data"]}
                                            makeElement={worx => <WorkspaceLink workspace={worx}/>}/>,
        sortable: true
    },
    {
        name: 'Total storage',
        selector: row => totalStorage(row["workspace_data"]),
        format: row => humanFileSize(totalStorage(row["workspace_data"])),
        sortable: true
    },
];

export function SubscriptionsView(props) {
    return <AdminPage title={"Subscription Management"}>
        <AdminDataPanel title={"Plans"}
                        columns={planColumns}
                        fetchAll={async () => GofigrService.listAllPlans()}
                        onSave={async (row, changes, create) => {
                            if(create) {
                                await GofigrService.createPlan(changes)
                            } else {
                                await GofigrService.updatePlanProperties({
                                    api_id: row.api_id,
                                    ...changes
                                })
                            }
                        }}
                        onDelete={async row => {
                            await GofigrService.deletePlan(row);
                        }}/>

        <AdminDataPanel title={"Subscriptions"}
                        columns={subscriptionColumns}
                        fetchAll={async () => GofigrService.listAllSubscriptions()}
                        onSave={async (row, changes, create) => {
                            if(create) {
                                await GofigrService.createSubscription(changes)
                            } else {
                                await GofigrService.updateSubscriptionProperties({
                                    api_id: row.api_id,
                                    ...changes
                                })
                            }
                        }}
                        onDelete={async row => {
                            await GofigrService.deleteSubscription(row);
                        }}/>
    </AdminPage>
}
