import {useLoaderData, useLocation, useNavigate, useNavigation, useParams, useSearchParams} from "react-router-dom";

export const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let navigation = useNavigation();
        let params = useParams();
        let [searchParams, setSearchParams] = useSearchParams();
        let data = useLoaderData();
        return <Component {...props} router={{ location, navigate, navigation, params, data,
            searchParams, setSearchParams}} />;
    }
    return ComponentWithRouterProp;
};