import React, {useEffect, useState} from "react";
import {Input, Spinner} from "reactstrap";
import {getErrorMessage} from "../common/errors";
import DataTable from "react-data-table-component";
import {Header} from "./header.components";
import {pageTitle} from "../js/utils";
import {RightFloatingButton} from "./create_new.component";
import {Check, Edit, Plus, PlusCircle, RefreshCw, Save, Trash, X, XCircle} from "react-feather";
import {Button, Modal} from "react-bootstrap";
import {ConfirmDeleteModal, SimpleModal} from "./simple.modal";
import {docco} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";
import GofigrService from "../services/gofigr.service";


export function InlineEditor(props) {
    const [value, setValue] = useState(props.value);

    const callOnCancel = () => {
        if(props.onCancel) {
            props.onCancel();
        }
    }

    const callOnAccept = () => {
        if(props.onAccept) {
            props.onAccept(value);
        }
    }

    return <div className="row align-items-center">
        <div className="col-9">
            <Input className={"mt-2"}
                   value={value}
                   onKeyDown={e => {
                       if(e.key === "Enter") {
                           callOnAccept();
                       } else if(e.key === "Escape") {
                           callOnCancel();
                       }
                   }}
                   onChange={event => {
                       setValue(event.target.value);
                   }}/>
        </div>
        <div className={"col-3"}>
            <div className={"row"}>
                <div className={"col-6"}>
                    <Button className={"btn btn-light px-2 py-2 w-100"}
                            onClick={callOnAccept}
                    ><Check/></Button>
                </div>
                <div className={"col-6"}>
                    <Button className={"btn btn-light px-2 py-2 w-100"}
                            onClick={callOnCancel}><X/></Button>
                </div>
            </div>
        </div>
    </div>
}

export const ExpandedComponent = ({ data }) =>
    <div className={"m-2"}>
        <pre>
            <JSONPreview value={data}/>
        </pre>
    </div>;

export function CellEditor(props) {
    const [row, setRow] = useState(props.row);
    const [editMode, setEditMode] = useState(props.editMode && props.column.setter != null);

    const formatter = props.column.format ? props.column.format : (row => props.column.selector(row))

    let body;
    if(editMode) {
        body = <InlineEditor value={props.column.selector(row)}
                             column={props.column}
                             onCancel={() => setEditMode(false)}
                             onAccept={value => {
                                 const result = props.column.setter(row, value);
                                 setRow(result.row);
                                 setEditMode(false);

                                 if(props.onAccept) {
                                     props.onAccept(result);
                                 }
                             }}/>
    } else if(props.column.setter) {
        body = <div onClick={() => setEditMode(true)}>
            {formatter(row)}
            <Edit className={"m-2"}/>
        </div>
    } else {
        body = <div>{formatter(row)}</div>
    }

    return <div className={"row my-2"}>
        <div className="form-label fw-bold col-4">
            {props.column.name}
        </div>
        <div className="label col-8">
            {body}
        </div>
    </div>;
}

export function JSONPreview(props) {
    return <SyntaxHighlighter showLineNumbers="true" language={"json"} style={docco}>
        {JSON.stringify(props.value, null, 2)}
    </SyntaxHighlighter>
}

export class RowEditor extends SimpleModal {
    constructor(props) {
        super(props);

        this.state['row'] = null;
        this.state['changes'] = {};
        this.state['loading'] = false;
        this.state['message'] = null;
        this.state['error'] = false;
        this.state['creationMode'] = false;
    }

    show(row, title, creationMode = false) {
        super.show(title, null);
        this.setState({
            row: row, changes: {}, loading: false, message: null, error: false,
            creationMode: creationMode
        })
    }

    hide() {
        super.hide();
        this.setState({
            row: null, changes: {}, loading: false, message: null, error: false,
            creationMode: false
        });
    }

    render() {
        return (
            <Modal
                show={this.state.show}
                onHide={() => this.hide()}
                size={this.props.size || "lg"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!this.state.row ? "" : this.props.columns.map(col => {
                        if(col.selector) {
                            return <CellEditor key={JSON.stringify(col)}
                                               column={col}
                                               row={this.state.row}
                                               editMode={this.state.creationMode}
                                               onAccept={result => {
                                                   this.setState({changes: {
                                                           ...this.state.changes,
                                                           ...result.changes
                                                       }})
                                               }}/>
                        } else {
                            return ""
                        }
                    })}
                    <h5 className={"mt-4"}>Changes</h5>
                    <JSONPreview value={this.state.changes}/>
                    {this.state.error ? <div className={"alert alert-danger my-4"}>{this.state.message}</div> : ""}
                    {!this.state.error && this.state.message ? <div className={"alert alert-info my-4"}>{this.state.message}</div> : ""}
                </Modal.Body>
                <Modal.Footer>
                    {this.state.loading ? <Spinner/> : ""}
                    {this.props.onSave ?
                        <Button className={"btn bg-primary"} onClick={() => {
                            this.setState({loading: true})
                            this.props.onSave(this.state.row, this.state.changes, this.state.creationMode)
                                .then(res => {
                                    this.setState({message: "Changes saved", error: false})
                                })
                                .catch(err => this.setState({error: true, message: getErrorMessage(err)}))
                                .finally(() => this.setState({loading: false}))
                        }}>
                            {this.state.creationMode ? "Create" : "Save"}
                        </Button> : ""}

                    <Button className={"btn bg-secondary"} onClick={() => this.hide()}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export function AdminEntityTable(props) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [editModal, setEditModal] = useState(null);
    const [deleteModal, setDeleteModal] = useState(null);

    useEffect(() => {
        if(props.fetchAll) {
            setIsLoading(true);
            props.fetchAll().then(data => {
                setData(data);
                setError(null);
                if(props.onChange) {
                    props.onChange(data);
                }
            }).catch(err => {
                setError(err);
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, []);

    if(isLoading) {
        return <Spinner/>
    } else if(error) {
        return <div className="alert alert-danger">
            {getErrorMessage(error)}
        </div>
    } else if(data === null) {
        return <div className="alert alert-danger">No data to display</div>
    } else {
        let columns = props.columns ? [...props.columns] : [];
        if(props.editable) {
            columns.push({
                name: "Actions",
                cell: row => {
                    return <>
                        <Button className="p-2 m-1" onClick={() => {
                            editModal.show(row, "Edit instance")
                        }
                        }><Edit/></Button>

                        {props.onDelete ? <Button className="bg-danger p-2 m-1" onClick={() => {
                            deleteModal.show(<><div>You are deleting:</div>
                                <JSONPreview value={row}/>
                            </>, {row: row});
                        }
                        }><Trash/></Button> : ""}
                    </>
                }
            })
        }
        return <><DataTable key={data} columns={columns} data={data}
                          expandableRows
                          expandableRowsComponent={ExpandedComponent}
                          pagination paginationRowsPerPageOptions={[10, 50, 100]}
                          paginationPerPage={50}/>

            <Button className="p-2 mx-auto" onClick={() => {
                editModal.show({}, "New instance", true);
            }}>
                <PlusCircle className="me-2" size={"1.25em"}/> Add new...
            </Button>

            <RowEditor callback={modal => setEditModal(modal)}
                       columns={columns}
                       onSave={props.onSave}/>

            <ConfirmDeleteModal callback={modal => setDeleteModal(modal)}
                                performDelete={props.onDelete}/>
        </>
    }
}

export function AdminDataPanel(props) {
    const [nonce, setNonce] = useState(0);
    const [message, setMessage] = useState();

    return <div className="card m-2">
        <div className={"card-header"}>
            {props.title}

            <RightFloatingButton onClick={() => {
                setNonce(nonce + 1)
                setMessage(null);
            }}>
                <RefreshCw className="me-2" size={"1.25em"}/> Refresh
            </RightFloatingButton>

        </div>
        <div className={"card-body"}>
            {message ? <div className={"alert alert-info mb-4 py-1"}>{message}</div> : ""}

            <AdminEntityTable
                key={nonce}
                columns={props.columns}
                fetchAll={props.fetchAll}
                onChange={props.onChange}
                editable={true}
                onSave={props.onSave ? async (row, changes, creationMode) => {
                    await props.onSave(row, changes, creationMode);
                    setMessage("Changes saved successfully.")
                    setNonce(nonce + 1);
                } : null}
                onDelete={props.onDelete ? async context => {
                    await props.onDelete(context.row);
                    setMessage("Object deleted successfully.");
                    setNonce(nonce + 1);
                } : null}
            />

        </div>
    </div>
}

export function AdminPage(props) {
    useEffect(() => {
        document.title = pageTitle(props.title ? props.title : "Admin");
    }, []);

    return <div>
        <Header hidePath={true}
                title={<div className={"row w-100"}>
            <span className="row text-red">🚀 {props.title}</span>
        </div>}/>
        {props.children}
    </div>
}