import React, {Component} from "react";
import GofigrService from "../services/gofigr.service";
import {getErrorMessage} from "../common/errors";
import {AlertTriangle} from "react-feather";

/**
 * Format bytes as human-readable text.
 * Thanks https://stackoverflow.com/users/65387/mpen!
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 *
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export class EntitySize extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            size_bytes: null,
            loading: false
        }
    }

    componentDidMount() {
        if(!this.props.object) {
            return;
        }

        if(this.props.object.size_bytes >= 0) {
            this.setState({error: null, loading: false, size_bytes: this.props.object.size_bytes})
            return;
        }

        this.setState({error: null, loading: true})
        GofigrService.getObjectSize(this.props.object).then(res => {
            this.setState({loading: false, size_bytes: res.size_bytes})
        }, err => {
            this.setState({loading: false, error: getErrorMessage(err)})
        })
    }

    render() {
        if(this.state.loading) {
            return <span className="spinner-border spinner-border-sm me-2"></span>
        } else if(this.state.error) {
            return <AlertTriangle/>
        } else {
            return <span>{humanFileSize(this.state.size_bytes)}</span>
        }
    }
}


export class StorageUsed extends EntitySize {
    constructor(props) {
        super(props);
    }

    render() {
        if(this.state.loading) {
            return <span className="spinner-border spinner-border-sm me-2"></span>
        } else if(this.state.error) {
            return <AlertTriangle/>
        } else {
            const usedPercent = (100.0 * this.state.size_bytes / this.props.limit).toFixed(0);

            return (
                <div className="progress">
                    <div className={"progress-bar " + (usedPercent >= 90 ? "bg-danger" : "bg-primary")}
                         role="progressbar" style={{width: `${Math.min(100, usedPercent)}%`}}
                         aria-valuenow={`${usedPercent}`} aria-valuemin="0" aria-valuemax="100">{`${usedPercent}%`}
                    </div>
                </div>
            )
        }
    }
}