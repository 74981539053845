import React, {Component, useEffect, useState} from "react";
import {Grid, Info, PlusCircle, PlusSquare, Send, Settings, Trash2, UserPlus, XCircle} from "react-feather";
import {withRouter} from "../common/with-router";
import GofigrService from "../services/gofigr.service";
import {ActionHeader, Header} from "../components/header.components";
import {withLoginOptionalRedirect} from "./login.view";
import {Newsfeed} from "../components/newsfeed.component";
import {ChildTable} from "../components/child_table.component";
import {makeEditAction, PropertyEditor} from "../components/property_editor.component";
import {Link, useLoaderData} from "react-router-dom";
import {UserLink} from "../components/userlink.component";
import {ConfirmDeleteModal, SimpleModal, UserSelectionModal, YesCancelModal} from "../components/simple.modal";
import {Button} from "react-bootstrap";
import {getErrorMessage} from "../common/errors";
import {AsyncDropdown} from "../components/async.component";
import {CreateNew} from "../components/create_new.component";
import {SharingSettings} from "../components/sharing.component";
import {pageTitle} from "../js/utils";
import {SubscriptionManager} from "../components/subscription.component";
import {Input} from "reactstrap";
import Moment from "react-moment";


export async function workspaceLoader({params}) {
    const api = await GofigrService.initialize();
    const apiId = (params && "apiId" in params) ? params.apiId : null;
    let workspace = null;
    let analyses = null;
    let log = null;

    if(apiId) {
        workspace = await GofigrService.getWorkspace(apiId)
        api.currentWorkspace = workspace;

        GofigrService.currentPath = [{ endpoint: "/workspace/", object:  GofigrService.currentWorkspace}]

        log = await GofigrService.getWorkspaceLog(apiId);
        analyses = workspace.analyses;
    }
    return {api, apiId, workspace, analyses, log};
}

class Workspace extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }


    render() {
        document.title = pageTitle(this.props.router.data.apiId ? this.props.router.data.workspace.name : "Workspaces");

        const data = this.props.router.data;
        if(data.apiId) {
            return this.renderSelectedWorkspace();
        } else {
            return this.renderWorkspaceList();
        }
    }

    renderWorkspaceList() {
        const data = this.props.router.data;

        return <div className="container-fluid">
            <div className="card mt-4">
                <div className="card-header">
                    Workspaces

                    <CreateNew endpoint={`/create-workspace`}/>
                </div>
                <div className="card-body">
                    <ChildTable children={this.props.router.data.api.workspaces}
                                   endpoint="/workspace/"
                                   navigate={this.props.router.navigate}
                                   fetch={api_id => data.api.getWorkspace(api_id)}/>
                </div>
            </div>
        </div>
    }

    renderSelectedWorkspace() {
        const data = this.props.router.data;
        return <div>
            <ActionHeader key={data.workspace.api_id}
                          object={data.workspace}
                          title={data.workspace.name}
                          subtitle={data.workspace.description}
                          icon={<Grid/>}
                          endpoint="/workspace/"
                          manage={true}
                          edit={false}
                          delete={false}
                          share={false}
            />

            <div className="row">
                <div className="col-xl-8">
                    <div className="card m-2">
                        <div className="card-header">
                            Analyses
                            <CreateNew endpoint={`/workspace/${data.workspace.api_id}/create-child`}/>
                        </div>
                        <div className="card-body">
                            <ChildTable children={this.props.router.data.analyses}
                                        endpoint="/analysis/"
                                        navigate={this.props.router.navigate}
                                        fetch={api_id => data.api.getAnalysis(api_id)}/>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4">
                    <div className="card m-2">
                        <div className="card-header">
                            Recent activity
                        </div>
                        <div className="card-body">
                            <Newsfeed key={data.workspace.api_id} log={data.log}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const workspaceRoles = [
    {label: "Owner", name: "owner"},
    {label: "Administrator", name: "admin"},
    {label: "Creator", name: "creator"},
    {label: "Viewer", name: "viewer"}
]

const inviteRoles = workspaceRoles.filter(role => role.name !== "owner")

function getRoleInfo(workspace_role) {
    let matchingRoles = workspaceRoles.filter(role => role.name === workspace_role);
    return matchingRoles.length ? matchingRoles[0] : {label: "N/A", name: null}
}

class WorkspaceMembers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: null,
            invitations: [],
            pending: false,
            workspace: props.workspace,
            infoModal: null,
            userSelectionModal: null
        }
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        GofigrService.getWorkspaceMembers(this.state.workspace).then(members => {
            this.setState({members: members});
        })

        GofigrService.getInvitations(this.state.workspace).then(invitations => {
            this.setState({invitations});
        })
    }

    renderRoles(member) {
        let matchingRoles = workspaceRoles.filter(role => role.name === member.membership_type);
        const memberRole = matchingRoles.length ? matchingRoles[0] : {label: "N/A", name: null}

        return <AsyncDropdown choices={workspaceRoles}
                              getName={role => role.label}
                              selected={memberRole}
                              onChange={(elt, oldRole, newRole) => {
                                  elt.setPending(true);
                                  GofigrService.changeMembership(this.state.workspace, member.username, newRole.name).then(result => {
                                  }, err => {
                                      this.state.infoModal.show("Unable to change role", getErrorMessage(err));
                                  }).finally(() => {
                                      elt.setSelected(oldRole);
                                      elt.setPending(false);
                                      this.refresh();
                                  })
                              }}/>
    }

    render() {
        if(!this.state.members) {
            return "No members to show"
        }

        return (
            <div>
                <SimpleModal callback={(modal) => this.setState({infoModal: modal})}/>

                <YesCancelModal callback={modal => this.setState({userSelectionModal: modal})}
                                yesLabel={"Invite"}>
                    <div>Please enter the e-mail address of the person you would like to invite:</div>
                    <div className={"mx-2"}>
                        <div className="row">
                                <Input className={"mt-2"}
                                       placeholder={"e-mail address"}
                                       onChange={event => {
                                           this.state.userSelectionModal.email = event.target.value;
                                       }}
                                />
                        </div>
                        <div className={"row my-3"}>
                            <div className="mb-2">Member role (you can change this once they join):</div>
                                <AsyncDropdown choices={inviteRoles}
                                               getName={role => role.label}
                                               selected={getRoleInfo(("viewer"))}
                                               onChange={(elt, oldRole, newRole) => {
                                                   this.state.userSelectionModal.role = newRole;
                                               }}/>

                        </div>
                    </div>
                </YesCancelModal>

                <table className="table table-responsive table-striped table-hover">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/*
                          * Members
                          */}
                        {this.state.members.map(member => {
                            return <tr key={JSON.stringify(member)}>
                                <td><UserLink username={member.username}/></td>
                                <td>
                                    {this.renderRoles(member)}
                                </td>
                                <td><Button className="btn btn-danger" onClick={() => {
                                    GofigrService.removeMember(this.state.workspace, member.username).then(
                                        () => {},
                                        (err) => {
                                            this.state.infoModal.show("Error", getErrorMessage(err))
                                        }
                                    ).finally(() => {this.refresh()})
                                }}>
                                    <XCircle size="16px"/><span className="ms-2">Remove</span></Button></td>
                            </tr>
                        })}

                        {/*
                          * Invitations
                          */}
                        {this.state.invitations.map(invite => {
                            return <tr key={invite.api_id}>
                                <td><em>{invite.email} (invited <Moment fromNow ago>{invite.created}</Moment> ago)</em></td>
                                <td>
                                    {getRoleInfo(invite.membership_type).label}
                                </td>
                                <td>
                                    <Button className="btn btn-dark me-2" onClick={() => {
                                        GofigrService.resendInvitation(this.state.workspace, invite).then(
                                            () => {
                                                this.state.infoModal.show("Invitation sent", "Invitation re-sent successfully")
                                            },
                                            (err) => {
                                                this.state.infoModal.show("Error", getErrorMessage(err))
                                            }
                                        ).finally(() => this.refresh());
                                    }}>
                                        <Send size="16px"/><span className="ms-2">Resend</span></Button>

                                    <Button className="btn btn-danger" onClick={() => {
                                        GofigrService.cancelInvitation(invite).then(() => {},
                                            (err) => {
                                                this.state.infoModal.show("Error", getErrorMessage(err))
                                            }
                                        ).finally(() => this.refresh());
                                    }}>
                                        <XCircle size="16px"/><span className="ms-2">Cancel invite</span></Button></td>
                            </tr>
                        })}
                    </tbody>
                </table>

                {/*
                  * Invite button
                  */}
                <div className="row">
                    <div className="col-12">
                        <Button className="btn btn-light" onClick={() => {
                            this.state.userSelectionModal.show("Invite a member to " + this.state.workspace.name,
                                "",
                                modal => {
                                GofigrService.inviteMember(this.props.workspace,
                                    modal.email,
                                    this.state.userSelectionModal.role ? this.state.userSelectionModal.role.name : "viewer")
                                    .then(() => {this.state.userSelectionModal.hide()},
                                        (err) => {
                                            this.state.infoModal.show("Error", getErrorMessage(err))
                                        })
                                    .finally(() => {this.refresh()})
                                });
                        }}>
                            <UserPlus/><span className="ms-2">Invite...</span>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export function getHumanizedWorkspaceType(workspaceType) {
    switch(workspaceType) {
        case "primary":
            return "Personal Workspace"
        case "secondary":
            return "Collaborative"
        default:
            return workspaceType
    }
}

const workspaceProperties = [
    {name: 'workspace_type', label: 'Workspace type: ', placeholder: 'Primary or Secondary',
        html: (workspace, spec) => {
            return <>
                <label className="small mb-1">{spec.label}</label>
                <div className="badge bg-blue ms-2 py-2">{getHumanizedWorkspaceType(workspace.workspace_type)}</div>
            </>;
        }},
    {name: 'name', label: 'Name: ', placeholder: 'Enter name'},
    {name: 'description', label: 'Description: ', placeholder: 'Enter description'},
]

export const EditWorkspace = withLoginOptionalRedirect(() => {
    const data = useLoaderData();
    const [deleteModal, setDeleteModal] = useState();

    const title = data.workspace.name + " - Manage"

    useEffect(() => {
        document.title = title;
    })

    let collaborationMessage = "";
    if(data.workspace.workspace_type === "primary") {
        collaborationMessage = (
            <div className={"alert alert-info"}>
                This is your personal workspace. We recommend creating a separate workspace for collaboration.
                This will make transferring ownership and management of the workspace easier in the future.
            </div>)
    }

    return <>
        <ConfirmDeleteModal callback={modal => setDeleteModal(modal)}
                            deleteWarning="Deleting a workspace will delete all analyses, all figures, all revisions and all their data. This cannot be undone."
                            performDelete={async () => {
                                await GofigrService.deleteWorkspace(data.workspace);
                                window.location.replace("/workspace/");
                            }}/>

        <Header title={title}
                object={data.workspace} hidePath={true} icon={<Settings/>}/>

        <PropertyEditor object={data.workspace} title_name="Workspace" objectProperties={workspaceProperties}
                        commitUpdates={updates => GofigrService.updateWorkspace(updates)}
                        title={"Information"} hideID={true}/>

        <div className={"card m-2"}>
            <div className={"card-header"}>Workspace Members</div>
            <div className={"card-body"}>
                {collaborationMessage}
                <WorkspaceMembers workspace={data.workspace}/>
            </div>
        </div>

        <div className={"card m-2"}>
            <div className={"card-header"}>Plan & Subscription</div>
            <div className={"card-body"}>
                <SubscriptionManager object={data.workspace}/>
            </div>
        </div>

        <div className={"card m-2"}>
            <div className={"card-header"}>Delete Workspace</div>
            <div className={"card-body"}>
                <a href="#" className="btn btn-danger ms-2 py-2" onClick={() => {
                    deleteModal.show();
                }}>
                    <Trash2 className={"me-2"}/> Delete this workspace
                </a>
            </div>
        </div>
    </>
})

export const ShareWorkspace = withLoginOptionalRedirect(() => {
    return <div className={"container-fluid"}>
        <div className="alert alert-warning mt-4">The sharing of workspaces is not currently possible.
            To collaborate, add collaborators as members of your workspace.</div>
    </div>
})

export default withLoginOptionalRedirect(withRouter(Workspace));
