import {Component, useState} from "react";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {orderByMostRecent} from "../services/gofigr.service";
import {Spinner} from "reactstrap";

function millisecondsToHours(milliseconds) {
    const seconds = milliseconds / 1000.0;
    const minutes = seconds / 60.0;
    const hours = minutes / 60.0;
    return hours;
}

export function SmallSpinner(props) {
    return <Spinner style={{height: "1em", width: "1em"}}/>
}

export function EntityList(props) {
    const [objects, setObjects] = useState(props.objects);
    const [visibleCount, setVisibleCount] = useState(props.visibleCount || 20);
    const [title, setTitle] = useState(props.title || null);

    const visibleObjects = objects ? objects.slice(0, visibleCount) : [];
    const allVisible = objects ? visibleCount >= objects.length : false;

    if(!visibleObjects || visibleObjects.length === 0) {
        return title ?
            <><div className="sidenav-menu-heading">{title}</div><div className="nav-link">
                {props.isLoading ? <Spinner className={"ms-2"} style={{height: "1em", width: "1em"}}/> : "Nothing to show"}
            </div></>
            : "";
    }

    return <div className="sidenav-menu">
        {title ? <div className="sidenav-menu-heading">{title}
            {props.isLoading ? <Spinner className={"ms-2"} style={{height: "1em", width: "1em"}}/> : ""}</div> : ""}

        {visibleObjects.map(obj => {
            const timedelta = obj.created_on ? Math.abs(Date.now() - new Date(obj.created_on)) : null;
            let badge = null;
            if(timedelta && millisecondsToHours(timedelta) < 24) {
                badge = <span className="badge bg-orange ms-2">new</span>
            }

            return <Link key={obj.api_id} className="nav-link" to={props.endpoint + "/" + obj.api_id}>
                {obj.name} {badge}
            </Link>
        })}
        {allVisible ? "" : <a className="nav-link pointer" onClick={() => setVisibleCount(visibleCount + 10)}>...</a>}
    </div>
}
