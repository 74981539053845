import GofigrService, {orderByMostRecent} from "../services/gofigr.service";
import React, {Component} from "react";
import {ActionHeader, Header} from "../components/header.components";
import {BarChart2, Book, Edit, PieChart} from "react-feather";
import {withRouter} from "../common/with-router";
import {Revisions} from "./revisions.view";
import {Form, Link, Navigate, redirect, useLoaderData, useNavigate} from "react-router-dom";
import {withLoginOptionalRedirect} from "./login.view";
import {makeEditAction, PropertyEditor} from "../components/property_editor.component";
import {SharingSettings} from "../components/sharing.component";
import {isPermissionDenied} from "../common/errors";
import {pageTitle} from "../js/utils";
import AuthService from "../services/auth.service";

export async function figureLoader({params}) {
    const api = await GofigrService.initialize();
    const apiId = params.apiId;

    const figure = await GofigrService.getFigure(apiId)

    let analysis;
    try {
        analysis = await GofigrService.getAnalysis(figure.analysis)
    } catch(err) {
        if(isPermissionDenied(err)) {
            analysis = null;
        } else {
            throw(err);
        }
    }

    if(AuthService.isLoggedIn()) {
        GofigrService.currentWorkspace = await GofigrService.getWorkspace(analysis.workspace)
        GofigrService.currentPath = [{endpoint: "/workspace/", object: GofigrService.currentWorkspace},
            {endpoint: "/analysis/", object: analysis},
            {endpoint: "/figure/", object: figure}]
    } else {
        GofigrService.currentPath = [
            {endpoint: "/analysis/", object: analysis},
            {endpoint: "/figure/", object: figure}]
    }

    return {api, figure};
}


export function FigureHeader(props) {
    const figure = props.figure;
    const revision = props.revision;

    return (
        <ActionHeader object={figure}
                      childObject={revision}
                      objectLabels={["Figure ID: ", "Revision ID: "]}
                      title={figure.name}
                      subtitle={figure.description}
                      icon={<PieChart/>}
                      endpoint="/figure/"
                      performDelete={async () => {
                          await GofigrService.deleteFigure(figure);
                          window.location.replace("/analysis/" + figure.analysis);
                      }}
                      deleteWarning={"Deleting a figure will delete all all revisions and all their data. This cannot be undone."}
        />
    )
}


class Figure extends Component {
    componentDidMount() {
        document.title = pageTitle(this.props.router.data.figure.name);
    }

    render() {
        const figure = this.props.router.data.figure;

        if(figure.revisions && figure.revisions.length) {
            orderByMostRecent(figure.revisions);
            return <Navigate to={"/revision/" + figure.revisions[0].api_id}/>
        }

        return <div>
            <FigureHeader figure={figure}/>
            <div className="container-fluid">
                <div className="alert alert-info" role="alert">
                    This figure doesn't have any revisions yet.
                </div>
            </div>
        </div>
    }
}

export const EditFigure = withLoginOptionalRedirect(() => {
    const data = useLoaderData();
    return <PropertyEditor object={data.figure} title_name="Figure" commitUpdates={updates => GofigrService.updateFigure(updates)}/>
})

export const ShareFigure = withLoginOptionalRedirect(() => {
    const data = useLoaderData();
    return <SharingSettings object={data.figure} endpoint="/figure/"/>
})

export default withLoginOptionalRedirect(withRouter(Figure));
