import {NOT_LOGGED_IN_ERROR} from "../services/gofigr.service";

export function isPermissionDenied(err) {
    return err.response && (err.response.status === 403 || err.response.status === 401);
}


export function getErrorMessage(err) {
    console.log(err);
    let message = "An unknown error has occurred";
    try {
        if(err === NOT_LOGGED_IN_ERROR) {
            message = "You must be logged in to perform this action"
        } else if (err.response && err.response.data && err.response.data.detail) {
            message = err.response.data.detail;
        } else if (err.data) {
            message = err.data;
        } else if(isPermissionDenied(err)) {
            message = "You do not have permissions to perform this action."
        } else if(err.response && err.response.data) {
            const data = err.response.data;
            if(Array.isArray(data)) {
                message = data[0];
            } else if(typeof(data) === "object") {
                message = data[Object.keys(data)[0]];
            }
        }
    } catch(e) {
        console.log(e)
        message = "An unknown error has occurred"
    }
    return message;
}


export function getErrorCode(err) {
    try {
        if(err.response.status) {
            return parseInt(err.response.status);
        } else {
            return null;
        }
    } catch(e) {
        console.log(e);
        return null;
    }
}