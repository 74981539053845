import React, {Component} from "react";
import {withRouter} from "../common/with-router";
import GofigrService from "../services/gofigr.service";
import {ActionHeader, Header} from "../components/header.components";
import {Book, Grid} from "react-feather";
import {withLoginOptionalRedirect} from "./login.view";
import {Newsfeed} from "../components/newsfeed.component";
import {ChildTable} from "../components/child_table.component";
import {Form, redirect, useLoaderData, useNavigate} from "react-router-dom";
import {makeEditAction, PropertyEditor} from "../components/property_editor.component";
import {CreateNew} from "../components/create_new.component";
import {SharingSettings} from "../components/sharing.component";
import {pageTitle} from "../js/utils";
import {isPermissionDenied} from "../common/errors";
import AuthService from "../services/auth.service";

export async function analysisLoader({params}) {
    const api = await GofigrService.initialize();
    const apiId = params.apiId;
    let analysis, figures, workspace;

    analysis = await GofigrService.getAnalysis(apiId)
    try {
        workspace = await GofigrService.getWorkspace(analysis.workspace)
    } catch(err) {
        if(isPermissionDenied(err)) {
            workspace = null;
        } else {
            throw(err);
        }
    }

    if(AuthService.isLoggedIn()) {
        GofigrService.currentWorkspace = workspace;
        GofigrService.currentPath = [{endpoint: "/workspace/", object: GofigrService.currentWorkspace},
            {endpoint: "/analysis/", object: analysis}]
    } else {
        GofigrService.currentPath = [];
    }

    figures = analysis.figures;

    const log = await GofigrService.getAnalysisLog(apiId);
    return {api, analysis, figures, log};
}

class Analysis extends Component {
    componentDidMount() {
        document.title = pageTitle(this.props.router.data.analysis.name);
    }


    render() {
        const data = this.props.router.data;
        const analysis = data.analysis;

        return (
            <div>
                <ActionHeader title={analysis.name}
                              subtitle={analysis.description}
                              icon={<Book/>}
                              object={analysis}
                              endpoint="/analysis/"
                              performDelete={async () => {
                                  await GofigrService.deleteAnalysis(analysis);
                                  window.location.replace("/workspace/" + analysis.workspace);
                              }}
                              deleteWarning={"Deleting an analysis will delete all figures, all revisions and all their data. This cannot be undone."}
                />
                <div className="row">
                    <div className="col-xl-8">
                        <div className="card m-2">
                            <div className="card-header">
                                Figures

                                <CreateNew endpoint={`/analysis/${analysis.api_id}/create-child`}/>
                            </div>
                            <div className="card-body">
                                <ChildTable children={this.props.router.data.figures}
                                            endpoint="/figure/"
                                            navigate={this.props.router.navigate}
                                            fetch={api_id => data.api.getFigure(api_id)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="card m-2">
                            <div className="card-header">
                                Recent activity
                            </div>
                            <div className="card-body">
                                <Newsfeed key={analysis.api_id} log={this.props.router.data.log}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const EditAnalysis = withLoginOptionalRedirect(() => {
    const data = useLoaderData();
    return <PropertyEditor object={data.analysis} title_name="Analysis" commitUpdates={updates => GofigrService.updateAnalysis(updates)}/>
})


export const ShareAnalysis = withLoginOptionalRedirect(() => {
    const data = useLoaderData();
    return <SharingSettings object={data.analysis} endpoint="/analysis/"/>
})

export default withLoginOptionalRedirect(withRouter(Analysis));
