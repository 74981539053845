import React, {useEffect, useState} from "react";
import {Input, Spinner} from "reactstrap";
import GofigrService, {orderByMostRecent} from "../services/gofigr.service";
import {getErrorMessage} from "../common/errors";
import {RightFloatingButton} from "./create_new.component";
import Moment from "react-moment";
import {Button, Modal} from "react-bootstrap";
import {AlertCircle, PlusCircle, XCircle} from "react-feather";
import {SimpleModal, YesCancelModal} from "./simple.modal";
import {SmallSpinner} from "./entity_list.component";
import {ApiId} from "./api_id.component";
import {WorkspaceName, WorkspaceSelector} from "./workspace_selector.component";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

export function ApiKey(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const item = props.api_key;

    if(item.last_used === "None") { // bug in early dev version, should be null. Since fixed.
        item.last_used = null;
    }

    if(item.revoked) {
        return "";
    }

    const confirm_button = <Button className="btn btn-danger py-3 my-2 me-1 w-auto"
                                  onClick={() => {
                                      setIsLoading(true);
                                      GofigrService.revokeApiKey(item.api_id).then(res => {
                                          item.revoked = true;
                                          setIsLoading(false);
                                          if(props.refreshParent) {
                                              props.refreshParent();
                                          }
                                      }).catch(err => setError(err))
                                  }}>
        {isLoading ? <Spinner className={"me-2"}/> : "Yes, revoke"}
    </Button>

    let action_elements = null;
    if(error) {
        action_elements = <div className="alert alert-danger">{getErrorMessage(error)}</div>
    } else if(isConfirmation) {
        action_elements = <div>
            <div className={"mb-3"}><span className="fw-bold me-2"><AlertCircle className={"me-2"}/>Are you sure?</span></div>
            {confirm_button}
            <Button className="btn btn-blue my-2 py-3 w-auto" onClick={() => {setIsConfirmation(false)}}>Cancel</Button>
        </div>
    } else {
        action_elements = <Button className="btn btn-danger" onClick={() => {setIsConfirmation(true)}}>
            <XCircle className={"me-2"}/>Revoke
        </Button>
    }

    return <tr>
        <td className={"col-2"}>
            {item.name}
        </td>
        <td className={"col-2"}>
            {item.created ? <span><Moment fromNow ago>{item.created}</Moment> ago</span> : "N/A"}
        </td>
        <td className={"col-2"}>
            {item.expiry ? <span><Moment fromNow>{item.expiry}</Moment></span> : "never"}
        </td>
        <td className={"col-2"}>
            {item.workspace ? <WorkspaceName workspace_id={item.workspace}/> : "Unrestricted"}
        </td>
        <td className={"col-2"}>
            {item.last_used ? <span><Moment fromNow ago>{item.last_used}</Moment> ago</span> : "Never"}
        </td>
        <td className={"col-2"}>
            {action_elements}
        </td>
    </tr>
}

export function ApiKeyManager(props) {
    const [keys, setKeys] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modal, setModal] = useState(null);
    const [isNewKeyLoading, setIsNewKeyLoading] = useState(false);
    const [newToken, setNewToken] = useState(null);
    const [newKeyError, setNewKeyError] = useState(null);
    const [newTokenInfoModal, setNewTokenInfoModal] = useState(null);
    const [isKeyUnrestricted, setIsKeyUnrestricted] = useState(true);
    const [keyExpiration, setKeyExpiration] = useState(null);

    const refresh = () => {
        GofigrService.listApiKeys().then(res => {
        setKeys(orderByMostRecent(res));
        setIsLoading(false);
    }).catch(err => {
        setIsLoading(false);
        setError(getErrorMessage(err))})
    }

    let visibleKeys = [];
    if(keys) {
        for(const key of keys) {
            if(!key.revoked) {
                visibleKeys.push(key);
            }
        }
    }

    useEffect(() => {refresh()}, []);

    let body = null;
    if(error) {
        body = <span className="alert alert-danger">{error}</span>
    } else if(isLoading) {
        body = <Spinner/>
    } else if(!visibleKeys || visibleKeys.length === 0) {
        body = "No API keys available"
    } else {
        const rows = keys.map(api_key => {return <ApiKey api_key={api_key} key={api_key.api_id} refreshParent={refresh}/>})
        body = <div className={"table-responsive"}>
            <table className="table table-hover">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Created</th>
                    <th>Expires</th>
                    <th>Scope</th>
                    <th>Last used</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        </div>
    }

    let infoModalBody = "";
    if(newKeyError) {
        infoModalBody = <div className="alert alert-danger">
            {getErrorMessage(newKeyError)}
        </div>
    } else if(newToken) {
        infoModalBody = <div>
            <span>Your new API key is shown below. You can find details on using API keys here:

            </span> <a href="https://gofigr.io/authenticating-with-api-keys/" target="_blank">Authenticating with API keys</a>
            <div className="alert alert-orange my-4">
                This is the <strong>only</strong> time that they key will be visible. Please save it in a secure location.
            </div>
            <div className={"w-100"}>
                <div className={"mx-auto"}>
                    <ApiId apiId={newToken.token} customStyle={{fontSize: "1.0em"}}/>
                </div>
            </div>
        </div>
    }

    return <div className="card mb-4">
        <div className="card-header">{"API Keys (" + String(visibleKeys.length) + "/25)"}
            <RightFloatingButton customClass={"btn-secondary"}
                                 onClick={() => {
                                     modal.show("Create new API key", "",
                                         () => {
                                         setIsNewKeyLoading(true);
                                         console.log(keyExpiration)
                                         GofigrService.createApiKey(modal.newKeyName,
                                             modal.keyExpiration ? modal.keyExpiration.toISOString() : null,
                                             modal.isKeyUnrestricted ? null : modal.workspaceScope
                                         ).then(res => {
                                             setIsNewKeyLoading(false);
                                             setNewKeyError(null);
                                             setNewToken(res);
                                             refresh();
                                             modal.hide();
                                             newTokenInfoModal.show("New API key", "");
                                         }).catch(err => {
                                             setIsNewKeyLoading(false);
                                             setNewKeyError(err);
                                             setNewToken(null);
                                             refresh();
                                             modal.hide();
                                             newTokenInfoModal.show("Error", "");
                                         })
                                     })}
            }>
                <PlusCircle className="me-2" size={"1.25em"}/> Create new API key
            </RightFloatingButton></div>
        <div className="card-body">
            <YesCancelModal callback={instance => setModal(instance)} yesLabel={isNewKeyLoading ? <SmallSpinner/> : "Create key"}>
                <div className={"container form-check"}>
                    <div className={"row"}>
                        Please enter the name for your new key:
                        <Input className={"mt-2"}
                               placeholder={"Enter API key name here."}
                               defaultValue={modal ? modal.newKeyName : ""}
                               onChange={event => {
                                   modal.newKeyName = event.target.value;
                               }}
                        />
                    </div>

                    <div className={"row my-4"}>
                        <label className="form-check-label p-0" htmlFor="expirationDate">
                            Expiration date. Leave blank if the key doesn't expire.
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker id="expirationDate"
                                            value={keyExpiration}
                                            onChange={event => {
                                                modal.keyExpiration = event;
                                                setKeyExpiration(event)}}/>
                        </LocalizationProvider>
                    </div>

                    <div className={"mt-4 mb-2 ms-2"}>
                        <input className="form-check-input" type="checkbox" value={isKeyUnrestricted}
                               checked={isKeyUnrestricted}
                               onChange={value => {
                                   modal.isKeyUnrestricted = !isKeyUnrestricted;
                                   setIsKeyUnrestricted(!isKeyUnrestricted);
                               }}
                               id="restrictScope"/>
                        <label className="form-check-label" htmlFor="restrictScope">
                            Allow this key access to all of my workspaces
                        </label>

                    </div>
                    {isKeyUnrestricted ? "" : <div className={"row"}>
                        <div className={"alert alert-info py-2"}>
                            This API key will only be able to access the workspace below.
                        </div>
                        <WorkspaceSelector onChange={worx => {
                            modal.workspaceScope = worx;
                        }}/>
                    </div>}
                </div>
            </YesCancelModal>

            <SimpleModal callback={instance => setNewTokenInfoModal(instance)}>
                <Modal.Body>
                    {infoModalBody}
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={() => newTokenInfoModal.hide()}>Close</Button>
                </Modal.Footer>
            </SimpleModal>
            {body}
        </div>
    </div>
}
