import Moment from "react-moment";
import {Link} from "react-router-dom";
import {UserLink} from "./userlink.component";
import GofigrService, {orderByMostRecent} from "../services/gofigr.service";
import React, {Component, useEffect, useState} from "react";
import {getErrorMessage} from "../common/errors";
import {AlertTriangle, MoreHorizontal, UserPlus} from "react-feather";
import {Button} from "react-bootstrap";
import {Spinner} from "reactstrap";
import {Placeholder} from "./placeholder.component";
import {MAX_GROUPED_THUMBNAILS, NEWSFEED_CARD_INFO_HEIGHT, THUMBNAIL_SIZE, THUMBNAIL_SIZE_PX} from "../js/config";
import {rgba} from "style-value-types";
import {groupBy} from "../js/utils";
import {WorkspaceName} from "./workspace_selector.component";

export const CARD_WIDTH = THUMBNAIL_SIZE + 55;
export const CARD_HEIGHT = 358;

function actionToVerb(action) {
    return `${action}d`
}

function getIndicator(action) {
    switch (action) {
        case "create":
            return "bg-green"
        case "update":
            return "bg-blue"
        case "delete":
            return "bg-red"
        case "error":
            return "bg-red"
        default:
            return "bg-gray-200"
    }
}

export function getTargetInfo(targetType) {
    const info = {
        workspace: {name: 'Workspace', endpoint: '/workspace/'},
        analysis: {name: 'Analysis', endpoint: '/analysis/'},
        figure: {name: 'Figure', endpoint: '/figure/'},
        figure_revision: {name: 'Revision', endpoint: '/revision/'}
    }
    if(targetType == null) {
        return {name: 'N/A', endpoint: null}
    } else if(targetType in info) {
        return info[targetType]
    } else {
        return {name: targetType, endpoint: null}
    }
}

function getLogGroupingKey(log) {
    const parentId = log.figure_id || log.analysis_id || log.workspace_id;
    return log.username + "-" + parentId;
}

function groupLogs(logs) {
    let groups = groupBy(logs, getLogGroupingKey);

    let groupedObjects = [];
    for(const [group_id, logs] of groups) {
        const orderedLogs = orderByMostRecent(logs);
        groupedObjects.push({group_id: group_id, logs: orderedLogs, timestamp: orderedLogs[0].timestamp})
    }
    return orderByMostRecent(groupedObjects);
}

export function ActivityThumbnail(props) {
    const logItem = props.logItem;

    if(!logItem.thumbnail) {
        return "";
    } else {
        const info = getTargetInfo(logItem.target_type);
        return <Link to={info.endpoint + logItem.target_id} className={"m-auto"}><img alt="Figure thumbnail"
                 className={"img-fluid border m-1 shadow-sm"} style={{maxWidth: "100%"}} src={`data:image/png;base64,${logItem.thumbnail}`}/></Link>
    }
}

export function ActivityThumbnailGallery(props) {
    if(!props.logs) {
        return "";
    }

    const filteredLogs = orderByMostRecent(props.logs.filter(logItem => logItem.thumbnail));
    if(!filteredLogs.length) {
        return "";
    }

    const mostRecent = filteredLogs[0];
    const visibleLogs = [filteredLogs[0]]

    const info = getTargetInfo(mostRecent.target_type);

    return <div className={"border border-1 border-gray-400 m-2 lift rounded flex-fill"} style={{width: CARD_WIDTH}}>
        <div className={"card h-100"}>
            <div className={"card-header my-0 py-1 text-black"}>
                <div className={"text-truncate"}>{mostRecent.figure_name}</div>

                {props.numberOfNotifications && props.numberOfNotifications > 2 ?
                    <span
                        className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
                        <Link to={info.endpoint + mostRecent.target_id}
                              className={"text-white"}>{props.numberOfNotifications}</Link>
                        <span className="visually-hidden">recent changes</span>
              </span> : ""}
            </div>

            <div className={"card-body d-flex justify-content-center p-0"}>
                {visibleLogs.map((logItem, idx) => {
                    return <ActivityThumbnail key={logItem.api_id} logItem={logItem}/>
                })}
            </div>

            <div className="card-footer py-1">
                <div className={"d-flex justify-content-between flex-wrap overflow-hidden"}>
                    <UserLink username={mostRecent.username}/> <Moment fromNow>{mostRecent.timestamp}</Moment>
                </div>
            </div>
        </div>
    </div>
        }

async function loadUntilThumbnail(logs) {
    if(!logs || logs.length === 0) {
        return null;
    }

    const result = await logs[0].fetch();
    if(result.thumbnail) {
        return result;
    } else {
        return await loadUntilThumbnail(logs.slice(1));
    }
}

function NewsfeedCard(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [logs, setLogs] = useState(props.data.logs);
    const [displayLog, setDisplayLog] = useState(null);

    useEffect(() => {
        if (!props.data || !props.data.logs) {
            setIsLoading(false);
            return;
        }

       loadUntilThumbnail(props.data.logs).then(result => {
           setDisplayLog(result);
       }).catch(e => {
           setIsError(e);
       }).finally(() => {
           setIsLoading(false);
       })
    }, []);

    let content;
    if(isError) {
        content = <div><AlertTriangle/></div>
    } else if(isLoading) {
        content = <div className={"border border-1 border-gray-400 m-2 rounded"} style={{height: CARD_HEIGHT, width: CARD_WIDTH}}>
            <Placeholder width={"100%"} height={"100%"}/>
        </div>
    } else if(!displayLog) {
        return "";
    } else {
        content = <ActivityThumbnailGallery logs={[displayLog]} numberOfNotifications={logs.length}/>
    }

    return content
}

function NewsfeedItem(props) {
    const [data, setData] = useState(props.data);
    const [isLoading, setIsLoading] = useState(!(props.data && props.data.loaded));
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if(data && data.loaded) {
            return;
        }

        data.fetch().then(fullData => {
            if(fullData) {
                fullData.loaded = true;
            }
            setData(fullData);
            setIsLoading(false);
            setIsError(false);
        }).catch(err => {
            console.log(err);
            setIsError(err);
            setIsLoading(false);
        })
    }, []);

    if(!data) {
        return "";
    }

    const targetInfo = getTargetInfo(data.target_type)

    let details;
    let thumbnail = null;
    if(data.deleted) {
        if(data.action === "delete") {
            details = data.deleted_sentinel || "details unavailable"
        } else {
            details = (data.deleted_sentinel || "details unavailable") + " (deleted item)"
        }
    } else if(targetInfo && data.target_id) {
        const linkTarget = targetInfo.endpoint + data.target_id;
        details = <Link to={linkTarget}>{data.target_name}</Link>
        if(data.thumbnail) {
            thumbnail = <Link to={linkTarget}>
                <img className="shadow-sm border" style={{maxWidth: "100%"}} src={`data:image/png;base64,${data.thumbnail}`}/>
            </Link>
        }
    } else {
        details = data.target_name + ": details unavailable (" + targetInfo.name + ")"
    }

    let analysis = ""
    if(data.analysis_id && props.showAnalysis) {
        analysis = <><Link to={"/analysis/" + data.analysis_id}>{(data.analysis_name || "N/A")}</Link><span> {" > "} </span></>
    }

    if(isError) {
        return <div className="timeline-item">
            <div className="timeline-item-marker">
                <div className="timeline-item-marker-text">
                    <AlertTriangle/>
                </div>
                <div className={"timeline-item-marker-indicator " + getIndicator("error")}/>
            </div>
            <div className="timeline-item-content">
                {getErrorMessage(isError)}
            </div>
        </div>
    } else {
        return <div className="timeline-item">
            <div className="timeline-item-marker">
                <div className="timeline-item-marker-text">
                    {isLoading ? "" : <Moment fromNow>{data.timestamp}</Moment>}
                </div>
                <div className={"timeline-item-marker-indicator " + getIndicator(data.action)}/>
            </div>
            <div className="timeline-item-content">
                {isLoading ? <Placeholder width={"100%"} height={"100px"}/> : <>
                    <div>{analysis} <UserLink username={data.username}/> {actionToVerb(data.action)} {details}</div>
                    <div>
                        {thumbnail}
                    </div>
                </>}
            </div>
        </div>
    }
}

export class Newsfeed extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleItems: [],
            allItems: props.log && orderByMostRecent(props.log),
            pageSize: props.pageSize || 20,
        }
    }

    async loadMore() {
        let visibleItems = this.state.allItems.slice(0, Math.min(this.state.allItems.length,
            this.state.visibleItems.length + this.state.pageSize));

        this.setState({visibleItems: visibleItems});
    }

    componentDidMount() {
        this.loadMore();
    }

    render() {
        const items = this.state.allItems;
        if(!items || items.length === 0) {
            return "No activity to show"
        }

        const loadMore = <Button className="btn btn-light w-100 py-1 mt-4" onClick={() => {
            this.loadMore();
        }}>
            <MoreHorizontal/>
        </Button>

        return <>
            <div className="timeline timeline-xs">
                {this.state.visibleItems.map(item => {
                    return <NewsfeedItem data={item} key={JSON.stringify(item)} showAnalysis={this.props.showAnalysis}/>
                })}

            </div>

            {this.state.error ? <div className={"alert alert-danger py-1 my-4"}>{this.state.error}</div> : ""}

            {this.state.visibleItems.length >= this.state.allItems.length ? "": loadMore}
        </>
    }
}

export class HomeFeed extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleItems: [],
            allItems: props.log && groupLogs(props.log),
            pageSize: props.pageSize || 50,
        }
    }

    async loadMore() {
        let visibleItems = this.state.allItems.slice(0, Math.min(this.state.allItems.length,
                                                                 this.state.visibleItems.length + this.state.pageSize));

        this.setState({visibleItems: visibleItems});
    }

    componentDidMount() {
        this.loadMore();
    }

    render() {
        const items = this.state.allItems;
        if(!items || items.length === 0) {
            return "No activity to show"
        }

        const loadMore = <Button className="btn btn-light py-1 m-2 border border-1 border-gray-400 flex-fill" style={{width: CARD_WIDTH}}
                                 onClick={() => {
            this.loadMore();
        }}>
            <MoreHorizontal/>
        </Button>

        return <>
            <div className="d-flex flex-wrap">
                {this.state.visibleItems.map(item => {
                    return <NewsfeedCard data={item} key={JSON.stringify(item)} showAnalysis={this.props.showAnalysis}/>
                })}

                {this.state.visibleItems.length >= this.state.allItems.length ? "": loadMore}

            </div>

            {this.state.error ? <div className={"alert alert-danger py-1 my-4"}>{this.state.error}</div> : ""}
        </>
    }
}
