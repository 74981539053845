import React, {useEffect, useState} from "react";
import GofigrService from "../../services/gofigr.service";
import Moment from "react-moment";
import {UserLink} from "../../components/userlink.component";
import {Link} from "react-router-dom";
import {getTargetInfo} from "../../components/newsfeed.component";
import DataTable from "react-data-table-component";
import {ExpandedComponent} from "./activity.view";
import {ApiId} from "../../components/api_id.component";
import {AdminDataPanel, AdminPage} from "../../components/admin_entity_table.component";
import DatePicker from "react-datepicker";
import {Checkbox} from "semantic-ui-react";
import Select from "react-select";

const columns = [
    {
        name: 'Created',
        selector: row => <Moment fromNow>{row.created_on}</Moment>
    },
    {
        name: 'Completed',
        selector: row => row.completed_on ? <Moment fromNow>{row.completed_on}</Moment> : "N/A"
    },
    {
        name: 'Pending',
        selector: row => {
            if(row.completed_on) {
                return "";
            }
            const overdue = row.pending_seconds > 60 * 60;
            const label = overdue ? ">1hr" : (Math.round(row.pending_seconds) + "s")
            let badge = "";
            if(overdue) {
                badge = <span className={"mx-2 badge bg-warning"}>Overdue</span>
            }
            return <>{label}{badge}</>
        }
    },
    {
        name: 'Status',
        selector: row => row.status
    },
    {
        name: 'Result',
        selector: row => JSON.stringify(row.result)
    },
    {
        name: 'Indexer',
        selector: row => row.indexer_name
    },
    {
        name: 'Target Type',
        selector: row => {
            const info = getTargetInfo(row.target_type);
            return info.name;
        }
    },
    {
        name: 'Target',
        selector: row => {
            if(row.target_exists) {
                const info = getTargetInfo(row.target_type);
                return <Link className={"font-monospace"} to={info.endpoint + row.target_id}>{row.target_id}</Link>;
            } else {
                return <span className={"badge bg-red"} style={{fontSize: "1em"}}>deleted</span>
            }
        }
    }
];

export function InfrastructureView(props) {
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [status, setStatus] = useState(["open"]);
    const [statusSelect, setStatusSelect] = useState([{value: 'open', label: 'open'}]);
    const [data, setData] = useState([]);

    return <AdminPage title={"Infrastructure"}>

        {/* Filters */}
        <div className={"card m-2"}>
            <div className={"card-header"}>Filters</div>
            <div className={"card-body"}>
                <div className={"row"}>
                Show requests since: <DatePicker selected={startDate} onChange={date => setStartDate(date)}/>
                </div>
                <div className={"row"}>
                    Status: <Select isMulti onChange={event => {
                        setStatus(event.map(x => x.label))
                        setStatusSelect(event);
                    }}
                                    value={statusSelect}
                                    options={[
                                        {value: 'open', label: 'open'},
                                        {value: 'pending', label: 'pending'},
                                        {value: 'target_deleted', label: 'target_deleted'},
                                        {value: 'done', label: 'done'},
                                        {value: 'error', label: 'error'}
                                    ]}/>
                </div>
            </div>
        </div>

        <AdminDataPanel title={"Indexing requests"}
                        key={JSON.stringify({startDate, status})}
                        columns={columns}
                        onChange={setData}
                        fetchAll={async () => GofigrService.indexStatus(startDate, status)}/>
    </AdminPage>
}