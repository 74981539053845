import GofigrService from "../services/gofigr.service";
import {useState} from "react";

export function AdminOnly(props) {
    const gf = GofigrService;

    if(!gf || !gf.userInfo || !gf.userInfo.is_staff) {
        return null;
    } else {
        return <>{props.children}</>
    }
}