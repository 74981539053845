import AuthService from "../services/auth.service";

export function NotLoggedInAlert(props) {
    if(AuthService.isLoggedIn()) {
        return "";
    }

    const next = encodeURIComponent(document.URL);

    return (
        <div className={"alert alert-warning p-2 my-0 sticky-top text-center"}>
            You are viewing this page anonymously and functionality may be limited.
            Click <a href={"/login?next=" + next}>here</a> to login.
        </div>
    )
}
