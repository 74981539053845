import {withLoginOptionalRedirect} from "./login.view";
import {withRouter} from "../common/with-router";
import {Header} from "../components/header.components";
import GofigrService from "../services/gofigr.service";
import {redirect, useLoaderData, useNavigate, useSearchParams} from "react-router-dom";
import Form from "react-validation/build/form";
import {User} from "react-feather";
import {GfAvatar} from "../components/userlink.component";
import {makeEditAction} from "../components/property_editor.component";
import React, {Component, useEffect, useState} from "react";
import Moment from "react-moment";
import {SimpleModal} from "../components/simple.modal";
import {pageTitle, required} from "../js/utils";
import {getErrorMessage} from "../common/errors";
import {Spinner} from "reactstrap";
import {FormTextInput, passwordsMatch} from "./signup.view";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import {ApiKeyManager} from "../components/api_keys.component";

export async function userLoader({params}) {
    const username = params.username;
    let userInfo, selfUserInfo, found;
    try {
        userInfo = await GofigrService.getUserInfo(username);
        selfUserInfo = await GofigrService.getUserInfo();
        found = true;
    } catch(err) {
        if(err && err.response && err.response.status === 404) {
            found = false;
        } else {
            throw(err)
        }
    }

    return {userInfo, selfUserInfo, username, found};
}

function EmailConfirmationStatus(props) {
    const [codeSent, setCodeSent] = useState(false);
    const [error, setError] = useState(null);

    const info = props.userInfo;
    if(info.email_confirmed) {
        return <div className={"alert alert-info py-2"}>Your email address has been successfully verified.</div>
    } else if(error) {
        return <div className={"alert alert-danger"}>{error}</div>
    } else if(codeSent) {
        return <div className={"alert alert-info"}>Code sent. Please click the link in the email to verify your email address.</div>
    } else {
        return <div className={"alert alert-warning"}>
            Your email has not been confirmed. Click <a href={"#"} onClick={() => {
                GofigrService.requestEmailVerification(info).then(res => {
                    setCodeSent(true);
                    setError(null);
                }, err => {
                    setCodeSent(false);
                    setError(getErrorMessage(err));
                })
            }}>here</a> to send a verification code.</div>
    }
}

export function ConfirmEmailView(props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const [params, _] = useSearchParams();
    const token = params.get('token');

    const data = useLoaderData();

    useEffect(() =>{
        GofigrService.verifyEmail(data.userInfo, token).then(res => {
            setLoading(false);
            setError(null);
            setSuccess(true);
        }, err => {
            setLoading(false);
            setError(getErrorMessage(err));
            setSuccess(false);
        })
    })

    let body;
    if(loading) {
        body = <div><Spinner/> <span className={"ms-2"}>Verifying...</span></div>
    } else if(error) {
        body = (
            <div>
                <div className={"alert alert-danger"}>{error}. You can request a new code below</div>
                <EmailConfirmationStatus userInfo={data.userInfo}/>
            </div>
        )
    } else {
        body = <div className={"alert alert-success"}>Email verified successfully. You can close this page.</div>
    }

    return (
        <div className="container-xl px-4">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                        <img src="/logo_wide.png" className="m-4 img-fluid mx-auto d-block"/>
                        <div className="card-header justify-content-center"><h3
                            className="fw-light my-2">Email Verification</h3></div>
                        <div className="card-body">
                            {body}
                        </div>
                    </div>
                </div>
            </div>
    </div>
    )
}

class SelfUserView extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        const info = this.props.userInfo;
        this.state = {
            userInfo: info,
            username: info.username,
            firstName: info.first_name,
            lastName: info.last_name,
            email: info.email,
            isStaff: info.is_staff,
            password: null,
            fieldErrors: null,
            loading: false,
            message: null,
            messageClass: "alert-danger",
            avatarMessage: null,
        }
    }

    updateInfo(info) {
        this.setState({
            userInfo: info,
            isStaff: info.is_staff,
            username: info.username,
            firstName: info.first_name,
            lastName: info.last_name,
            email: info.email,
        })
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({message: "", loading: true})

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            const updatedUser = {
                username: this.state.username,
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                email: this.state.email
            }
            if(this.state.password) {
                updatedUser.password = this.state.password;
            }

            GofigrService.updateUser(updatedUser).then(res => {
                    let message;
                    if(updatedUser.password) {
                        message = "Password and user information updated successfully";
                    }  else {
                        message = "Changes saved successfully";
                    }
                    this.setState({loading: false, message: message, messageClass: "alert-info",
                                        fieldErrors: null})
                    this.passwordComponent.clear();
                    this.confirmPasswordComponent.clear();
                    this.updateInfo(res);
                },
                err => {
                    if(err.response.status == 400) {
                        this.setState({loading: false, fieldErrors: err.response.data, message: "", messageClass: "alert-danger"})
                    } else {
                        this.setState({loading: false, fieldErrors: null, message: getErrorMessage(err), messageClass: "alert-danger"});
                    }
                })
        } else {
            this.setState({loading: false})
        }
    }

    render() {
        const info = this.state.userInfo;

        return <div>
            <Header subtitle="User Profile"
                    title={<>
                        <span>{info.username}</span>
                        {info.is_staff ? <span className="ms-3 badge bg-primary">🚀 Staff</span> : ""}
                        <span className="ms-3 badge bg-info">You</span>
                    </>}
                    icon={<User/>} hidePath={true}/>
            <div className="row m-2">
                <div className="col-xl-4">
                    <div className="card mb-4 mb-xl-0">
                        <div className="card-header">Profile Picture</div>
                        <div className="card-body text-center align-content-center">
                            <div className={"d-flex justify-content-center mb-3"}><GfAvatar userInfo={info} size={"128px"}/></div>
                            <div className="small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
                            <input
                                className="form-control"
                                type="file"
                                accept="image/*"
                                name="avatarImage"
                                onChange={async (event) => {
                                    const reader = new FileReader();
                                    reader.onload = async () => {
                                        const updatedInfo = {
                                            username: info.username,
                                            avatar: btoa(reader.result)
                                        }
                                        GofigrService.updateUser(updatedInfo).then(res => {
                                            this.setState({avatarMessage: null});
                                            window.location.reload();
                                        }, err => {
                                            this.setState({avatarMessage: getErrorMessage(err)});
                                        })

                                    }
                                    reader.readAsBinaryString(event.target.files[0]);
                                }}/>

                            {this.state.avatarMessage && (
                                <div className="form-group">
                                    <div className="alert alert-danger mt-4" role="alert">
                                        {this.state.avatarMessage}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="card mb-4">
                        <div className="card-header">Account Details</div>
                        <div className="card-body">
                            {/* Login form */}
                            <Form
                                onSubmit={this.handleSubmit}
                                ref={c => {
                                    this.form = c;
                                }}
                            >
                                <FormTextInput name={"username"}
                                               label={"Username"}
                                               placeholder={"Enter username"}
                                               validations={[required]}
                                               messages={this.state.fieldErrors}
                                               value={this.state.username}
                                               disabled={true}
                                               onChange={val => this.setState({"username": val})}/>

                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <FormTextInput name={"firstName"}
                                                       label={"First Name"}
                                                       placeholder={"Enter first name"}
                                                       validations={[required]}
                                                       messages={this.state.fieldErrors}
                                                       value={this.state.firstName}
                                                       onChange={val => this.setState({"firstName": val})}/>
                                    </div>

                                    <div className={"col-md-6"}>
                                        <FormTextInput name={"lastName"}
                                                       label={"Last Name"}
                                                       placeholder={"Enter last name"}
                                                       validations={[required]}
                                                       messages={this.state.fieldErrors}
                                                       value={this.state.lastName}
                                                       onChange={val => this.setState({"lastName": val})}/>
                                    </div>
                                </div>

                                <FormTextInput name={"email"}
                                               label={"Email"}
                                               placeholder={"Enter email address"}
                                               validations={[required]}
                                               messages={this.state.fieldErrors}
                                               value={this.state.email}
                                               onChange={val => this.setState({"email": val})}/>

                                <EmailConfirmationStatus userInfo={this.state.userInfo}/>

                                <div className={"row mt-4"}>
                                    <div className={"col-md-6"}>
                                        <FormTextInput name={"password"}
                                                       label={"Change Password"}
                                                       type={"password"}
                                                       placeholder={"Enter new password"}
                                                       messages={this.state.fieldErrors}
                                                       value={this.state.password}
                                                       validations={[]}
                                                       onChange={val => this.setState({"password": val})}
                                                       callback={component => this.passwordComponent = component}/>
                                    </div>

                                    <div className={"col-md-6"}>
                                        <FormTextInput name={"confirmPassword"}
                                                       label={"Confirm Password"}
                                                       type={"password"}
                                                       placeholder={"Please confirm new password"}
                                                       messages={this.state.fieldErrors}
                                                       validations={[passwordsMatch(() => this.state.password)]}
                                                       onChange={val => this.setState({"confirmPassword": val})}
                                                       callback={component => this.confirmPasswordComponent = component}/>
                                    </div>
                                </div>

                                <div
                                    className="d-flex align-items-center justify-content-between mt-4 mb-0">
                                    <button
                                        className="btn btn-primary btn-block"
                                        disabled={this.state.loading}
                                    >
                                        {this.state.loading && (
                                            <span className="spinner-border spinner-border-sm me-2"></span>
                                        )}
                                        <span>Save</span>
                                    </button>
                                </div>

                                {this.state.message && (
                                    <div className="form-group">
                                        <div className={"alert mt-4 " + this.state.messageClass} role="alert">
                                            {this.state.message}
                                        </div>
                                    </div>
                                )}
                                <CheckButton
                                    style={{display: "none"}}
                                    ref={c => {
                                        this.checkBtn = c;
                                    }}
                                />

                            </Form>
                        </div>
                    </div>
                    <ApiKeyManager/>
                </div>
            </div>
        </div>
    }
}

function OtherUserView(props) {
    const data = useLoaderData();
    const info = data.userInfo;
    console.log(info)

    return <div>
        <Header subtitle="User Profile" title={info.username} icon={<User/>} hidePath={true}/>
        <div className="row m-4">
            <div className="col-xl-12">
                <div className="card mb-4">
                    <div className="card-header">User Profile</div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xl-4 text-center mb-4">
                                <GfAvatar userInfo={info} size={"128px"}/>
                            </div>
                            <div className="col-xl-8">
                                <dl className={"row"}>
                                    <dt className="col-sm-3">Username:</dt>
                                    <dd className="col-sm-9">{info.username}</dd>

                                    <dt className="col-sm-3">First name:</dt>
                                    <dd className="col-sm-9">{info.first_name && info.first_name !== "" ? info.first_name : "-"}</dd>

                                    <dt className="col-sm-3">Last name:</dt>
                                    <dd className="col-sm-9">{info.last_name && info.last_name !== "" ? info.last_name : "-"}</dd>

                                    <dt className="col-sm-3">Member since:</dt>
                                    <dd className="col-sm-9"><Moment format="YYYY">{info.date_joined}</Moment></dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function UserView(props) {
    const data = useLoaderData();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = pageTitle(data.userInfo ? data.userInfo.username : null);
    })

    if(data.found) {
        if (data.userInfo.username === data.selfUserInfo.username) {
            return <SelfUserView userInfo={data.userInfo}/>
        } else {
            return <OtherUserView/>
        }
    } else {
        return <SimpleModal show={true} title={"User not found"} body={"User " + data.username + " not found"}
        onHide={() => navigate(-1)}/>
    }
}

export default withLoginOptionalRedirect(withRouter(UserView))
