import React from "react";

export function DefaultFooter(props) {
    return <footer className="footer-admin mt-auto footer-light">
        <div className="container-xl px-4 small text-center">
            <div className="row">
                <div className="col-md-4">Copyright © Flagstaff Solutions, LLC 2024</div>
                <div className="col-md-4">
                    <a href="https://gofigr.io/privacy-policy/" target="_blank">Privacy Policy</a>
                    <span className={"mx-1"}>•</span>
                    <a href="https://gofigr.io/terms-and-conditions/" target="_blank">Terms &amp; Conditions</a>
                </div>
                <div className="col-md-4">
                    <span>{process.env.REACT_APP_VERSION}</span>
                </div>
            </div>
        </div>
    </footer>
}
