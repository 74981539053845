import React, {Component, useState} from "react";
import {Link, useLoaderData} from "react-router-dom";
import {withLoginOptionalRedirect, withLoginRequiredRedirect} from "./login.view";
import GofigrService, {orderByMostRecent} from "../services/gofigr.service";
import {HomeFeed, Newsfeed} from "../components/newsfeed.component";
import {WithAsyncLoader} from "../components/async.component";
import {WelcomeBanner} from "../components/welcome.component";
import {Circle, RefreshCw, Tablet} from "react-feather";
import {RightFloatingButton} from "../components/create_new.component";
import {Checkbox} from "semantic-ui-react";
import {FormControlLabel, FormGroup, Switch, ToggleButton, ToggleButtonGroup} from "@mui/material";

export function HomeView(props) {
    const [nonce, setNonce] = useState(0);
    const [isDetailed, setIsDetailed] = useState(false);

    const data = useLoaderData();
    const newsfeed = <WithAsyncLoader key={nonce} target={function({log}) { return(isDetailed ? <Newsfeed log={log} /> : <HomeFeed log={log}/>) }}
                                      targetProps={{}} loaders={{log: () => fetchGlobalLog(data.api)}}/>

    return (<>
        <WelcomeBanner userInfo={data.userInfo}/>
        <div className="card m-2">
            <div className="card-header">
                Recent Figures

                <div className={"float-end"}>
                    <ToggleButtonGroup className={"me-2 p-0"}
                        value={isDetailed}
                        exclusive onChange={e => setIsDetailed(!isDetailed)}
                        aria-label={"View type"}>
                        <ToggleButton style={{padding: 5}} value={true} aria-label="Detailed">
                            Detailed
                        </ToggleButton>
                        <ToggleButton style={{padding: 5}} value={false} aria-label="Compact">
                            Compact
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <Link to={null} onClick={e => setNonce(nonce + 1)}>
                        <span className={"btn btn-primary px-2 py-2"}>
                            <RefreshCw className="me-2" size={"1.25em"}/> Refresh
                        </span>
                    </Link>

                </div>
            </div>
            <div className={"card-body"}>
                {newsfeed}
            </div>
        </div>
    </>)
}

async function fetchGlobalLog(gf) {
    const workspaceLogs = await Promise.all(gf.workspaces.map(workspace => {
        return GofigrService.getWorkspaceLog(workspace.api_id, false);
    }));

    const globalLog = []
    workspaceLogs.forEach(log => {globalLog.push(...log)})
    return orderByMostRecent(globalLog);
}

export async function homeViewLoader({params}) {
    const gf = await GofigrService.initialize();
    const userInfo = await GofigrService.getUserInfo();

    return {api: gf,
            userInfo: userInfo};
}

export default withLoginRequiredRedirect(HomeView);
