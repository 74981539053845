import React, {Component, useEffect} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";

import { withRouter } from '../common/with-router';
import {Navigate, useNavigate} from "react-router-dom";
import GofigrService, {NOT_LOGGED_IN_ERROR} from "../services/gofigr.service";
import {pageTitle} from "../js/utils";

export function Logout() {
    useEffect(() => {
        document.title = pageTitle("Logout");
    })

    GofigrService.logout();
    return <Navigate to={"/login"}/>
}
