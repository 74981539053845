import Moment from "react-moment";
import {AlertTriangle, Image, MoreHorizontal} from "react-feather";
import {UserLink} from "./userlink.component";
import {EntitySize} from "./entity_size.component";
import React, {useEffect, useState} from "react";
import {Placeholder} from "./placeholder.component";
import {Button} from "react-bootstrap";

export function ChildEntity(props) {
    const [item, setItem] = useState(props.item);
    const [isFetched, setIsFetched] = useState(false);
    const [isError, setIsError] = useState(false);
    const navigate = props.navigate;
    const endpoint = props.endpoint;
    const fetch = props.fetch;

    useEffect(() => {
        //console.log(fetch);
        if(item.thumbnail || !fetch) {
            setIsFetched(true);
            return;  // already fetched, nothing to do
        }

        const doFetch = async () => {
            const result = await fetch(item.api_id);
            setItem(setLastActivity(result));
            setIsFetched(true);
        }
        doFetch().catch(err => {
            setIsError(true);
            console.error(err);
        });
    }, []);

    let thumbnail;
    if(item.thumbnail) {
        thumbnail = <img className="shadow-sm border" src={`data:image/png;base64,${item.thumbnail}`}/>
    } else if(isFetched) {
        thumbnail = <div className="mx-auto"><Image/></div>;
    } else if(!isError) {
        thumbnail = <div className="shadow-sm border mx-auto" style={{width: "200px", height: "200px"}}>
            <Placeholder width={"200px"} height={"200px"}/>
        </div>
    } else {
        thumbnail = <AlertTriangle/>;
    }

    return <tr key={JSON.stringify(item)}
               className="pointer"
               onClick={() => navigate(endpoint + item.api_id)}>
        <td className={"text-center"}>{thumbnail}</td>
        <td>{item.name}</td>
        <td>{item.description}</td>
        <td><EntitySize object={item}/></td>
        {item.lastActivity ?
            <td><Moment fromNow ago>{item.lastActivity}</Moment> ago by <UserLink username={item.lastActivityUser}/>
            </td> : <td>N/A</td>}
    </tr>
}

export function setLastActivity(item) {
    if(item.child_updated_on) {
        item.lastActivity = item.child_updated_on;
        item.lastActivityUser = item.child_updated_by;
    } else if(item.updated_on) {
        item.lastActivity = item.updated_on;
        item.lastActivityUser = item.updated_by;
    } else if(item.created_on) {
        item.lastActivity = item.created_on;
        item.lastActivityUser = item.created_by;
    } else {
        item.lastActivity = null;
        item.lastActivityUser = null;
    }
    return(item);
}

export function ChildTable(props) {
    const [pageSize, setPageSize] = useState(20);
    const [visibleChildren, setVisibleChildren] = useState(pageSize);

    let children = props.children;
    const endpoint = props.endpoint;
    const navigate = props.navigate;
    const fetch = props.fetch;

    const defaultMakeChildElement = item => <ChildEntity key={item.api_id}
                                                            item={item}
                                                            navigate={navigate}
                                                            endpoint={endpoint}
                                                            fetch={fetch}/>
    const makeChildElement = props.makeChildElement ? props.makeChildElement : defaultMakeChildElement;

    const defaultMakeHeader = () => <thead><tr>
        <th className={"text-center"}>Preview</th>
        <th>Name</th>
        <th>Description</th>
        <th>Size</th>
        <th>Last activity</th>
    </tr></thead>;
    const tableHeader = props.tableHeader ? props.tableHeader : defaultMakeHeader;

    if(!children || !children.length) {
        return "Nothing to show"
    }

    children = children.map(setLastActivity)
    children.sort((a, b) => new Date(b.lastActivity) - new Date(a.lastActivity))

    const childrenSubset = children.slice(0, Math.min(visibleChildren, children.length))

    const loadMore = <Button className="btn btn-light w-100 py-1 mt-4" onClick={() => {
        setVisibleChildren(Math.min(children.length, visibleChildren + pageSize));
    }}>
        <MoreHorizontal/>
    </Button>

    return <div className={"table-responsive"}>
                <table className="table table-hover">
                {tableHeader()}
                <tbody>
                    {childrenSubset.map(makeChildElement)}
                </tbody>
            </table>
        {visibleChildren >= children.length ? "" : loadMore}
    </div>
}
