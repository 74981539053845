import React, {Component, useEffect, useState} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import {FormTextInput} from "./signup.view";
import GofigrService, {getEndpoint} from "../services/gofigr.service";
import {getErrorMessage} from "../common/errors";
import {Spinner} from "reactstrap";
import {Image, Search, X} from "react-feather";
import {formatEntityType} from "../components/sharing.component";
import {ApiId} from "../components/api_id.component";
import {Navigate} from "react-router-dom";
import {Button} from "react-bootstrap";
import trim from "validator/es/lib/trim";
import {LARGE_THUMBNAIL_SIZE, LOAD_DELAY_MS, SEARCH_DELAY_MS, THUMBNAIL_SIZE} from "../js/config";
import {Placeholder} from "../components/placeholder.component";
import {CARD_WIDTH} from "../components/newsfeed.component";

function entityTypeToColorClass(entity_type) {
    switch(entity_type) {
        case "workspace":
            return "dark";

        case "analysis":
            return "primary";

        case "figure":
            return "info";

        case "figure_revision":
            return "secondary";
    }
}

export function SearchItem(props) {
    const [navigateTo, setNavigateTo] = useState(null);
    const [thumbnailData, setThumbnailData] = useState(props.item.object.thumbnail);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(thumbnailData) {
            return;
        }

        let delayFunc = (thunk) => {thunk()}
        if(props.item && props.item.rank) {
            if(props.item.rank <= 10) {
                // No delay
            } else {
                delayFunc = (thunk) => {setTimeout(thunk, LOAD_DELAY_MS)}
            }
        }

        let fetchThumbnail = () => {
            GofigrService.getThumbnail(object, LARGE_THUMBNAIL_SIZE)
                .then(data => {
                    setThumbnailData(data);
                    setIsLoading(false);
                })
                .catch(err => {
                    setIsLoading(false);
                })
        }

        delayFunc(fetchThumbnail);
    }, []);

    const item = props.item;
    const object = item && item.object;
    if(!item || !object) {
        return "No data available";
    }


    if(navigateTo) {
        return <Navigate to={navigateTo}/>
    }

    let thumbnail = <div className="mx-auto align-middle"><Image/></div>;
    if(thumbnailData && thumbnailData['thumbnail'])
        thumbnail = <img className="shadow-sm border"
                         style={{maxHeight: "100%", maxWidth: "100%"}}
                         src={`data:image/${thumbnailData['format']};base64,${thumbnailData['thumbnail']}`}/>
    else if(isLoading) {
        thumbnail = <Placeholder width={THUMBNAIL_SIZE} height={THUMBNAIL_SIZE}/>
    }

    let details;
    let cardTitle;
    const clr = entityTypeToColorClass(object.entity_type);
    switch(object.entity_type) {
        case "figure_revision":
            cardTitle = <>{object.figure_metadata.name}<span className={"badge ms-2 " + `bg-${clr}`}>{formatEntityType(object.entity_type)}</span></>;
            details = <div className={'mt-2'}><dl>
                <dt>Revision</dt><dd>#{object.revision_index + 1}</dd>
            </dl></div>
            break;

        default:
            cardTitle = <>{object.name || "N/A"}<span className={"badge float-end ms-2 " + `bg-${clr}`}>{formatEntityType(object.entity_type)}</span></>;

            details = <div className={'mt-2'}><dl>
                <dt>Description</dt><dd>{object.description || "N/A"}</dd>
            </dl></div>
            break;
    }

    return (
        <div className={"m-2 card lift border-gray-400 flex-fill" + (props.blur ? "opacity-25" : "")}
             style={{cursor: "pointer", width: CARD_WIDTH, maxWidth: CARD_WIDTH * 1.5}}
             onClick={() => {
            if(props.onClick) {
                props.onClick();
            }

            const url = getEndpoint(object) + object.api_id;

            if(props.router) {
                props.router.navigate(url);
            } else {
                setNavigateTo(url);
            }
            if(props.onNavigate) {
                props.onNavigate(url)
            }
        }}>
            <div className={"card-header my-0 py-1"}><span className={"text-black"}>{cardTitle}</span></div>

            <div className={"card-body my-0 py-0"}>
                <div className={"row my-2"}>
                    {thumbnail}
                </div>
                <div className={"row"}>
                    {details}
                </div>
            </div>
        </div>
    );
}

function SearchHeader(props) {
    return (
        <div>
            <header className={"page-header page-header-light border-bottom bg-white mb-4"}>
                <div className="container-fluid">
                    <div className="page-header-content pt-4 pb-4">

                        <div className="align-items-center justify-content-between">
                            <div className="row">
                                <div className="col-md-10">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <Search/>
                                        </div>
                                        Search
                                    </h1>
                                </div>
                            </div>

                            <div className="page-header-subtitle">
                                Find revisions, figures, analyses and workspaces.
                            </div>
                        </div>
                    </div>
                </div>

                {props.children}

            </header>
        </div>
    );
}

export class SearchView extends Component {
    constructor(props) {
        super(props);

        this.timerId = null;

        const query = this.parseURLSearchParams();

        this.state = {
            searchString: query,
            results: null,
            loading: false,
            error: null,
            queryImage: null,
        }

        document._search_nonce = 0;

        if(this.state.searchString && this.state.searchString !== "") {
            this.state.loading = true;
            this.queueSearch(this.state.searchString);
        }
    }

    componentDidMount() {

    }

    parseURLSearchParams() {
        const path = window.location.pathname;
        const hash = window.location.hash;
        const params = (new URL(document.location)).searchParams;
        const query = params.get("q");
        return query ? query : "";
    }

    updateURLSearchParams(query) {
        const path = window.location.pathname;
        const hash = window.location.hash;
        const params = (new URL(document.location)).searchParams;
        params.set('q', query);

        window.history.replaceState({}, '', `${path}?${params.toString()}${hash}`);
    }

    queueSearch(val) {
        const parent = this;
        const nonce = document._search_nonce;

        if(this.timerId) {
            clearTimeout(this.timerId);
        }

        parent.timerId = setTimeout(() => {
            parent.search(val, nonce);
        }, SEARCH_DELAY_MS);
    }

    updateSearchString(val) {
        document._search_nonce += 1;

        this.setState({searchString: val, loading: true})
        this.updateURLSearchParams(val);
        this.queueSearch(val);
    }

    search(val, nonce) {
        const parent = this;

        if(!val || trim(val) === "") {
            this.setState({loading: false, results: [], error: null, queryImage: null})
            return;
        }

        GofigrService.textSearch(val).then(res => {
            if(document._search_nonce !== nonce) {  // results out of date: query string has changed and there's a new search pending
                return
            }

            parent.timerId = null;
            this.setState({loading: false, results: res, error: null, queryImage: null})
        }, err => {
            if(document._search_nonce !== nonce) {  // results out of date: query string has changed and there's a new search pending
                return
            }

            parent.timerId = null;
            this.setState({loading: false, results: null, error: getErrorMessage(err), queryImage: null})
        })
    }

    searchImage(image_data) {
        this.setState({loading: true, results: null, error: null, queryImage: image_data})

        GofigrService.imageSearch(image_data).then(res => {
            this.setState({loading: false, results: res, error: null})
        }, err => {
            this.setState({loading: false, results: null, error: getErrorMessage(err)})
        })
    }

    render() {
        return (
            <div>
                <SearchHeader/>

                <div className="container-xxl m-0 w-100 mw-100">
                    <div className={"card my-2"}>
                        <div className={"card-header"}>Query</div>
                        <div className={"card-body"}>
                            <table>
                                <tbody>
                                <tr>
                                    <td className="w-100">
                                        <Form className={"py-0"} onSubmit={e => e.preventDefault()}>
                                            <FormTextInput name={"search"}
                                                           label={""}
                                                           placeholder={"Enter your search here"}
                                                           margin={"mb-0"}
                                                           value={this.state.searchString}
                                                           onChange={(val) => {
                                                               this.updateSearchString(val)
                                                           }}
                                            />
                                            {this.state.error ? <div className={"alert alert-danger"}>{this.state.error}</div> : ""}
                                        </Form>
                                    </td>
                                    <td className={"mb-3"}>
                                        <Button onClick={() => this.search(this.state.searchString)}>{this.state.loading ? <Spinner style={{height: "1em", width: "1em"}}/> : "Search"}</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"w-100 py-2 pt-4"}>
                                        <span className={'my-2'}>
                                            You can also search by uploading an image or taking a picture:
                                        </span>

                                        <input
                                            className="form-control my-2"
                                            type="file"
                                            accept="image/*"
                                            name="imageQuery"
                                            onChange={async (event) => {
                                                const reader = new FileReader();
                                                reader.onload = async () => {
                                                    this.searchImage(reader.result);
                                                }
                                                reader.readAsBinaryString(event.target.files[0]);
                                            }}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {this.state.queryImage ?
                                            <div className={"mx-auto"}>
                                                <img className="img-fluid mx-auto"
                                                     style={{maxWidth: "650px",
                                                             maxHeight: "650px"}}
                                                     src={`data:image/png;base64,${btoa(this.state.queryImage)}`}/>
                                            </div> : ""}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div className={"card my-2"}>
                        <div className={"card-header"}>Results</div>
                        <div className={"card-body"}>
                            <div className="mx-2 d-flex w-100 flex-wrap justify-content-start">
                                {this.state.results && this.state.results.length > 0 ? this.state.results.map(item => {
                                    return <SearchItem
                                        blur={this.state.loading}
                                        key={item.object.api_id} item={item} router={this.props.router}/>
                                }) : (this.state.loading ? <Spinner style={{height: "1em", width: "1em"}}/> : "No Results")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export class QuickSearch extends SearchView {
    constructor(props) {
        super(props);
    }

    parseURLSearchParams() {
        return "";
    }

    updateURLSearchParams(query) {
        return null;
    }

    hide() {
        this.setState({results: null});
    }

    render() {
        let searchResults = "";
        if((this.state.results && this.state.results.length > 0 || this.state.error)) {
            searchResults = <div className="px-2 pb-2 shadow-sm"
                                 style={{position: 'absolute',
                                     background: 'white',
                                     border: '1px solid rgba(33, 40, 50, 0.125)'}}>

                <X className="pointer float-end mt-1" onClick={() => this.hide()}/>
                <div className="my-5"></div>

                {this.state.error ? <div className={"alert alert-danger"}>{this.state.error}</div> :
                    <div className="overflow-auto" style={{maxHeight: "600px"}}>
                        {this.state.results.map(item => {
                            return <div className={"lift"} key={item.object.api_id} tabIndex={"0"} style={{cursor: "pointer"}}>
                                <SearchItem item={item} router={this.props.router} onClick={() => {
                                    this.hide()
                                }}/>
                    </div>
                    })}</div>
                }
            </div>
        }

        return (
            <div className={""} onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                    this.hide();
                }
            }}>
                <Form className={"form-inline me-auto d-none d-lg-block me-3"} onSubmit={e => e.preventDefault()}>
                    <div className={"input-group input-group-joined input-group-solid"}>
                        <FormTextInput name={"search"} placeholder={"Quick Search"}
                                       margin={"mb-0"}
                                       onChange={(val) => this.updateSearchString(val)}
                        />
                        <div className={"input-group-text"}>
                            {this.state.loading ? <span className="spinner-border spinner-border-sm me-2"></span> : <Search style={{width: "1em", height: "1em"}}/>}
                        </div>
                    </div>

                </Form>

                {searchResults}
            </div>
        );
    }
}