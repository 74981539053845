import {CopyThis} from "./copy";

export function ApiId(props) {
    return <CopyThis text={props.apiId}
    renderCallback={rhs => {
        return <span className="badge bg-gray-800 font-monospace" style={props.customStyle}>
                    <span className="my-auto">
                        {props.apiId}
                    </span>

                    {rhs}
                </span>;
    }}/>
}
