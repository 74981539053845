import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import {pageTitle, required} from "../js/utils";
import CheckButton from "react-validation/build/button";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import GofigrService, {NOT_LOGGED_IN_ERROR} from "../services/gofigr.service";
import {UserLink} from "../components/userlink.component";
import {Spinner} from "reactstrap";
import {Button} from "react-bootstrap";
import {getErrorMessage} from "../common/errors";
import {CheckCircle, User, XCircle} from "react-feather";
import {SmallSpinner} from "../components/entity_list.component";

export function InvitationView(props) {
    const params = useParams();
    const token = params.token;

    const [invitation, setInvitation] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isAcceptLoading, setIsAcceptLoading] = useState(false);
    const [isDeclineLoading, setIsDeclineLoading] = useState(false);
    const [error, setError] = useState(null);
    const [finalMessage, setFinalMessage] = useState(null);
    const [finalLink, setFinalLink] = useState(null);

    useEffect(() => {
        document.title = pageTitle("Invitation");

        GofigrService.retrieveInvitation(token).then((invitation) => {
            setInvitation(invitation);
        }, err => {
            setError(err);
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    let body = <Spinner/>
    const next = "?next=" + encodeURIComponent(document.location);
    if(finalMessage) {
        body = finalMessage;
    } else if(!isLoading && !error && invitation) {
        body = <>
            <div className={"mt-2 alert alert-info"}>
                You are currently logged in as <UserLink/>. To log in as someone else, click <a href={"/login" + next}>here</a>.
            </div>

            <div className={"mt-4"}>
                <UserLink username={invitation.initiator} userInfo={invitation.initiator_details}/> invited you to
                join their workspace "{invitation.workspace_details.name}".
            </div>

            <div className={"mt-4"}>
                <Button className="btn btn-primary me-2" onClick={() => {
                    setIsAcceptLoading(true);
                    GofigrService.acceptInvitation(token).then(response => {
                            setFinalMessage(<div className={"alert alert-success"}><span>Invitation accepted successfully. Click </span>
                                <a href={"/workspace/" + invitation.workspace_details.api_id}>here</a>
                                <span> to open the workspace.</span></div>);
                            setError(null);
                    },
                        err => {
                        setError(err);
                        }).finally(() => {setIsAcceptLoading(false);})
                }}>
                    {isAcceptLoading ? <SmallSpinner/> : <CheckCircle size="16px"/>}<span className="ms-2">Accept</span></Button>

                <Button className="btn btn-danger" onClick={() => {
                    setIsDeclineLoading(true);
                    GofigrService.cancelInvitation(invitation).then(response => {
                        setFinalMessage(<div className={"alert alert-success"}>Invitation successfully declined. You can close this window.</div>);
                        setError(null);
                    }, err => {
                        setError(err);
                    }).finally(() => {setIsDeclineLoading(false);})
                }}>
                    {isDeclineLoading ? <SmallSpinner/> : <XCircle size="16px"/>}<span className="ms-2">Decline</span></Button>
            </div>
        </>
    } else if(!isLoading && error) {
        if(error === NOT_LOGGED_IN_ERROR) {
            body = <>
                <div className={"alert alert-info"}>You must be logged in to accept invitations. Use the links below
                    to login or register for an account.
                </div>
                <div className={"mt-2"}>
                    <a className={"btn btn-primary me-2"} href={"/login" + next}>Login</a>
                    <a className={"btn btn-secondary"} href={"/register" + next}>Register</a>
                </div>
            </>
        } else {
            body = <>
                <div className="alert alert-danger">{getErrorMessage(error)}</div>
                <a className={"btn btn-primary"} href="/">Go Home</a>
            </>
        }
    }

    return <div className="container-xl px-4">
        <div className="row justify-content-center">
            <div className="col-lg-5">
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className={"mx-2"}>
                        <img src="/logo_wide.png" className="m-4 img-fluid mx-auto d-block"/>
                    </div>
                    <div className="card-header justify-content-center"><h3
                        className="fw-light my-2">Invitation {invitation ? ('to Join "' + invitation.workspace_details.name + '"') : ""}</h3></div>
                    <div className="card-body">
                        {body}
                    </div>
                </div>
            </div>
        </div>

        <footer className="footer-admin mt-auto footer-light">
            <div className="container-lg px-4 pt-10 pb-2">
                <div className="row">
                    <div className="col-md-12 text-md-end small float-end">
                        <span className="float-end">{process.env.REACT_APP_VERSION}</span>
                    </div>
                </div>
            </div>
        </footer>
    </div>
}
