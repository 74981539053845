import DataTable from 'react-data-table-component';
import React, {Component} from "react";

import Papa from 'papaparse'
import {Download} from "react-feather";

export class Dataframe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            csvData: null,
            parsedData: null,
            errors: null,
            fields: null
        }
    }

    componentDidMount() {
        const csvText = atob(this.props.data);
        const parseResult = Papa.parse(csvText, {header: true});
        this.setState({csvData: csvText,
                            parsedData: parseResult.data,
                            errors: parseResult.errors,
                            fields: parseResult.meta.fields})
    }

    render() {
        if(!this.state.parsedData) {
            return <DataTable/>
        }

        const columns = this.state.fields.map(name => {
            return {
                name: name,
                selector: row => row[name]

        }})

        return <div className="container-fluid">
            <a className="btn btn-primary float-end"
            href={`data:text/csv;base64,${this.props.data}`}
            download={(this.props.downloadName + ".csv") || "data.csv"}>
                <Download/><span className="ms-2">Download CSV</span>
            </a>
            <DataTable columns={columns} data={this.state.parsedData} pagination/>
        </div>
    }
}