export const SERVICE_URL = process.env.REACT_APP_SERVICE_URL
export const SEARCH_DELAY_MS = 500;

export const THUMBNAIL_SIZE = 256;

export const THUMBNAIL_SIZE_PX = THUMBNAIL_SIZE + "px";

export const NEWSFEED_CARD_INFO_HEIGHT = 16 * 4;

export const LARGE_THUMBNAIL_SIZE = 512;

export const LOAD_DELAY_MS = 10;

export const MAX_GROUPED_THUMBNAILS = 5;