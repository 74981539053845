import React, {Component} from "react";
import {ApiId} from "./api_id.component";
import {Form} from "react-router-dom";
import GofigrService from "../services/gofigr.service";
import {SimpleModal, UserSelectionModal} from "./simple.modal";
import {getErrorMessage} from "../common/errors";
import {Copy, ExternalLink, Share2, UserPlus, XCircle} from "react-feather";
import {Spinner} from "reactstrap";
import {Button} from "react-bootstrap";
import {UserLink} from "./userlink.component";

export function formatEntityType(name) {
    switch(name) {
        case "figure":
            return "Figure";
        case "analysis":
            return "Analysis";
        case "workspace":
            return "Workspace"
        case "figure_revision":
            return "Revision";
        default:
            return name;
    }
}

export class SharingSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            objectName: props.objectName || props.object.name,
            object: props.object,
            endpoint: props.endpoint,
            linkSharing: {enabled: false},
            linkSharingPending: true,
            userSharing: [],
            userSharingPending: true,
            error: null,
            modal: null,
            userSelectionModal: null,
        }
    }

    componentDidMount() {
        this.refreshLinkSharing();
        this.refreshUserSharing();
    }

    refreshUserSharing() {
        this.setState({userSharingPending: true})
        GofigrService.getUserSharing(this.state.endpoint, this.state.object).then(
            result => {
                this.setState({userSharing: result})
            },
            err => {this.setState({error: err})}).finally(() => this.setState({userSharingPending: false}))
    }

    refreshLinkSharing() {
        this.setState({linkSharingPending: true})
        GofigrService.getLinkSharing(this.state.endpoint, this.state.object).then(
            result => {
                this.setState({linkSharing: result})
            },
            err => {this.setState({error: err})}).finally(() => this.setState({linkSharingPending: false}))
    }

    getLink() {
        const url = new URL(document.URL).origin + this.state.endpoint + this.state.object.api_id;

        return <div className={"input-group mt-2"}>
            <label className={"form-check-label my-auto me-4"}>
                Link:
            </label>
            <input className="form-control font-monospace py-2" readOnly={true} value={url}/>
            <a href="#" className={"my-auto"} onClick={() => {navigator.clipboard.writeText(url)}}>
                <Copy className={"ms-2"}/>
            </a>
            <a href={url} target="_blank" className={"my-auto"}>
                <ExternalLink className={"ms-2"}/>
            </a>
        </div>
    }

    render() {
        if(this.state.error) {
            this.state.modal.show("Error", getErrorMessage(this.state.error));
        }

        let thumbnail = "";
        if(this.state.object.thumbnail) {
            thumbnail =
                <div className="col-sm-auto m-4">
                    <img className="shadow-sm border" src={`data:image/png;base64,${this.state.object.thumbnail}`}/>
                </div>
        }

        return (<>
            <SimpleModal callback={(modal) => this.setState({modal: modal})}/>

            <UserSelectionModal callback={(modal) => this.setState({userSelectionModal: modal})}
                                acceptName="Add user"
                                onAccept={(username) => {
                                    GofigrService.setUserSharing(this.state.endpoint,
                                        this.state.object, {username: username, sharing_enabled: true}).then(
                                        () => {},
                                        err => {
                                            this.state.modal.show("Error", getErrorMessage(err))
                                        }
                                    ).finally(() => {
                                        this.state.userSelectionModal.hide();
                                        this.refreshUserSharing();
                                    })
                                }}/>

        <div className="card mx-4 mt-5">
            <div className="card-header">
                <div><Share2/> Sharing {formatEntityType(this.state.object.entity_type)}: {this.state.objectName}</div>
            </div>
            <div className="card-body">
                <div className="row">
                {thumbnail}

                {/*
                  Link sharing
                  */}
                    <div className="col-xl-auto">
                <div className="container-fluid">
                    <div className="form-switch form-check mt-2">
                        <label className="form-check-label" htmlFor="link_sharing"><h3>{this.state.linkSharing.enabled ? "Link sharing: enabled" : "Link sharing: disabled"}</h3></label>

                        {this.state.linkSharingPending ? <span className="spinner-border spinner-border-sm ms-2"/> : ""}

                        <input className="form-check-input" type="checkbox" role="switch" id="link_sharing"
                               checked={this.state.linkSharing && this.state.linkSharing.enabled}
                               onChange={(event) => {
                                   this.setState({linkSharingPending: true})

                                   GofigrService.setLinkSharing(this.state.endpoint, this.state.object, {enabled: event.target.checked}).then(
                                       res => this.setState({linkSharing: res})
                                   ).finally(() => this.setState({linkSharingPending: false}))
                                   }}/>

                    </div>

                    <div className="alert alert-info p-1 my-2">
                        Anyone with the link will be able to view this object. This includes all child objects. For example, if you are
                        sharing an Analysis, all figures and their revisions will become publicly viewable.
                    </div>

                    {this.state.linkSharing.enabled ? this.getLink() : ""}
                </div>

                {/*
                  User sharing
                */}.
                <hr className={"my-4"}/>

                <div className="container-fluid">
                    <h3>
                        User sharing
                        {this.state.userSharingPending ? <span className="spinner-border spinner-border-sm ms-2"/> : ""}
                    </h3>

                    <div>
                        <div className="alert alert-info p-1 my-2">
                            Share with specific users (read-only). If you need to collaborate, please add users to the workspace instead.
                        </div>

                        {this.renderUsers()}

                        <div>
                            <Button className="btn btn-light" onClick={() => {
                                this.state.userSelectionModal.show();
                            }}>
                                <UserPlus/><span className="ms-2">Add user...</span>
                            </Button>
                        </div>

                    </div>
                </div>
                </div>
                </div>
            </div>
        </div></>)
    }

    renderUsers() {
        if(!this.state.userSharing || this.state.userSharing.length == 0) {
            return <div className="m-4">Not shared with anybody yet</div>
        }

        return <table className="table table-responsive-sm table-striped">
            <thead>
                <tr>
                    <th>User</th>
                    <th>Actions</th>
                </tr>
            </thead>

            <tbody>
                {this.state.userSharing.map(item => {
                    return <tr key={item.username}>
                        <td><UserLink username={item.username}/></td>
                        <td><Button className="btn btn-danger" onClick={() => {
                            GofigrService.setUserSharing(this.state.endpoint, this.state.object, {username: item.username, sharing_enabled: false}).then(
                                () => {},
                                (err) => {
                                    this.state.modal.show("Error", getErrorMessage(err))
                                }
                            ).finally(() => {this.refreshUserSharing()})
                        }}>
                            <XCircle size="16px"/><span className="ms-2">Remove</span></Button></td>
                    </tr>
                })}
            </tbody>
        </table>
    }
}
