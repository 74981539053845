import React, {useState} from "react";
import {CheckCircle, Copy} from "react-feather";
import {getErrorMessage} from "../common/errors";

export function CopyThis(props) {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const setTimer = (delay) => {
        setTimeout(() => {
            setSuccess(false);
            setError(false);
        }, delay);
    }

    let rhs = "";
    if (success) {
        rhs = <CheckCircle width="16px" height="16px" className={"ms-2"}/>
    } else if (error) {
        rhs = <span className={'text-white'}>{getErrorMessage(error)}</span>
    } else {
        rhs = <Copy width="16px" height="16px" className={"ms-2"}/>
    }

    let body = "";
    if(props.label) {
        body = <>{props.label}{rhs}</>;
    } else if(props.renderCallback) {
        body = props.renderCallback(rhs);
    }

    return <a href="#" onClick={() => {
        navigator.clipboard.writeText(props.text)
            .then(() => {
                setSuccess(true);
                setTimer(500);
            })
            .catch(err => {
                setError(err);
                setTimer(4000);
            })
    }
    }>
        {body}
    </a>;
}

export function CopyThisButton(props) {
    return <CopyThis text={props.text}
              renderCallback={rhs => {
                  return <div className={"btn btn-light"}><span>{props.label}</span>{rhs}</div>
              }}
    />
}