import React, {useState} from "react";
import GofigrService from "../../services/gofigr.service";
import {getTargetInfo} from "../../components/newsfeed.component";
import {Link} from "react-router-dom";
import {UserLink} from "../../components/userlink.component";
import Moment from "react-moment";
import {AdminDataPanel, AdminPage} from "../../components/admin_entity_table.component";
import DatePicker from "react-datepicker";
import Plot from 'react-plotly.js';

const columns = [
    {
        name: 'Time',
        selector: row => <Moment fromNow>{row.timestamp}</Moment>
    },
    {
        name: 'User',
        selector: row => <UserLink username={row.username}/>,
    },
    {
        name: 'Action',
        selector: row => row.action
    },
    {
        name: 'Workspace',
        selector: row => <Link to={"/workspace/" + row.workspace_id}>{row.workspace_name}</Link>,
    },
    {
        name: 'Analysis',
        selector: row => <Link to={"/analysis/" + row.analysis_id}>{row.analysis_name}</Link>,
    },
    {
        name: 'Target Type',
        selector: row => {
            const info = getTargetInfo(row.target_type);
            return info.name;
        }
    },
    {
        name: 'Target',
        selector: row => {
            const info = getTargetInfo(row.target_type);
            return <Link to={info.endpoint + row.target_id}>{row.target_name}</Link>;
        }
    }
];

export function activitiesToTraces(activities) {
    if(!activities || activities.length === 0) {
        return null;
    }

    const allDates = new Set();
    const allWorkspaces = new Set();
    activities.forEach(act => {
        act.timestamp = new Date(act.timestamp)
        act.date = act.timestamp.toLocaleDateString();
        act.workspace_label = act.workspace_name + " (" + act.workspace_id.substr(0, 8) + ")";
        act.key = JSON.stringify({date: act.date, workspace: act.workspace_label})

        allDates.add(act.date);
        allWorkspaces.add(act.workspace_label);
    })
    allDates.add(new Date().toLocaleDateString());  // always show today

    // Count activities by day+workspace
    const counts = new Map();
    activities.forEach(act => {
        if(counts.has(act.key)) {
            counts.set(act.key, counts.get(act.key) + 1)
        } else {
            counts.set(act.key, 1)
        }
    });

    // Show each workspace as a separate trace
    const traces = Array.from(allWorkspaces).map(worx => {
        let worx_trace = {
            x: [],
            y: [],
            name: worx,
            type: 'bar'
        };
        allDates.forEach(date => {
            const key = JSON.stringify({date: date, workspace: worx});
            worx_trace.x.push(new Date(date));
            worx_trace.y.push(counts.has(key) ? counts.get(key) : 0);
        });
        return worx_trace;
    })
    return traces;
}

export function ActivityView(props) {
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [activities, setActivities] = useState([])

    return <AdminPage title={"Service Activity"}>
        {/* Filters */}
        <div className={"card m-2"}>
            <div className={"card-header"}>Filters</div>
            <div className={"card-body"}>
                Show activities since: <DatePicker selected={startDate} onChange={date => setStartDate(date)}/>
            </div>
        </div>

        {/* Visualization */}
        <div className={"card m-2"}>
            <div className={"card-header"}>Daily Activity Chart</div>
            <div className={"card-body"}>
                <Plot
                    responsive={true}
                    data={activitiesToTraces(activities)}
                    useResizeHandler={true}
                    layout={{barmode: 'stack'}}
                    style={{width: "100%", height: "100%"}}
                />
            </div>
        </div>

        {/* Table */}
        <AdminDataPanel key={startDate}
                        title={"Activity Log"}
                        onChange={data => {
                            setActivities(data);
                        }}
                        columns={columns}
                        fetchAll={async () => GofigrService.globalActivityLog(startDate)}/>
    </AdminPage>
}