import React, {Component} from "react";
import {BarChart2, Delete, Edit, Grid, PlusCircle, Settings, Share2, Trash, Trash2} from "react-feather";
import {Link} from "react-router-dom";
import GofigrService from "../services/gofigr.service";
import {ApiId} from "./api_id.component";
import {ConfirmDeleteModal} from "./simple.modal";
import AuthService from "../services/auth.service";
import {EntitySize} from "./entity_size.component";

function renderBreadcrumbItem(pathElement, idx, arr) {
    if(!pathElement.object) { return "" ;}   // may happen if we don't have access

    return <li key={pathElement.object.api_id} className={idx === arr.length - 1 ? "breadcrumb-item active" : "breadcrumb-item"}>
        <Link to={pathElement.endpoint + pathElement.object.api_id}>{pathElement.name || pathElement.object.name}</Link>
    </li>
}

export function Header(props) {
    const objectLabels = props.objectLabels || ["API ID: ", "API ID (child): "];

    return (
        <div>
            <header className="page-header page-header-light border-bottom bg-white mb-4">
                <div className="container-fluid">
                    <div className="page-header-content pt-4 pb-1">

                        <div className="align-items-center justify-content-between">
                            <div className="row">
                                <div className="col-md-10">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            {props.icon}
                                        </div>
                                        {props.title}
                                    </h1>
                                </div>
                                <div className="col-xl-2 my-2">
                                    <div className={"float-end"}>
                                        {props.actions}
                                    </div>
                                </div>
                            </div>
                                <div className="page-header-subtitle">
                                    {props.subtitle}
                                </div>

                                <div className="mt-4 mb-2 me-4">{props.object ? <><span className={"me-2"}>{objectLabels[0]}</span><ApiId apiId={props.object.api_id}/></> : ""}</div>
                                <div className="my-2 me-4">{props.childObject ? <><span className={"me-2"}>{objectLabels[1]}</span><ApiId apiId={props.childObject.api_id}/></> : ""}</div>
                        </div>

                        {GofigrService.currentPath.length > 1 && !props.hidePath ?
                            <>
                                <nav className="mt-4 rounded" aria-label="breadcrumb">
                                    <ol className="breadcrumb px-3 py-2 rounded">
                                        {GofigrService.currentPath.map(renderBreadcrumbItem)}
                                    </ol>
                                </nav>
                            </> : ""}

                    </div>
                </div>
            </header>
        </div>)
}


export class ActionHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            deleteModal: null,
            deleteAllowed: false,

            edit: props.edit === undefined ? true : props.edit,
            manage: props.manage === undefined ? false : props.manage,
            delete: props.delete === undefined ? true : props.delete,
            share: props.share === undefined ? true : props.share,
        }
    }

    render() {
        const actions = <>
            {this.state.edit ? <Link to={this.props.endpoint + this.props.object.api_id + "/edit"}><Edit className={"ms-4"}/></Link> : ""}
            {this.state.manage ? <Link to={this.props.endpoint + this.props.object.api_id + "/manage"}>
                <span className={"float-end btn btn-secondary px-2 py-2"}><Settings className="me-2" size={"1.25em"}/>Manage workspace</span>
            </Link> : ""}
            {this.state.share ? <Link to={this.props.endpoint + this.props.object.api_id + "/share"}><Share2 className={"ms-3"}/></Link> : ""}
            {this.props.performDelete && this.state.delete ? <a href="#" onClick={() => {
                this.state.deleteModal.show()
            }}>
                <Trash2 className={"ms-3"} style={{stroke: "red"}}/>
            </a> : ""}
        </>

        return (
            <div>
                <ConfirmDeleteModal callback={modal => this.setState({deleteModal: modal})}
                                    deleteWarning={this.props.deleteWarning}
                                    performDelete={this.props.performDelete}/>

                <Header title={this.props.title}
                        actions={AuthService.isLoggedIn() ? actions : null}
                        subtitle={this.props.subtitle}
                        icon={this.props.icon}
                        object={this.props.object}
                        childObject={this.props.childObject}
                        objectLabels={this.props.objectLabels}/>
            </div>
        )
    }
}