import React, {Component, useEffect, useState} from "react";
import GofigrService, {orderByMostRecent} from "../services/gofigr.service";
import {FormattedDate, FormattedTime} from "react-intl";
import Moment from "react-moment";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {withRouter} from "../common/with-router";
import {Code, Download, FileText, Image, Info, Share2, Table, Tag, Trash2} from "react-feather";
import {withLoginOptionalRedirect} from "./login.view";
import {UserLink} from "../components/userlink.component";
import {getErrorMessage, isPermissionDenied} from "../common/errors";
import {FigureHeader} from "./figure.view";
import {Link, Navigate, useLoaderData} from "react-router-dom";
import {ConfirmDeleteModal} from "../components/simple.modal";
import {Dataframe} from "../components/dataframe.component";
import {SharingSettings} from "../components/sharing.component";
import {pageTitle} from "../js/utils";
import AuthService from "../services/auth.service";
import {Metadata} from "../components/metadata.component";
import {IndexData} from "../components/index_data.component";
import {Spinner} from "reactstrap";
import {Placeholder} from "../components/placeholder.component";
import {LARGE_THUMBNAIL_SIZE, THUMBNAIL_SIZE} from "../js/config";
import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import {SmallSpinner} from "../components/entity_list.component";
import trim from "validator/es/lib/trim";
import {CopyThis, CopyThisButton} from "../components/copy";

function RevisionTimelineItem(props) {
    const [thumbnail, setThumbnail] = useState(props.revision ? props.revision.thumbnail : null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!props.revision.thumbnail) {
            GofigrService.getThumbnail(revision).then(res => {
                setIsLoading(false);
                setThumbnail(res['thumbnail']);
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    })

    const revision = props.revision;
    const isSelected = props.isSelected;

    let imgElt = null;
    if(isLoading) {
        imgElt = <Placeholder width={THUMBNAIL_SIZE} height={THUMBNAIL_SIZE}/>
    } else {
        imgElt = thumbnail ? <img className="shadow-sm border" src={`data:image;base64,${thumbnail}`}/> : "";
    }

    return <div key={revision.api_id} className="timeline-item pointer revision-item" onClick={props.onClick}>
        <div className="timeline-item-marker">
            <div className="timeline-item-marker-text"><Moment fromNow ago>{revision.created_on}</Moment> ago</div>
            <div className={isSelected ? "timeline-item-marker-indicator bg-green" : "timeline-item-marker-indicator bg-gray-200"}></div>
        </div>
        <div className={isSelected ? "timeline-item-content fw-bold text-dark" : "timeline-item-content"}>
            <div>
                <span>Revision #{revision.revision_index + 1} by <UserLink username={revision.created_by}/> on </span>
                <FormattedDate value={revision.created_on}/>
                <span> at </span>
                <FormattedTime value={revision.created_on}/>
            </div>
            <div>
                {imgElt}
            </div>
        </div>
    </div>
}

const PREFERRED_FORMATS = ["html", "png", "jpg", "jpeg"]

function getDownloadText(img) {
    if(!img) {
        return "";
    }

    const fmt_text = img.metadata.format.toUpperCase();
    const watermark_text = img.metadata.is_watermarked ? <span> with Watermark <Tag style={{width: "1em", height: "1em"}}/></span> : "";
    return <><span>{fmt_text}</span><span>{watermark_text}</span></>;
}

function sortImages(images) {
    if(!images) {
        return images;
    }

    return images.sort((a, b) => {
        if(a.metadata.format !== b.metadata.format) {
            return a.metadata.format.localeCompare(b.metadata.format)
        } else {
            // Same format. Show watermarked version first.
            return b.metadata.is_watermarked - a.metadata.is_watermarked;
        }
    })
}

function WithAsyncData(props) {
    const [obj, setObj] = useState(props.obj);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (props.renderCallback && obj && obj._shallow) {
            GofigrService.getRevisionData(obj.api_id).then(result => {
                setObj(result);
                setIsLoading(false);
                setError(null);
            }).catch(error => {
                setObj(null);
                setError(error);
                setIsLoading(false);
            })
        } else {
            setIsLoading(false);
            setError(false);
        }
    }, []);

    if(isLoading) {
        return <Spinner/>
    } else if (error) {
        return <div className={"alert alert-danger"}>{getErrorMessage(error)}</div>
    } else if(obj) {
        return props.renderCallback(obj);
    } else {
        return "";
    }
}

function IPythonHistory(props) {
    const codeObj = props.codeObj;

    let history = null;
    try {
        history = codeObj.data ? JSON.parse(atob(codeObj.data)) : [];
    } catch (e) {
        return <div className={"alert alert-danger"}>Error parsing history</div>
    }

    if(!history) {
        return "No data"
    }

    history = history.filter(elt => trim(elt) !== "");
    let histIdx = 0;
    const annotatedHistory = history.map(elt => {
        histIdx++;
        return `# Cell ${histIdx}/${history.length}\n${elt}`;
    })

    let idx = 0
    return <div>
        <div className={"mt-1 mb-3"}>
            <CopyThisButton text={annotatedHistory.join(`\n\n`)}
                            label={"Copy history to clipboard"}/>
        </div>
        <div className={"code-area"}>
            {history.map(hist => {
                idx++;

                return <div key={idx} className={"mb-3"}>
                    <SyntaxHighlighter showLineNumbers="true"
                                       language={codeObj.metadata.language ? codeObj.metadata.language.toLowerCase() : ""}
                                       style={docco}>
                        {hist}
                    </SyntaxHighlighter></div>
            })}
        </div>
    </div>
}

function CodeSnippet(props) {
    const codeObj = props.codeObj;
    const text = atob(codeObj.data);

    return <div>
        <div className={"mt-1 mb-3"}>
            <CopyThisButton text={text}
                            label={"Copy code to clipboard"}/>
        </div>

        <div className={"code-area"}>
            <SyntaxHighlighter showLineNumbers="true"
                               language={codeObj.metadata.language ? codeObj.metadata.language.toLowerCase() : ""}
                               style={docco}>
                {text}
            </SyntaxHighlighter>
        </div>
    </div>
}

function CodeDisplay(props) {
    const codeObj = props.codeObj;

    if (!codeObj) {
        return "";
    } else if (codeObj.metadata && codeObj.metadata.format === "jupyter-history/json") {
        return <IPythonHistory codeObj={codeObj}/>
    } else {
        return <CodeSnippet codeObj={codeObj}/>
    }
}

function RevisionImage(props) {
    const [imageObj, setImageObj] = useState(props.imageObj);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(imageObj && imageObj._shallow) {
            GofigrService.getRevisionData(imageObj.api_id).then(result => {
                setImageObj(result);
                setIsLoading(false);
                setError(null);
            }).catch(error => {
                setImageObj(null);
                setError(error);
                setIsLoading(false);
            })
        } else {
            setIsLoading(false);
            setError(false);
        }
    }, []);

    if(isLoading) {
        return <Placeholder extraClasses={"shadow-sm border mx-auto d-block"}
                            width={LARGE_THUMBNAIL_SIZE} height={LARGE_THUMBNAIL_SIZE}/>
    } else if(error) {
        return <div className={"alert alert-danger"}>{getErrorMessage(error)}</div>
    }

    const isInteractive = imageObj ? imageObj.metadata.format.toLowerCase() === "html" : false;
    let imageElements;
    if(imageObj) {
        if(isInteractive) {
            let figureHtml = atob(imageObj.data);
            let loaderSnippet = "<script src='/assets/js/gofigr_plotly.js'></script><script>gofigrPlotlyInit()</script>"
            let loaderHtml = "";
            if(figureHtml.includes("<body>")) {  // full HTML figure
                loaderHtml = figureHtml.replace("<body>", "<body style='margin: 0'>" + loaderSnippet);
            } else {
                loaderHtml = loaderSnippet + figureHtml;
            }

            imageElements = <iframe width={"100%"} height={"800px"} srcDoc={loaderHtml}/>
        } else {
            imageElements = <img className="img-fluid shadow-sm border mx-auto d-block"
                                 alt={"Figure image"}
                                 src={`data:image;base64,${imageObj.data}`}/>
        }
    } else {
        imageElements = "No supported image formats found. You can still download the image below.";
    }

    return imageElements;
}

function ImageDownloader(props) {
    const [obj, setObj] = useState(props.obj);
    const [data, setData] = useState(props.obj && props.obj.data ? props.obj.data : null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    if(error) {
        return <div className={"alert alert-danger"}>{getErrorMessage(error)}</div>
    } else {
        return <span className="dropdown-item pointer" onClick={event => {
            setIsLoading(true);
            setError(null);
            GofigrService.getRevisionData(obj.api_id).then(result => {
                setData(result.data);
                setIsLoading(false);
                setError(null);

                const link = document.createElement('a');
                link.href = `data:image/${obj.metadata.format};base64,${result.data}`;
                link.download = props.downloadName + "." + obj.metadata.format;
                link.click();
            }).catch(error => {
                setError(error);
                setIsLoading(false);
                setData(null);
            })
        }}>
            <span className="text-primary fw-bold">{isLoading ? <SmallSpinner/> : ""} Download as {getDownloadText(obj)}</span>
        </span>
    }
}

function RevisionImageDisplay(props) {
    const [images, setImages] = useState(sortImages(props.images) || []);
    const [imageObj, setImageObj] = useState(null);
    const [showWatermark, setShowWatermark] = useState(true);
    const [downloadName, setDownloadName] = useState(props.downloadName || "image");

    for (const idx in images) {
        images[idx].key = idx;
    }

    if (!images || !images.length) {
        return "No images to show";
    }

    // Work through preferred image formats and return one to display
    for(let fmt of PREFERRED_FORMATS) {
        const formatMatches = images.filter(img =>
            img.metadata.is_watermarked === showWatermark && img.metadata.format.toLowerCase() === fmt)

        if(formatMatches.length > 0) {
            if(!imageObj || formatMatches[0].api_id !== imageObj.api_id) {
                setImageObj(formatMatches[0]);
            }
            break;
        }
    }

    return <div className="container px-0">
        <div className="container px-0 my-2" style={{width: "200px"}}>

            <FormGroup>
                <FormControlLabel control={
                    <Switch value={showWatermark}
                            defaultChecked
                            onChange={event => setShowWatermark(!showWatermark)} />}
                                  label="Show watermark" />
            </FormGroup>
        </div>

        <div className="container mt-2 mx-0 px-0">
            <RevisionImage imageObj={imageObj} key={imageObj ? imageObj.api_id : null}/>

            <div className="dropdown my-2">
                <div className={"text-center"}>
                    <button className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                            style={{width: "50%"}} id="dropdownMenuButton"
                            type="button" aria-haspopup="true"
                            aria-expanded="false"><Download/><span className="ms-2">Download</span>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {
                            images.map(
                                img => {
                                    return <ImageDownloader key={img.key} obj={img} downloadName={downloadName}/>
                                })
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}

class SingleRevisionViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            figure: props.figure,
            revision: props.revision
        }
    }

    getDataByType(type) {
        if(!this.state.revision.data) {
            return [];
        }
        return this.state.revision.data.filter(data => data.type === type);
    }

    getImage() {
        const matchingImages = this.getDataByType("image");
        if(!matchingImages.length) {
            return "No images available"
        }

        return <div className="container-fluid px-0">
            <RevisionImageDisplay id={this.state.revision.api_id} images={matchingImages}
                                  downloadName={this.state.figure.name + ", rev " + this.state.revision.revision_index + 1}/>
        </div>
    }

    getCode() {
        // Find first watermarked image
        const matchingData = this.getDataByType("code");
        if (!matchingData || !matchingData.length) {
            return  <div className={"my-2"}>No code to show</div>;
        }

        return matchingData.map((codeObj) =>
            <div key={codeObj.api_id} className="card my-4">
                <div className="card-header">
                    {codeObj.name}
                </div>
                <div className="card-body">
                    <WithAsyncData obj={codeObj} renderCallback={codeObj => {
                        return <CodeDisplay codeObj={codeObj}/>
                    }}/>
                </div>
            </div>);
    }

    getTables() {
        const matchingTables = this.getDataByType("dataframe");

        if(!matchingTables || matchingTables.length === 0) {
            return <div className={"my-2"}>No tables to show</div>
        }

        return matchingTables.map(tableObj => {
            return (
                <div key={tableObj.name} className="card my-2">
                    <div className="card-header">
                        {tableObj.name}
                    </div>
                    <div className="card-body">
                        <WithAsyncData obj={tableObj} renderCallback={tableObj => {
                            return <Dataframe data={tableObj.data} downloadName={tableObj.name}/>
                        }}/>
                    </div>
                </div>)
        })
    }

    getText() {
        const matchingText = this.getDataByType("text");
        if(!matchingText || matchingText.length === 0) {
            return  <div className={"my-2"}>No text to show</div>
        }

        return matchingText.map(textObj => {
            return (
                <div key={textObj.name} className="card my-2">
                <div className="card-header">
                    {textObj.name}
                </div>
                <div className="card-body">
                    <pre>
                        <WithAsyncData obj={textObj} renderCallback={textObj => {
                            {
                                return atob(textObj.data)
                            }
                        }}/>
                    </pre>
                </div>
            </div>)
        })
    }

    getMetadata() {
        return <Metadata metadata={this.state.revision.metadata}/>
    }

    render() {
        if(!this.state.revision) {
            return "No data";
        }

        return (
            <div>
                <ul className="nav nav-tabs" id="navigationTabs">
                    <li>
                        <a className="nav-link active" id="navigationImageTab" data-bs-toggle="tab" href="src/views/revisions.view#navigationImage"
                           role="tab" aria-controls="navigationImageTab" aria-selected="true">
                            <Image/> Image
                        </a>
                    </li>
                    <li>
                        <a className="nav-link" id="navigationMetadataTab" data-bs-toggle="tab" href="src/views/revisions.view#navigationMetadata"
                           role="tab" aria-controls="navigationMetadata" aria-selected="false">
                            <Info/> Metadata
                        </a>
                    </li>
                    <li>
                        <a className="nav-link" id="navigationCodeTab" data-bs-toggle="tab" href="src/views/revisions.view#navigationCode"
                           role="tab" aria-controls="navigationCode" aria-selected="false">
                            <Code/> Code
                        </a>
                    </li>
                    <li>
                        <a className="nav-link" id="navigationTablesTab" data-bs-toggle="tab" href="src/views/revisions.view#navigationTables"
                           role="tab" aria-controls="navigationTables" aria-selected="false">
                            <Table/> Tables
                        </a>
                    </li>
                    <li>
                        <a className="nav-link" id="navigationTextTab" data-bs-toggle="tab" href="src/views/revisions.view#navigationText"
                           role="tab" aria-controls="navigationText" aria-selected="false">
                            <FileText/> Text
                        </a>
                    </li>

                </ul>

                <div className="tab-content">
                    <div className="tab-pane active" id="navigationImage" role="tabpanel"
                         aria-labelledby="navigationImageTab">
                        {this.getImage()}
                    </div>

                    <div className="tab-pane" id="navigationMetadata" role="tabpanel"
                         aria-labelledby="navigationMetadataTab">
                        {this.getMetadata()}
                    </div>

                    <div className="tab-pane" id="navigationCode" role="tabpanel"
                         aria-labelledby="navigationCodeTab">
                        {this.getCode()}
                    </div>

                    <div className="tab-pane" id="navigationTables" role="tabpanel"
                         aria-labelledby="navigationTablesTab">
                        {this.getTables()}
                    </div>

                    <div className="tab-pane" id="navigationText" role="tabpanel"
                         aria-labelledby="navigationTextTab">
                        {this.getText()}
                    </div>
                </div>
            </div>
        );
    }
}

export async function revisionLoader({params}) {
    const api = await GofigrService.initialize();
    const apiId = params.apiId;

    const revision = await GofigrService.getRevision(apiId);
    let figure = null, analysis = null;

    try {
        figure = await GofigrService.getFigure(revision.figure);
        analysis = await GofigrService.getAnalysis(figure.analysis)
    } catch(err) {
        if(isPermissionDenied(err)) {
            if(figure == null) {
                // Revision includes basic info about the figure just for this scenario
                figure = {api_id: revision.figure_metadata.api_id,
                          name: revision.figure_metadata.name}
            }
        } else {
            throw(err);
        }
    }

    let allRevisions = figure ? figure.revisions : null;

    // Most recent revision first
    allRevisions = orderByMostRecent(allRevisions);

    if(AuthService.isLoggedIn()) {
        GofigrService.currentWorkspace = await GofigrService.getWorkspace(analysis.workspace)
        GofigrService.currentPath = [{endpoint: "/workspace/", object: GofigrService.currentWorkspace},
            {endpoint: "/analysis/", object: analysis},
            {endpoint: "/figure/", object: figure},
        ]
    } else {
        GofigrService.currentPath = [
            {endpoint: "/analysis/", object: analysis},
            {endpoint: "/figure/", object: figure},
        ]
    }

    return {api: api, figure: figure, currentRevision: revision, revisions: allRevisions};
}

class RevisionActions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteModal: null
        }
    }

    render() {
        const revision = this.props.revision;

        return <>
            <ConfirmDeleteModal callback={modal => this.setState({deleteModal: modal})}
                                deleteWarning="Deleting this revision will delete all associated data, including figures, code & tables."
                                performDelete={async () => {
                                    await GofigrService.deleteRevision(revision);
                                    window.location.replace("/figure/" + revision.figure);
                                }}/>

            <span className="float-end">
                <Link to={"/revision/" + revision.api_id + "/share"}><Share2 className={"ms-3"}/></Link>

                <a href="#" onClick={() => { this.state.deleteModal.show() }}>
                    <Trash2 className={"ms-3"} style={{stroke: "red"}}/>
                </a>
            </span>
        </>
    }
}

class Revision extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const data = this.props.router.data;
        document.title = pageTitle(data.figure.name);
    }

    render() {
        const data = this.props.router.data;
        const figure = data.figure;
        const navigate = this.props.router.navigate;

        if(!data.revisions) {
            return "No revisions available";
        }

        let revisionsPanel = <></>
        let showingRevisions = false;
        if(data.revisions && data.revisions.length > 0) {
            showingRevisions = true;
            revisionsPanel = <div className="col-xl-4">
                <div className="card card-header-actions m-2">
                    <div className="card-header">
                        All Revisions: {data.revisions.length} available
                    </div>
                    <div className="card-body overflow-auto">
                        <div className="timeline timeline-xs">
                            {data.revisions.map((rev) =>
                                <RevisionTimelineItem key={rev.api_id} revision={rev} isSelected={rev.api_id === data.currentRevision.api_id}
                                    onClick={() => navigate("/revision/" + rev.api_id)}/>)}
                        </div>
                    </div>
                </div>
            </div>
        }

        return <div>
            <FigureHeader figure={figure} revision={data.currentRevision}/>

                <div className="row">
                    <div className={showingRevisions ? "col-xl-8" : "col-xl-12"}>
                            <div className="card card-header-actions overflow-auto m-2">
                                <div className="card-header">
                                    Revision #{data.currentRevision.revision_index + 1}
                                    {AuthService.isLoggedIn() ? <RevisionActions revision={data.currentRevision}/> : ""}
                                </div>
                                <div className="card-body">
                                    <SingleRevisionViewer
                                        key={data.currentRevision.api_id}
                                        revision={data.currentRevision}
                                        figure={figure}/>
                                </div>
                            </div>
                    </div>

                    {revisionsPanel}
                </div>

            <IndexData api_id={data.currentRevision.api_id}/>

        </div>
    }
}

export const ShareRevision = withLoginOptionalRedirect(() => {
    const data = useLoaderData();
    return <SharingSettings object={data.currentRevision}
                            objectName={`Figure ${data.figure.name}, Revision #${data.currentRevision.revision_index + 1}`}
                            endpoint="/revision/"/>
})


export async function redirectToRevisionLoader({params}) {
    return({apiId: params.apiId});
}

export function RedirectToRevision() {
    const data = useLoaderData();
    return <Navigate to={"/revision/" + data.apiId} replace={true}/>;
}

export default withRouter(Revision);
