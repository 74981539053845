import React, {Component} from "react";
import {Crosshair, PlusCircle, XCircle} from "react-feather";
import GofigrService from "../services/gofigr.service";

export class WelcomeBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userInfo: props.userInfo
        }
    }

    dismiss() {
        GofigrService.updateUser({username: this.state.userInfo.username,
                                          user_profile: {show_welcome_banner: false}}).then(updatedInfo => {
                                              this.setState({userInfo: updatedInfo})
        })
    }

    render() {
        if(!this.state.userInfo ||
            (this.state.userInfo.user_profile && !this.state.userInfo.user_profile.show_welcome_banner)) {
            return "";
        }

        return (
            <div className="card m-2">
                <div className="card-header">
                    Welcome to GoFigr!

                    <span className={"float-end btn btn-secondary px-2 py-2 m-n2"}
                    onClick={event => {this.dismiss()}}>
                        <XCircle className="me-2" size={"1.25em"}/>Dismiss
                    </span>
                </div>
                <div className="card-body">
                    <iframe
                        width={"100%"}
                        height={"1050px"}
                        src={"https://gofigr.io/welcome-to-gofigr/"}/>
                </div>
            </div>
        )
    }
}