import React from "react";
import GofigrService from "../../services/gofigr.service";
import Moment from "react-moment";
import {GfAvatar} from "../../components/userlink.component";
import {ExternalLink} from "react-feather";
import {Link} from "react-router-dom";
import {AdminDataPanel, AdminPage} from "../../components/admin_entity_table.component";
import {InlineList} from "./subscriptions.view";

const columns = [
    {
        name: "PK",
        selector: row => row.pk
    },
    {
        name: "Avatar",
        selector: row => <GfAvatar userInfo={row}/>
    },
    {
        name: "Username",
        selector: row => <>{row.username}
            {row.is_staff ? <div className="badge bg-primary mx-2" style={{fontSize: "1em"}}>🚀 Staff</div> : ""}</>
    },
    {
        name: "Profile",
        selector: row => <Link to={"/user/" + row.username}><ExternalLink/></Link>
    },
    {
        name: "Last name",
        selector: row => row.last_name
    },
    {
        name: "First name",
        selector: row => row.first_name
    },
    {
        name: "E-mail",
        selector: row => {
            return <>
            {row.email}
                {row.email_confirmed ?
                <div className="badge bg-success mx-2">Confirmed</div> : <div className="badge bg-warning mx-2">Not confirmed</div>}
            </>
        }
    },
    {
        name: "Joined",
        selector: row => <Moment>{row.date_joined}</Moment>
    },
    {
        name: "Active",
        selector: row => row.is_active ? "yes" : "no"
    },
    {
        name: "Workspaces",
        selector: row => {
            return <InlineList elements={row.workspaces}
                               makeElement={w => <span key={w.api_id}><Link to={"/workspace/" + w.api_id}>{w.name}</Link></span>}/>
        }
    }
]

export function UserManagement(props) {
    return <AdminPage title={"User Management"}>
        <AdminDataPanel title={"GoFigr users"}
                        columns={columns}
                        fetchAll={async () => GofigrService.listUsers()}
        />
    </AdminPage>;
}