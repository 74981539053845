import React, {Component} from "react";
import GofigrService from "../services/gofigr.service";
import {Link} from "react-router-dom";
import {User} from "react-feather";
import {Avatar} from "@mui/material";

function makeLink(username, content, props) {
    if(!props.onClick) {
        return <Link to={"/user/" + username}>{content}</Link>
    } else {
        return <a href="#" onClick={props.onClick}>{content}</a>
    }
}

export function getPrettyUserName(userInfo) {
    if(!userInfo) {
        return("")
    }

    const pretty_name = ((userInfo.first_name || "") + " " + (userInfo.last_name || "")).trim();
    return pretty_name === "" ? userInfo.username : pretty_name;
}

export function getInitials(userInfo) {
    if(!userInfo) {
        return("")
    }

    const pretty_name = ((userInfo.first_name[0] || "") + " " + (userInfo.last_name[0] || "")).trim();
    return pretty_name === "" ? userInfo.username[0] : pretty_name;
}

export function GfAvatar(props) {
    const info = props.userInfo;
    const size = props && props.size ? props.size : "64px";

    if(!info || !info.avatar) {
        return <User width={size} height={size}/>
    } else {
        return <Avatar alt={getPrettyUserName(info)}
                       src={`data:image/png;base64,${info.avatar}`}
                       sx={{ width: size, height: size }}/>
    }
}

export class UserLink extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: props.username,
            userInfo: props.userInfo,
            variant: props.variant || "simple"
        }
    }

    componentDidMount() {
        if(!this.state.userInfo) {
            GofigrService.getUserInfo(this.state.username).then(info => this.setState({userInfo: info}));
        }
    }

    render() {
        switch(this.state.variant) {
            case "simple":
                return this.renderSimple();
            case "wide":
                return this.renderWide();
            default:
                return "Unknown variant: " + this.state.variant
        }
    }

    renderSimple() {
        if(!this.state.userInfo) {
            return makeLink(this.state.username, this.state.username, this.props);
        } else {
            const pretty_name = ((this.state.userInfo.first_name || "") + " " + (this.state.userInfo.last_name || "")).trim();
            if(pretty_name != "") {
                return makeLink(this.state.username, pretty_name, this.props);
            } else {
                return makeLink(this.state.userInfo.username, this.state.userInfo.username, this.props);
            }
        }
    }

    renderWide() {
        const info = this.state.userInfo;

        return <div className="row container-fluid" onClick={this.props.onClick}>
            <div className="col-2 d-flex justify-content-center my-auto">
                <GfAvatar userInfo={info}/>
            </div>
            <div className="col-10">
                <div className="row text-dark">
                    {info.username}
                </div>
                <div className="row">
                    {((info.first_name || "") + " " + (info.last_name || "")).trim()}
                </div>
                <div className="row">
                    {info.email}
                </div>
            </div>
        </div>
    }
}