import {useRouteError} from "react-router-dom";
import {getErrorCode, getErrorMessage} from "../common/errors";
import {ArrowLeft} from "react-feather";
import {DefaultFooter} from "./footer.component";

export function getErrorImage(error) {
    const code = getErrorCode(error);
    if([400, 401, 403, 404, 500, 503, 504].includes(code)) {
        return String(code) + ".svg";
    } else {
        return "error.svg"
    }
}

export function DefaultGoFigrError(props) {
    const error = useRouteError();
    const code = getErrorCode(error);

    return <div className="container-xl px-4">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="text-center mt-4">
                                <img className="img-fluid p-4" src={"/assets/img/illustrations/" + getErrorImage(error)}
                                     alt=""/>
                                    <p className="lead">{code == null ? "" : String(code) + " - "}{getErrorMessage(error)}</p>
                                {props.show_back_link ?
                                    <a className="text-arrow-icon" href="/">
                                        <ArrowLeft size={"1em"}/>
                                        Back to App
                                    </a> : ""}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center text-center small mt-15">
                        <a className={"float-end"} href="https://storyset.com/people">People illustrations by Storyset</a>
                    </div>

        {props.footer ? <div id="layoutError_footer">
            <DefaultFooter/>
        </div> : ""}
                </div>
}