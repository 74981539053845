import {Navigate, useLoaderData, Redirect, redirect} from "react-router-dom";
import React, {Component} from "react";
import {withLoginOptionalRedirect} from "./login.view";
import {withRouter} from "../common/with-router";
import {Spinner} from "reactstrap";
import {Button, Modal} from "react-bootstrap";
import GofigrService from "../services/gofigr.service";
import {pageTitle} from "../js/utils";

export async function createNewEntityLoader({params}) {
    return {parentType: params.parentType,
            parentId: params.parentId}
}

class CreateNewEntity extends Component {
    constructor(props) {
        super(props);
        const data = this.props.router.data;

        this.state = {
            pending: true,
            workspace: props.workspace,
            parentType: props.workspace ? "root" : data.parentType,
            parentId: props.workspace ? null : data.parentId,
            objectName: null,
            redirectTo: null
        }
    }

    componentDidMount() {
        const parentType = this.state.parentType;
        const parentId = this.state.parentId;
        const user = GofigrService.userInfo;

        let newState = {}
        let createFunc;

        switch(parentType) {
            case "root":  // creating a Workspace (which doesn't have a parent)
                newState.objectName = "Workspace";
                createFunc = async () => {
                    const res = await GofigrService.createWorkspace({
                        name: `${user.username}'s New Workspace`
                    })

                    this.setState({pending: false, redirectTo: "/workspace/" + res.api_id})
                    return res;
                }
                break;

            case "workspace":  // creating an Analysis under a Workspace
                newState.objectName = "Analysis";
                createFunc = async () => {
                    const res = await GofigrService.createAnalysis({
                        name: `${user.username}'s New Analysis`,
                        workspace: this.state.parentId})

                    GofigrService.invalidateCache("workspace", parentId);
                    this.setState({pending: false, redirectTo: "/analysis/" + res.api_id})
                    return res;
                }
                break;

            case "analysis":  // creating a Figure under an Analysis
                newState.objectName = "Figure";
                createFunc = async () => {
                    const res = await GofigrService.createFigure({
                        name: `${user.username}'s New Figure`,
                        analysis: this.state.parentId})

                    GofigrService.invalidateCache("analysis", parentId);
                    this.setState({pending: false, redirectTo: "/figure/" + res.api_id})
                    return res;
                }
                break;

            default:
                throw Error("Unsupported entity type: " + parentType)
        }

        document.title = pageTitle("Creating new " + newState.objectName);
        this.setState(newState);

        createFunc()
    }

    render() {
        if(this.state.redirectTo) {
            // We force a full redirect to refresh all cached state (e.g. recent figures and analyses).
            // This can obviously be improved later.
            window.location.replace(this.state.redirectTo);
            return "Redirecting..."
        }

        return <Modal
            show={true}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {this.state.objectName ? <span>Creating new {this.state.objectName}...</span> : <span>Creating...</span>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <span className="align-middle"><Spinner/> Please wait</span>
                </div>
            </Modal.Body>
        </Modal>
    }
}

export default withLoginOptionalRedirect(withRouter(CreateNewEntity));
