import {redirect} from "react-router-dom";
import React, {Component} from "react";
import {ApiId} from "./api_id.component";
import Form from "react-validation/build/form";
import {FormTextInput} from "../views/signup.view";
import CheckButton from "react-validation/build/button";
import GofigrService from "../services/gofigr.service";
import {getErrorMessage} from "../common/errors";

const defaultProperties = [
    {name: 'name', label: 'Name: ', placeholder: 'Enter name'},
    {name: 'description', label: 'Description: ', placeholder: 'Enter description'}
]

export class PropertyEditor extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.objectProperties = (props.objectProperties || defaultProperties);
        if(!props.commitUpdates) {
            throw Error("Please specify the commitUpdates property");
        }

        this.state = {
            fieldErrors: null,
            message: null,
            messageClass: "",
            loading: false
        }
        this.objectProperties.forEach(prop => {
            this.state[prop.name] = this.props.object[prop.name];
        })
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({message: "", loading: true})

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            const updatedObj = {api_id: this.props.object.api_id}
            this.objectProperties.forEach(prop => {
                updatedObj[prop.name] = this.state[prop.name];
            })

            this.props.commitUpdates(updatedObj).then(res => {
                this.setState({loading: false, message: "Changes saved successfully", messageClass: "alert-info",
                    fieldErrors: null})
                this.setState(res);
            }, err => {
                this.setState({loading: false, fieldErrors: null, message: getErrorMessage(err), messageClass: "alert-danger"});
            })
        } else {
            this.setState({loading: false})
        }
    }

    render() {
        const props = this.props;
        const object = this.props.object;

        return (
            <div className="card m-2">
                <div className="card-header">
                    <div>{props.title ? props.title : <span>Editing {props.title_name}: {this.state.name}</span>}</div>
                </div>
                <div className="card-body">
                    {this.props.hideID ? "" : <><div>API ID: <ApiId apiId={object.api_id}/></div><hr/></>}

                    <Form
                        onSubmit={this.handleSubmit}
                        ref={c => {
                            this.form = c;
                        }}
                    >
                        {this.objectProperties.map(propSpec => {
                            if(!propSpec.html) {
                                return <div key={propSpec.name} className={"my-2"}>
                                    <FormTextInput name={propSpec.name}
                                                   type={propSpec.type || "text"}
                                                   label={propSpec.label}
                                                   messages={this.state.fieldErrors}
                                                   value={this.state[propSpec.name]}
                                                   placeholder={propSpec.placeholder || null}
                                                   onChange={val => {
                                                       let newState = {};
                                                       newState[propSpec.name] = val;
                                                       this.setState(newState);
                                                   }}/>
                                </div>
                            } else {
                                return <div key={propSpec.name}>{propSpec.html(object, propSpec)}</div>;
                            }
                        })}

                        {props.children}

                        <div
                            className="d-flex align-items-center justify-content-between mt-4 mb-0 float-end">
                            <button
                                className="btn btn-primary btn-block"
                                disabled={this.state.loading}
                            >
                                {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm me-2"></span>
                                )}
                                <span>Save</span>
                            </button>
                        </div>

                        {this.state.message && (
                            <div className="form-group">
                                <div className={"alert mt-4 " + this.state.messageClass} role="alert">
                                    {this.state.message}
                                </div>
                            </div>
                        )}
                        <CheckButton
                            style={{display: "none"}}
                            ref={c => {
                                this.checkBtn = c;
                            }}
                        />
                    </Form>
                </div>
            </div>
        );
    }
}
