import {EntitySize, humanFileSize, StorageUsed} from "./entity_size.component";
import React, {Component} from "react";
import GofigrService from "../services/gofigr.service";
import {getErrorMessage} from "../common/errors";
import {Input, Spinner} from "reactstrap";
import {ArrowRight, Users} from "react-feather";
import {SimpleModal, YesCancelModal} from "./simple.modal";
import {PlansView} from "../views/plans.view";

export class SubscriptionManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            subscription: null,
            members: null,
            planModal: null,
            planError: null,
            confirmModal: null,

            /* Promotions */
            promoModal: null,
            promoCode: "",
            promoError: null,
            promoMessage: null
        }
    }

    componentDidMount() {
        if(!this.props.object) {
            return "No object specified"
        }

        this.setState({loading: true, error: null, subscription: null})

        this.refreshSubscription()
    }

    refreshSubscription() {
        const requests = Promise.all([GofigrService.getSubscription(this.props.object),
            GofigrService.getWorkspaceMembers(this.props.object)])

        requests.then(([sub, members]) => {
            this.setState({loading: false, error: null, subscription: sub, members: members})
        }, err => {
            this.setState({loading: false, error: getErrorMessage(err), subscription: null})
        })
    }

    render() {
        if(this.state.loading) {
            return <Spinner/>
        } else if(this.state.error) {
            return <div className={"alert alert-danger py-1"}>{this.state.error}</div>
        } else if(!this.state.subscription || !this.state.members) {
            return "";
        }

        const plan = this.state.subscription.plan_data;
        return (<>
            <YesCancelModal callback={modal => this.setState({confirmModal: modal})}/>
            <YesCancelModal callback={modal => this.setState({promoModal: modal})}
                            yesLabel={"Apply"}
                            cancelLabel={"Close"}>
                <div>
                    {this.state.promoError ? <div className={"alert alert-danger"}>{this.state.promoError}</div> : ""}
                    {this.state.promoMessage ? <div className={"alert alert-success"}>{this.state.promoMessage}</div> : ""}
                </div>
                <div>
                    <label>
                        If you have a promo code, please enter it below:
                    </label>
                    <Input className={"mt-2 form-control"}
                           onChange={event => {
                               const value = event.target.value;
                               this.setState({promoCode: value});
                           }}/>
                </div>
            </YesCancelModal>

            <SimpleModal size="xl" callback={modal => this.setState({planModal: modal})}>
                {this.state.planError ?
                    <div className={"alert alert-danger"}>Unable to switch plans: {this.state.planError}</div> : ""}
                <PlansView currentPlan={plan} onSelect={plan => {
                    this.state.confirmModal.show("Are you sure?", `Are you sure you want to change your plan to ${plan.name}?`,
                        (confirmModal) => {
                            confirmModal.hide();
                            GofigrService.updatePlan(this.props.object, plan.api_id).then(res => {
                                this.setState({planError: null})
                                this.state.planModal.hide();
                                this.componentDidMount();
                            }, err => {
                                this.setState({planError: getErrorMessage(err)})
                            })
                        })
                }}/>
            </SimpleModal>

            <div className="card h-100 border-start-lg border-start-success" style={{maxWidth: "30em"}}>
                <div className={"card-body"}>
                    <div className={"small text-muted"}>Current plan</div>
                    <div className="h3 d-flex align-items-center">{plan.name}</div>
                    <div className={"my-3"}><strong>{this.state.members.length - 1}</strong> collaborator(s) out
                        of <strong>{plan.max_users - 1}</strong> allowed
                    </div>
                    <div>Using <strong><EntitySize
                        object={this.props.object}/></strong> of <strong>{humanFileSize(plan.max_storage_bytes)}</strong>
                    </div>
                    <StorageUsed object={this.props.object} limit={plan.max_storage_bytes}/>

                    <div>
                        <a className="text-arrow-icon small text-success mt-4" href="#" onClick={() => {
                            this.setState({planError: null});
                            this.state.planModal.show("Select a New Plan")
                        }}>
                            Upgrade plan <ArrowRight stroke={"green"}/>
                        </a>
                    </div>
                    <div className={"mt-n2"}>
                        <a className="text-arrow-icon small text-primary mt-4" href="#" onClick={() => {
                            this.setState({promoError: null,
                                                 promoMessage: null});
                            this.state.promoModal.show("Enter promo code", "",
                                (modal) => {
                                GofigrService.applyPromo(this.props.object, this.state.promoCode)
                                    .then(res => {
                                        this.setState({promoError: null,
                                                             promoMessage: `Plan successfully upgraded to ${res.name}. Enjoy!`})
                                        this.refreshSubscription()
                                    })
                                    .catch(res => {
                                        this.setState({promoMessage: null,
                                        promoError: getErrorMessage(res)})
                                    })
                            })
                        }}>
                            Apply promo code <ArrowRight stroke={"green"}/>
                        </a>
                    </div>
                </div>
            </div>
        </>)
    }
}
