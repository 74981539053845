import {Button, Modal} from "react-bootstrap";
import React, {Component} from "react";
import {Input} from "reactstrap";
import GofigrService from "../services/gofigr.service";
import {UserLink} from "./userlink.component";
import {getErrorMessage} from "../common/errors";

export class SimpleModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: 'show' in props ? props.show : false,
            title: props.title || "",
            body: props.body || "",
            callback: props.callback,
        }
    }

    componentDidMount() {
        if(this.state.callback) {
            this.state.callback(this);
        }
    }

    show(title, body) {
        this.setState({show: true, title: title, body: body})
    }

    hide() {
        this.setState({show: false})
        if(this.props.onHide) {
            this.props.onHide(this);
        }
    }

    render() {
        const defaultBody = <><Modal.Body>
            {this.state.body}
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => this.hide()}>Close</Button>
        </Modal.Footer></>

        const modalBody = this.props.children ? this.props.children : defaultBody

        return (
            <Modal
                show={this.state.show}
                onHide={() => this.hide()}
                size={this.props.size || "lg"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.title}
                    </Modal.Title>
                </Modal.Header>
                {modalBody}
            </Modal>
        );
    }
}

export class YesCancelModal extends SimpleModal {
    constructor(props) {
        super(props);

        this.state.onYes = (inst) => {}
    }

    show(title, message, onYes) {
        this.setState({onYes: onYes})
        return super.show(title, message);
    }

    render() {
        return (
            <Modal
                show={this.state.show}
                onHide={() => this.hide()}
                size={this.props.size || "lg"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.body}
                    {this.props.children}
                </Modal.Body>
                    <Modal.Footer>
                        <Button className={"btn btn-primary"} onClick={() => this.state.onYes && this.state.onYes(this)}>{this.props.yesLabel ? this.props.yesLabel : "Yes"}</Button>
                        <Button className={"btn btn-secondary"} onClick={() => this.hide()}>{this.props.cancelLabel ? this.props.cancelLabel : "Cancel"}</Button>
                    </Modal.Footer>
            </Modal>
        );
    }
}

export class UserSelectionModal extends SimpleModal {
    constructor(props) {
        super(props);

        this.state.username = props.username || "";
        this.state.query = null;
        this.state.candidates = []
    }

    hide() {
        this.setState({username: "", query: null, candidates: []})
        return super.hide()
    }

    onQueryChange(query) {
        this.setState({username: query})
        GofigrService.searchUsers(query).then(result => {
            this.setState({candidates: result})
        })
    }

    render() {
        const candidate_list = this.state.candidates.map((candidate) => {
            return(
                <div key={JSON.stringify(candidate)}
                     className={"d-flex align-items-center justify-content-between user-card my-3 py-2 " +
                         (candidate.username == this.state.username ? "user-card-selected" : "")}>
                    <UserLink variant="wide" username={candidate.username} userInfo={candidate}
                              onClick={() => {
                                  this.setState({username: candidate.username})
                              }}/>
                </div>)
        })

        const candidate_element = this.state.candidates && this.state.candidates.length > 0 ? candidate_list : "No matching users found"

        return (
            <Modal
                show={this.state.show}
                onHide={() => this.hide()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Select a user
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className="small mb-1"
                           htmlFor="username">Username: </label>

                    <Input type="text" className="form-control"
                           value={this.state.username}
                           placeholder="Enter username"
                           onChange={(evt) => this.onQueryChange(evt.target.value)}/>

                    <div className={"overflow-auto my-4"} style={{"max-height": "40em"}}>
                        {candidate_element}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={!this.state.username || this.state.username.length == 0}
                            onClick={() => { this.props.onAccept(this.state.username) }}>
                        {this.props.acceptName ? this.props.acceptName : "Accept"}
                    </Button>
                    <Button onClick={() => this.hide()}>Close</Button>
                </Modal.Footer>
            </Modal>)
    }
}

export class ConfirmDeleteModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteModal: null,
            deleteAllowed: false,
            error: null,
            deleteWarning: props.deleteWarning,
            context: null
        }
    }

    componentDidMount() {
        if(this.props.callback) {
            this.props.callback(this);
        }
    }

    show(deleteWarning=null, context=null) {
        if(deleteWarning === null) {
            deleteWarning = this.props.deleteWarning;
        }

        this.state.deleteModal.show("Are you sure?");
        this.setState({deleteWarning: deleteWarning,
                             context: context})
    }

    hide() {
        this.state.deleteModal.hide();
    }

    render() {
        return <SimpleModal
            hideFooter={false}
            callback={modal => this.setState({deleteModal: modal})}>
            <div className={"modal-body"}>
                <div className={"alert alert-danger"}>
                    {this.state.error ? this.state.error : this.state.deleteWarning}
                </div>
                {this.state.error ? "" : <>
                    <label>
                        Please type "delete" below to confirm that you would like to delete this object.
                    </label>
                    <Input className={"mt-2 form-control"}
                           onChange={event => {
                               const value = event.target.value;
                               this.setState({deleteAllowed: value === "delete"});
                           }}/>
                </>}
            </div>
            <div className={"modal-footer"}>
                <Button className={"btn btn-secondary"} onClick={() => this.state.deleteModal.hide()}>Cancel</Button>
                {this.state.error ? "" : <Button className={"btn btn-danger"} onClick={async () => {
                    try {
                        await this.props.performDelete(this.state.context);
                        this.state.deleteModal.hide();
                    } catch(e) {
                        this.setState({error: getErrorMessage(e)})
                    }
                }}
                        disabled={!this.state.deleteAllowed}>Delete</Button>}
            </div>
        </SimpleModal>
    }
}